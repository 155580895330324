/* eslint-disable import/extensions */
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import ReactLoading from 'react-loading';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '*',
            element: 
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <ReactLoading type='spin' color='#000' height='6%' width='6%' sx={{ alignSelf: 'center' }} />
            </div>
        }
    ]
};

export default AuthenticationRoutes;
