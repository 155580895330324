import React, { useEffect, useState, useRef} from 'react';
import MainCard from '../../../ui-component/cards/MainCard';
import axios from 'axios';
import {
    MenuItem, Select, Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
    Box,
    FormControl,
    InputLabel,
    Pagination,
    TextField,
    InputAdornment,
    Autocomplete,
    Switch,
    IconButton
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseIcon from '@mui/icons-material/Close';
import "./style.css";
import ReactLoading from "react-loading";
import { UserData } from 'utils/userData';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Rating from '@mui/material/Rating';
import { GridCloseIcon } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@material-ui/core/CircularProgress'; 



const ViewBookings = () => {
    const [role, setRole] = useState(null);
    const [data, setData] = useState(null);
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [newBookingModalOpen, setNewBookingModalOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(10);
    const [search, setSearch] = useState('');
    const [bookingId, setBookingId] = useState(null);
    const [roleId, setRoleId] = useState("all");
    const [adminUserId, setadminUserId] = useState()
    const [organisations, setOrganisations] = useState([]);
    const [orgId, setOrgId] = useState("");
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");
    const [SearchUser, setSearchUser] = useState("");
    const [consultantId, setConsultantId] = useState("");
    const [experts, setExperts] = useState([]);
    const [expertId, setExpertId] = useState("");
    const [slotDates, setSlotDates] = useState([]);
    const [slotDate, setSlotDate] = useState(null);
    const [slots, setSlots] = useState([]);
    const [slotId, setSlotId] = useState("");
    const [roleIdForNewBooking, setRoleIdForNewBooking] = useState("");
    const [bookingLoading, setBookingLoading] = useState(false);
    const [cancelId, setCancelId] = useState(null);
    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");
    const [loading, setLoading] = useState(false);
    const [disableForRole, setDisableForRole] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [activeBookingFilter, setActiveBookingFilter] = useState("true");
    const [activeDropDownValue, setActiveDropDownValue] = useState("true");
    const [allBookingFilter, setAllBookingFilter] = useState("");
    const [isCustomerJoin, setIsCustomerJoin] = useState(false)
    const [FeedbackModal, setFeedbackModal] = useState(false);
    const [Feedback, setFeedback] = useState({ rating: '', feedbackDesc:'' });
    const [UnTagModal, setUnTagModal] = useState(false);
    const [UntagData, setUntagData] = useState({ it: '', index:'' });
    const userRole = UserData().role;
    // const [tableLoading, setTableLoading] = useState(true);

    const success = (message) => {
        setToastMessage(message);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getAllData = async () => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/bookings?size=${size}&page=${page}&search=${search}&role_id=${roleId === "all" ? "" : roleId}&is_active=${activeBookingFilter}${allBookingFilter}&admin_user_id=${adminUserId ? adminUserId : ""}`).then((response) => {
            setData(response.data.data);
            console.log(response.data.data);
            setTotal(response.data.total_count);
            setLoading(false);
        });
    }

    let searchTimeout;

    const roleIDNumber = UserData().role;
    
    useEffect(() => {
        if(roleIDNumber === "10" || roleIDNumber === "6" || roleIDNumber === "7"){
            if(adminUserId) {
                setLoading(true);
                getAllData();
            }
        } else {
            setLoading(true);
            getAllData();
        }
    }, [page, size, search, roleId, activeBookingFilter, adminUserId, roleIDNumber]);

    // useEffect(() => {
    //     setLoading(true);
    //     getAllData();
    // }, [roleId, activeBookingFilter, adminUserId, trigger])

    // useEffect(() => {
    //     setLoading(true);
    //     clearTimeout(searchTimeout);
        
    //     searchTimeout = setTimeout(() => {
    //         if(search){    
    //             getAllData();
    //         }
    //     }, 2000);

    //         return () => {
    //         clearTimeout(searchTimeout);
    //     };
    //   }, [page, size, search]);

      useEffect(() => {
        if(!search) getAllData();
    }, [page, size, search]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [window.innerWidth]);

    useEffect(() => {
        // setLoading(true);
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/admin_roles`).then((response) => {
            setRole(response.data.data);
            console.log(response.data.data);
            // setLoading(false);

        });
        // axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/bookings?size=${size}&page=${page}&search=${search}&role_id=${roleId === "all" ? "" : roleId}&is_active=${activeBookingFilter}${allBookingFilter}&admin_user_id=${adminUserId ? adminUserId : ""}`).then((response) => {
        //     setData(response.data.data);
        //     console.log(response.data.data);
        //     setTotal(response.data.total_count);
        //     // setLoading(false);
        // });
        axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/organizations?search=`).then((response) => {
            setOrganisations(response.data.data);
            // setLoading(false);

        });
    }, []);

    useEffect(() => {
        const role = UserData().role;
        const userId = UserData().userId
        const name = UserData().name;
        if (role === "10" || role === "6" || role === "7") {
            // setLoading(true);
            setTimeout(() => {
                setRoleId(role);
                setadminUserId(userId)
                setSearch('');
                setDisableForRole(true);
                // setLoading(false);
            }, 50);
        }
    }, []);

    useEffect(() => {
        setPage(1);
    }, [search, roleId]);

    useEffect(() => {
        setPage(1);
    }, [size]);

    const expertNameRef = useRef(null);
    const slotDateRef = useRef(null);
    const slotRef = useRef(null);


    useEffect(() => {
        if(SearchUser) setUsers([])
        const getUsers = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/users?org_id=${orgId}&search=${SearchUser}`);
            setUsers(res.data.data);
        }
        if (orgId !== "") {
            getUsers();
        }
    }, [orgId,SearchUser]);

    useEffect(() => {
        const getExperts = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/admin_users?role_id=${roleIdForNewBooking}&search=`);
            setExperts(res.data.data);
        }
        if (roleIdForNewBooking !== "") {
            getExperts();
        }
        setSlotDate(null);
        setExpertId("");
        setSlotId("");

    }, [roleIdForNewBooking]);

    useEffect(() => {
        const getSlotDates = async () => {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/slot_dates?admin_user_id=${expertId}`);
            setSlotDates(res.data.data);
            setSlotDate(null);
        }
        if (expertId !== "") {
            getSlotDates();
            setSlotDate(null);
            setSlotId("");
        }
    }, [expertId]);

    useEffect(() => {
        const getSlots = async () => {
            const formattedDate = dayjs(slotDate).format('YYYY-MM-DD');
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/time_slots?date=${formattedDate}&admin_user_id=${expertId}`);
            setSlots(res.data.data);
        }
        if (slotDate !== null) {
            getSlots();
            setSlotId("");
        }
    }, [slotDate]);



    // const filteredData = useMemo(() => {
    //     setLoading(false)
    //     if(search === '') return data;
    //     console.log(data.name)
    //     return data.filter(data => data.name.toLowerCase().includes(search.toLowerCase()));
    // }, [data, search]);

    // console.log("filteredData",filteredData)

    const getExpertName = () => {
        if (!expertId || expertId === "") return "";
        // if(!experts || experts.length === 0) return "";
        const expert = experts.find((i) => i.id === expertId);
        return expert?.name;
    }

    const getSlot = (id) => {
        const slot = slots.find((i) => i.calender_id === id);
        return slot?.slot;
    }


    const options = ['Option 1', 'Option 2', 'Option 3'];

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: windowWidth > 600 ? '60vw' : '80vw',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        borderCollapse: 'separate',
        borderRadius: '0.7rem',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
    };


    const getRolesForBookingForParticularUser = (id, roleList) => {
        if (id === "10" || id === "6" || id === "7") {
            const list = [];
            roleList?.map((role) => {
                // eslint-disable-next-line eqeqeq
                if (role.id == id) {
                    list.push(role);
                }
                return null;
            });
            return list;
        }
        return roleList;
    }

    const roleList = role?.map((i) => <MenuItem value={i.id}>{i.name}</MenuItem>);
    const roleList1 = getRolesForBookingForParticularUser(userRole, role);

    console.log('roleList', role);

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    }

    const handleNewBookingModalClose = () => {
        setNewBookingModalOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setNewBookingModalOpen(false);
        setSuccessDialogOpen(false);
        setSlotDate(null)
        getAllData();
        // axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/bookings?size=${size}&page=${page}&search=${search}&role_id=${roleId === "all" ? "" : roleId}&is_active=${activeBookingFilter}${allBookingFilter}&admin_user_id=${adminUserId ? adminUserId : ""}`).then((response) => {
        //     setData(response.data.data);
        //     setTotal(response.data.total_count);
        // });
    }

    const handleCancelBooking = async () => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/consultation/v1/booking/${cancelId}`);
            console.log('res', res);
            success(res.data.message);
            setCancelDialogOpen(false);
            getAllData();
            // axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/bookings?size=${size}&page=${page}&search=${search}&role_id=${roleId === "all" ? "" : roleId}&is_active=${activeBookingFilter}${allBookingFilter}&admin_user_id=${adminUserId ? adminUserId : ""}`).then((response) => {
            //     setData(response.data.data);
            //     setTotal(response.data.total_count);
            // });
        } catch (e) {
            console.log(e);
            setToastSeverity("error");
            success(e.response.data.message);
            setTimeout(() => {
                // window.location.reload();
                setToastSeverity("success");
            }
                , 2000);
        }
    }

    const handleNewBooking = async () => {
        if (!orgId) {
            alert("Please Select an Organiastion");
            return;
        }
        if (!userId) {
            alert("Please Select a User");
            return;
        }
        if (!roleIdForNewBooking) {
            alert("Please Select a Role");
            return;
        }
        if (!expertId) {
            alert("Select an Expert");
            return;
        }
        if (!slotDate) {
            alert("Select a Date");
            return;
        }
        if (!slotId) {
            alert("Select a Slot Time");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/consultation/v1/booking
            `, {
                "calender_id": slotId,
                "user_id": userId,
            });
            if (res.data.result === "success") {
                setSuccessDialogOpen(true);
            }
            else {
                alert(res.data.message);
                // window.location.reload();
            }
        }
        catch (e) {
            // console.log('hello',e.response.data.message);
            alert(e.response.data.message);
            // window.location.reload();
        }
    }

    const renderCancelDialog = () => (
        <Dialog
            open={cancelDialogOpen}
            onClose={handleCancelDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span className='dialog-content-container' >
                        <div
                            className='dialog-content-heading'
                        >
                            Cancel Booking
                        </div>
                        <div
                            className='dialog-content'
                        >
                            Are you sure you want to cancel the booking for this user?
                        </div>
                        <div className='dialog-content'>
                            The session cannot be restored once canceled.
                        </div>
                    </span>

                    <p
                        className='dialog-success'
                    >
                        { }
                    </p>

                    <p
                        className='dialog-error'
                    >
                        { }
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <div className='dialog-button-container' >
                    <Button
                        onClick={handleCancelDialogClose}
                        className='dialog-cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCancelBooking}
                        className='dialog-confirm-button'
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
    const renderSuccessDialog = () => (
        <Dialog
            open={successDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span className='dialog-content-container' >
                        <div
                            className='dialog-content-heading'
                        >
                            Booking successful
                        </div>
                        <div
                            className='dialog-content'
                        >
                            You have successfully added a new booking for<br /> {getExpertName(expertId)} at {getSlot(slotId)}.
                        </div>
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ paddingLeft: '20px', paddingRight: '20px' }} >
                <div className='dialog-button-container' >
                    <Button
                        onClick={handleSuccessDialogClose}
                        className='dialog-cancel-button'
                    >
                        Continue
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    const shouldDisableDate = (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        return !slotDates.includes(formattedDate);
    };

    const convertToDateISOString = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const dateObj = new Date(year, month - 1, day);
      
        const formattedDateString = dateObj.toISOString().slice(0, 10);
        return formattedDateString;
    }

    const newBookingDialog = () => (
        <Modal
            open={newBookingModalOpen}
            onClose={handleNewBookingModalClose}
            aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        >
            <Box sx={modalBoxStyle}>
                {bookingLoading ? <div> Load</div> : <div className='dialog-content-container' >
                    <h2
                        className='dialog-content-heading'
                    >
                        New Booking
                    </h2>
                    <div
                        className='dialog-content'
                    >
                        <div className='input-container'>
                            <p>Select Organisation</p>
                            <Autocomplete
                                options={organisations}
                                // value={orgId}
                                onChange={(event, newValue) => {
                                    setOrgId(newValue ? newValue.id : null);
                                }}
                                getOptionLabel={(option) => option.name}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Organisation Name'
                                    />
                                )}
                            />
                        </div>
                        <div className='input-container'>
                            <p>Select User</p>
                            <Autocomplete
                                options={users}
                                // value={userId}
                                onChange={(event, newValue) => {
                                    setUserId(newValue ? newValue.id : null);
                                }}
                                onInputChange={(event, newInputValue) =>{
                                    setSearchUser(newInputValue)
                                }}
                                getOptionLabel={(option) => option.name || ''}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='User Name'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div
                        className='dialog-content'
                    >
                        <div className='input-container' >
                            <p>Consultation From</p>
                            <Autocomplete
                                options={roleList1}
                                // value={userId}
                                onChange={(event, newValue) => {
                                    setRoleIdForNewBooking(newValue ? newValue.id : null);
                                }}
                                getOptionLabel={(option) => option.name || ''}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Expert'
                                    />
                                )}
                            />
                        </div>
                        <div className='input-container'>
                            <p>Select Expert</p>
                            <Autocomplete
                                ref={expertNameRef}
                                options={experts}
                                // value={getExpertName()}
                                onChange={(event, newValue) => {
                                    setExpertId(newValue ? newValue.id : null);
                                    setSlotDates([])
                                    setSlotDate(null);
                                }}
                                getOptionLabel={(option) => option.name || ''}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Expert Name'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div
                        className='dialog-content'
                    >
                        <div className='input-container'>
                            <p>Select Date</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    sx={{width:'100%', boxShadow:'none'}}
                                    value={slotDate || null}
                                    onChange={(event) => {
                                        setSlotDate(event);
                                    }}
                                    // renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    shouldDisableDate={shouldDisableDate}
                                    // disablePast
                                    // disableFuture
                                />
                            </LocalizationProvider>
                            {/* <Autocomplete
                                ref={slotDateRef}
                                options={slotDates}
                                // value={expertId}
                                onChange={(event, newValue) => {
                                    setSlotDate(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Slot Date'
                                    />
                                )}
                            /> */}
                        </div>
                        <div className='input-container'>
                            <p>Select Time Slot</p>
                            <Autocomplete
                                options={slots}
                                ref={slotRef}
                                // value={expertId}
                                onChange={(event, newValue) => {
                                    setSlotId(newValue ? newValue.calender_id : null);
                                }}
                                getOptionLabel={(option) => option.slot || ''}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Slot'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <p
                        className='dialog-success'
                    >
                        { }
                    </p>

                    <p
                        className='dialog-error'
                    >
                        { }
                    </p>
                </div>}
                <div className='dialog-button-container' >
                    <Button
                        onClick={handleNewBookingModalClose}
                        className='dialog-cancel-button'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleNewBooking()}
                        className='dialog-confirm-button'
                    >
                        Confirm
                    </Button>
                </div>
            </Box>
        </Modal>
    )


    const malaki = (event) => {
        setRoleId(event.target.value);

    };

    const changeActiveFilter = (event) => {
        if (event.target.value === "all") {
            setActiveBookingFilter(true);
            setAllBookingFilter("&is_all=true")
        } else {
            setActiveBookingFilter(event.target.value);
            setAllBookingFilter("")
        }
        setActiveDropDownValue(event.target.value)


    };

    const handleChange = async (item, index) => {
        try {
            await axios.post(`${process.env.REACT_APP_BASEURL}admin/consultation/user_joined`, {
                "id": item.id,
                "is_joined": !item.is_joined,
            });
          
            const tempData = [...data]
            tempData[index].is_joined = !item.is_joined
            console.log("tempData", tempData[index].is_joined)
            setData(tempData)
            // setIsCustomerJoin(res.is_joined)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handlePsychiatrist = (item, index) => {
        axios.post(`${process.env.REACT_APP_BASEURL}admin/consultation/refer_psychiatrist`, {
            id: item.id,
            is_referred: !item.referred_psychiatrist,
        }).then((response)=>{
            console.log('Response Data:', response.data.message);
            success(response.data.message);
            const tempData = [...data];
            tempData[index].referred_psychiatrist = !item.referred_psychiatrist;
            setData(tempData);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const handleTagUntag = (item, index) => {
        axios.post(`${process.env.REACT_APP_BASEURL}admin/consultation/untag_expert`, {
            id: item.id,
            is_untagged: !item.untagged,
        }).then((response)=>{
            setUnTagModal(false);
            success(response.data.message);
            const tempData = [...data];
            tempData[index].untagged = !item.untagged;
            setData(tempData);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const handleUploadofFile = (item, e) => {
        const files = e.target.files;
        const formData = new FormData();
        formData.append('files', files[0]);
        formData.append('calender_id', item.id);
        console.log("FILES", files, formData);
        axios.post(`${process.env.REACT_APP_BASEURL}admin/consultation/upload/notes`, formData)
        .then((res) => {
            success(res.data.message);
            setData([]);
            setTrigger(trigger + 1);
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    const renderFeedback = () => (
        <Dialog
            open={FeedbackModal}
            onClose={() => {setFeedbackModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    minWidth: "500px", // Set your minimum width here
                  },
                },
            }}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span className='dialog-content-container' >
                        <div
                            className='dialog-content-heading'
                            style={{
                                display:'flex',
                                justifyContent:'center'
                            }}
                        >
                            Feedback
                            <IconButton onClick={() => {setFeedbackModal(false)}} style={{
                                position: "absolute",
                                right: "10px",
                                top: "8px"
                            }}>
                                <GridCloseIcon />
                            </IconButton>
                        </div>
                        <div className='dialog-content' style={{display:'flex', justifyContent:'center'}}>
                            <Rating name="read-only" value={Feedback.rating} readOnly precision={0.5} size="large" />
                        </div>
                        <div className='dialog-content' style={{textAlign:'justify'}}>
                            {Feedback.feedbackDesc}
                        </div>
                    </span>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );

    const renderUntagConfirmation = () => (
        <Dialog
            open={UnTagModal}
            onClose={() => {setUnTagModal(false)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span className='dialog-content-container' >
                        <div
                            className='dialog-content-heading'
                            style={{
                                display:'flex',
                                justifyContent:'center',
                                border: 0
                            }}
                        >
                            Untag Expert
                            <IconButton onClick={() => {setUnTagModal(false)}} style={{
                                position: "absolute",
                                right: "10px",
                                top: "8px"
                            }}>
                                <GridCloseIcon />
                            </IconButton>
                        </div>
                        <div className='dialog-content' style={{textAlign:'justify'}}>
                            {!UntagData.it.untagged ? 'Are you sure you want to untag the expert with this customer?' : 'Are you sure you want to tag the expert with this customer?'}
                        </div>
                    </span>
                </DialogContentText>
                <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10,
                        border: 0
                    }}
                >
                    <Button
                        onClick={() => {setUnTagModal(false)}}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {handleTagUntag(UntagData.it, UntagData.index)}}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
            </DialogContent>
        </Dialog>
    );

console.log('active',JSON.parse(activeBookingFilter));
    const finalPermission = UserData().permissions
    const consultation = finalPermission?.find(permission => permission.name === "Consultation");

    const columns = [
        { title: 'S.No', field: 'sno' },
        { title: 'Name', field: 'name' },
        { title: 'Customer Id', field: 'customer_id', render: (rowData) => {
            if (rowData && rowData.customer_id) {
              return rowData.customer_id;
            }
            return 'null';
          },
        },
        { title: 'Date', field: 'date' },
        { title: 'Time', field: 'time' },
        { title: 'Organization', field: 'org_name' },
        { title: 'Expert Name', field: 'expertname' },
        { title: 'Phone', field: 'phone' },
        {
            title: 'Customer Joined', field: 'c_join', sorting: false,
        },
        {
            title: 'Refer Psychiatrist',
            field: 'referred_psychiatrist',
            sorting: false,
            hidden: JSON.parse(activeBookingFilter) === true || !data?.every((rowData) => rowData.refer_psychiatrist === true)
        },
        {
            title: 'Untag Expert',
            field: 'can_untag_experts',
            sorting: false,
            hidden: JSON.parse(activeBookingFilter) === true || !data?.every((rowData) => rowData.can_untag_expert === true),
        },
        {
            title: 'Notes',
            field: 'view_notes',
            sorting: false,
            hidden: JSON.parse(activeBookingFilter) === true || !data?.every((rowData) => rowData.can_view_notes === true),
        },
        {
            title: 'Feedback', field: 'has_feedback', sorting: false, hidden: JSON.parse(activeBookingFilter) === true
        },
        {
            title: 'Link', field: 'link', sorting: false,
        },
        {
            title: 'Action', field: 'action', sorting: false

        }
    ];

    const arr = []
    const dateObj = new Date()

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

    data?.map((item, i) => {
        console.log('slot', slotId)
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: size * (page - 1) + i + 1,
                name: item.name,
                customer_id: item.customer_id,
                date: item.date.slice(0, 10),
                time: item.time,
                org_name: item.organization,
                expertname: item.expert_name,
                phone: item.phone,
                c_join: <Switch checked={item.is_joined} disabled={!consultation?.can_create} onChange={() => handleChange(item, i)} inputProps={{ 'aria-label': 'controlled' }}
                />,
                link: <>{item.link.length > 0 ?
                    <Button className='join_button' variant="outlined" onClick={() => window.open(item.link, '_blank')}
                        disabled={!item.is_active || !consultation?.can_create}
                    >
                        Join
                    </Button> : "No Link Added"
                } </>
                ,
                //     : <button>
                //     Join
                // </button>, 
                referred_psychiatrist: <Switch checked={item.referred_psychiatrist} disabled={!consultation?.can_create} onChange={() => {handlePsychiatrist(item, i)}} inputProps={{ 'aria-label': 'controlled' }} />,
                can_untag_experts: <Switch checked={item.untagged} disabled={!consultation?.can_create} onChange={() => {setUnTagModal(true); setUntagData({index:i, it:item})}} inputProps={{ 'aria-label': 'controlled' }} />,
                view_notes: 
                item.has_notes ? (
                <Button className='join_button' style={{width:200}} variant="outlined" onClick={() => {window.open(item.notes, '_blank')}}>
                    View Notes
                </Button>
                ) : (
                <LoadingButton loadingPosition="start" component="label" variant="outlined" disabled={!consultation?.can_create} startIcon={<CloudUploadIcon />} style={{width:200}} >
                    Upload Note
                    <VisuallyHiddenInput type="file" onChange={(e)=>{handleUploadofFile(item, e)}} />
                </LoadingButton>),
                has_feedback: 
                    <Button className='join_button' disabled={!item.has_feedback} style={{width:150}} variant="outlined" onClick={() => {setFeedbackModal(true); setFeedback({rating: parseFloat(item.feedback.rating), feedbackDesc: item.feedback.description})}}>
                        View Feedback
                    </Button>,
                action: (
                    <button 
                    type='button' 
                    style={{ 
                        color: (consultation?.can_create && item.can_cancel_booking)
                        ? '#D32C2C' : '#AAA1A1' 
                    }} 
                    className='cancel-button' onClick={() => {
                        if(consultation?.can_create && item.can_cancel_booking === true)
                        {
                            setCancelId(item.id);
                            setCancelDialogOpen(true);
                        }
                    }}
                    // disabled={
                    //     (parseInt(item.date.split("-")[0],10)>=dateObj.getUTCFullYear()) ||
                    //     (parseInt(item.date.split("-")[0],10)===dateObj.getUTCFullYear() && parseInt(item.date.split("-")[1],10)>=(dateObj.getMonth()+1)) || 
                    //     (parseInt(item.date.split("-")[0],10)===dateObj.getUTCFullYear() && parseInt(item.date.split("-")[1],10)===(dateObj.getMonth()+1) && parseInt(item.date.split("-")[2],10)>=dateObj.getDate())
                    // }
                    >
                        Cancel
                    </button>
                )
            });
        }
        return null;
    });


    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    return (
        <>
            <MainCard>
                <div className='header-container' >
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: "center", gap: "20px" }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={roleId ? roleId : ""} sx={{ width: "200px" }} placeholder="All" onChange={malaki}
                            disabled={disableForRole}
                            style={{ paddingLeft: "10px" }}
                        >
                            {roleList}
                            <MenuItem value="all">All Experts</MenuItem>
                        </Select>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={activeDropDownValue} sx={{ width: "200px" }} onChange={changeActiveFilter}
                            style={{ paddingLeft: "10px" }}
                        >
                            <MenuItem value="true">Active Bookings</MenuItem>
                            <MenuItem value="false">Past Bookings</MenuItem>
                            <MenuItem value="all">All Bookings</MenuItem>
                        </Select>
                    </div>

                    <Button
                        disabled={!consultation?.can_create}
                        onClick={() => setNewBookingModalOpen(true)}
                        className='dialog-confirm-button'
                    >
                        New Booking
                    </Button>
                </div>
                {/* {loading ?
                    <div className='loader-container'>
                        <ReactLoading type='spin' color='#000000' height='10%' width='10%' />
                    </div>
                    : */}
                <div className='table-container' >
                    <div className='search-container' >
                        <TextField
                            autoComplete="off"
                            key="search"
                            autoFocus="autoFocus"
                            id="standard-basic"
                            placeholder='Search for Name'
                            variant="standard"
                            disabled={disableForRole}
                            style={{ width: '300px' }}
                            InputProps={{
                                startAdornment: (
                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                        {loadingsearch ? (
                                        <InputAdornment position="end">
                                            <CircularProgress size={24} />
                                        </InputAdornment>
                                        ) : (
                                        <InputAdornment position="end">
                                            <SearchRoundedIcon />
                                        </InputAdornment>
                                        )}
                                    </div>

                                    // <InputAdornment position="end">
                                    //     <SearchRoundedIcon />
                                    // </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: "pointer" }}
                                        disabled={disableForRole}
                                        onClick={
                                            () => setSearch('')
                                        } >
                                        {/* <CloseIcon /> */}
                                    </InputAdornment>
                                )
                            }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                setLoading(true);
                            }}
                        />
                    </div>
                    <MaterialTable
                        title={null}
                        isLoading={loading}
                        icons={tableIcons}
                        columns={columns}
                        data={arr}
                        options={{
                            // pageSize: 20,
                            toolbar: false,
                            search: false,
                            paging: false,
                            maxBodyHeight: '700px',
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF',
                                fontWeight: 100
                            },

                            // eslint-disable-next-line consistent-return
                            rowStyle: (x) => {
                                if (x.tableData.id % 2) {
                                    return { backgroundColor: '#eef9ff' };
                                }
                            }
                        }}
                    />
                    <div className='pagination-container' >
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={size}
                                label="Results per Page"
                                onChange={(event) => { setSize(event.target.value) }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;
                        <Pagination
                            count={Math.ceil(total / size) || 1}
                            page={page}
                            onChange={handlePageChange}
                            variant="outlined"
                            shape="rounded"
                            size="small"
                        />
                    </div>
                    

                </div>

                {/* // } */}
                {renderCancelDialog()}
                {newBookingDialog()}
                {renderSuccessDialog()}
            </MainCard >
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            {renderFeedback()}
            {renderUntagConfirmation()}
        </>
    );
};

export default ViewBookings;
