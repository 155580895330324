import styled from 'styled-components';

const MainWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    flex-direction: column;
    height: 80vh;
`;

export default function NotFound() {
    return (
        <MainWrapper>
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </MainWrapper>
    );
}
