import { IconDashboard } from '@tabler/icons';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import TableViewIcon from '@mui/icons-material/TableView';
import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import FitnessCenterTwoToneIcon from '@mui/icons-material/FitnessCenterTwoTone';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import QueuePlayNextTwoToneIcon from '@mui/icons-material/QueuePlayNextTwoTone';
import LibraryAddCheckTwoToneIcon from '@mui/icons-material/LibraryAddCheckTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import BiotechTwoToneIcon from '@mui/icons-material/BiotechTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ViewAgendaTwoToneIcon from '@mui/icons-material/ViewAgendaTwoTone';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import DiningTwoToneIcon from '@mui/icons-material/DiningTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import EggTwoToneIcon from '@mui/icons-material/EggTwoTone';
import CalculateIcon from '@mui/icons-material/Calculate';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ReactComponent as Progress } from '../assets/images/icons/Tracker.svg';

import { ReactComponent as BloodSample } from '../assets/images/icons/bloodsample.svg';





import { UserData } from 'utils/userData';
import DNATestIcon from 'assets/images/icons/DNATestIcon';

// constant
const icons = {
    IconDashboard,
    CorporateFareIcon,
    TableViewIcon,
    AddIcon,
    AddShoppingCartTwoToneIcon,
    ShoppingBagTwoToneIcon,
    FitnessCenterTwoToneIcon,
    OndemandVideoTwoToneIcon,
    QueuePlayNextTwoToneIcon,
    LibraryAddCheckTwoToneIcon,
    AccountCircleTwoToneIcon,
    VerifiedUserTwoToneIcon,
    PersonOutlineTwoToneIcon,
    BiotechTwoToneIcon,
    AssessmentTwoToneIcon,
    UploadFileTwoToneIcon,
    LocalOfferTwoToneIcon,
    AddCircleOutlineTwoToneIcon,
    ViewAgendaTwoToneIcon,
    GroupsTwoToneIcon,
    QrCode2Icon,
    NotificationsNoneIcon,
    BloodSample
};

const role = UserData().role;
const finalPermission = UserData().permissions
console.log("roleID",finalPermission)
const organizationPermissions = finalPermission?.find(permission => permission.name === "Organizations");
const fitnessVideo = finalPermission?.find(permission => permission.name === "Fitness Videos");
const uploadReport = finalPermission?.find(permission => permission.name === "Upload Reports");
const dna = finalPermission?.find(permission => permission.name === "DNA Report");
const blood = finalPermission?.find(permission => permission.name === "Blood Reports");
const usa = finalPermission?.find(permission => permission.name === "User Video Assessment");
const coupon = finalPermission?.find(permission => permission.name === "Coupon");
const notification = finalPermission?.find(permission => permission.name === "Notifications");
const products = finalPermission?.find(permission => permission.name === "Products");
const consultation = finalPermission?.find(permission => permission.name === "Consultation");
const ingredients = finalPermission?.find(permission => permission.name === "Ingredients Panel");
const recipe = finalPermission?.find(permission => permission.name === "Recipe Panel");
const barcode = finalPermission?.find(permission => permission.name === "Add Barcode");
const verification = finalPermission?.find(permission => permission.name === "Verification Panel");

// eslint-disable-next-line consistent-return
function roles() {

        return [
            organizationPermissions?.can_view ? {
                id: 'Organizations',
                title: 'Organizations',
                type: 'collapse',
                icon: CorporateFareIcon,

                children: [
                    organizationPermissions?.can_create ? {
                        id: 'add',
                        title: 'Add Organization',
                        type: 'item',
                        icon: icons.AddIcon,
                        url: '/organization/add'
                    } : null,
                    {
                        id: 'view',
                        title: 'View Organization',
                        type: 'item',
                        icon: icons.TableViewIcon,
                        url: '/organization/view',
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null,
            fitnessVideo?.can_view ? {
                id: 'Fitness Video',
                title: 'Fitness Videos',
                type: 'collapse',
                icon: icons.FitnessCenterTwoToneIcon,

                children: [
                    fitnessVideo?.can_create ? {
                        id: 'add new video',
                        title: 'Add New Video',
                        type: 'item',
                        icon: QueuePlayNextTwoToneIcon,
                        url: '/fitnessvideo/add',
                        target: false
                    } : null,
                    fitnessVideo?.can_create ? {
                        id: 'assign new video',
                        title: 'Assign New Videos',
                        type: 'item',
                        icon: LibraryAddCheckTwoToneIcon,
                        url: '/fitnessvideo/assign',
                        target: false
                    } : null,
                    {
                        id: 'view video',
                        title: 'View Videos',
                        type: 'item',
                        icon: OndemandVideoTwoToneIcon,
                        url: '/fitnessvideo/view',
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null,
            uploadReport?.can_view ? {
                id: 'user data',
                title: 'User Data',
                type: 'collapse',
                icon: icons.AccountCircleTwoToneIcon,

                children: [
                    // {
                    //     id: 'all user',
                    //     title: 'All Users',
                    //     type: 'item',
                    //     icon: PersonOutlineTwoToneIcon,
                    //     url: '/user/all',
                    //     target: false
                    // },
                    // {
                    //     id: 'BloodReport',
                    //     title: 'Blood Reports',
                    //     type: 'item',
                    //     url: '/user/report',
                    //     icon: BloodSample,
                    //     target: false
                    // },
                    // {
                    //     id: 'subscribed user',
                    //     title: 'Subscribed Users',
                    //     type: 'item',
                    //     icon: VerifiedUserTwoToneIcon,
                    //     url: '/user/subscribed',
                    //     target: false
                    // },
                    {
                        id: 'UploadReport',
                        title: 'Upload Reports',
                        type: 'item',
                        url: '/user/newTest',
                        icon: BiotechTwoToneIcon,
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null,
            dna?.can_view || blood?.can_view ? {
                id: 'Reports',
                title: 'Reports',
                type: 'collapse',
                icon: BiotechTwoToneIcon,

                children: [
                    blood?.can_view ? {
                        id: 'BloodReport',
                        title: 'Blood Reports',
                        type: 'item',
                        url: '/user/report',
                        icon: icons.BloodSample,
                        target: false
                    } : null,
                    dna?.can_view? {
                        id: 'DNAReport',
                        title: 'DNA Report',
                        type: 'item',
                        icon: DNATestIcon,
                        url: '/customer-field'
                    } : null,
                ].filter((item) => item !== null)
            } : null,
            usa?.can_view ? {
                id: 'user assessment',
                title: 'User Video Assessment',
                type: 'collapse',
                icon: VideoCameraFrontIcon,

                children: [
                    {
                        id: 'new assessment',
                        title: 'New Assessment',
                        type: 'item',
                        url: '/assessment/new',
                        target: false,
                        icon: VideoLibraryIcon
                    },
                    {
                        id: 'approved video',
                        title: 'Approved Videos',
                        type: 'item',
                        url: '/assessment/approved',
                        target: false,
                        icon: HowToRegIcon
                    },
                    {
                        id: 'rejected video',
                        title: 'Rejected Videos',
                        type: 'item',
                        url: '/assessment/rejected',
                        target: false,
                        icon: ThumbDownOffAltIcon
                    }
                ]
            } : null,
            coupon?.can_view ? {
                id: 'coupon',
                title: 'Coupon',
                type: 'collapse',
                icon: icons.LocalOfferTwoToneIcon,

                children: [
                    coupon?.can_create ? {
                        id: 'add coupon',
                        title: 'Add Coupon',
                        type: 'item',
                        url: '/coupon/add',
                        icon: AddCircleOutlineTwoToneIcon,
                        target: false
                    } : null,
                    {
                        id: 'view coupon',
                        title: 'View Coupon',
                        type: 'item',
                        url: '/coupon/view',
                        icon: ViewAgendaTwoToneIcon,
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null,
            notification?.can_view ? {
                id: 'notification',
                title: 'Notification',
                type: 'item',
                url: '/notification',
                icon: icons.NotificationsNoneIcon
            } : null,
            products?.can_view ? {
                id: 'products',
                title: 'Products',
                type: 'collapse',
                icon: icons.ShoppingBagTwoToneIcon,

                children: [
                    products?.can_create ? {
                        id: 'add product',
                        title: 'Add Product',
                        type: 'item',
                        url: '/product/add',
                        icon: AddShoppingCartTwoToneIcon,
                        target: false
                    } : null,
                    {
                        id: 'view product',
                        title: 'View Product',
                        type: 'item',
                        icon: ShoppingCartTwoToneIcon,
                        url: '/product/view',
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null,
            consultation?.can_view ? {
                id: 'Consultation',
                title: 'Consultation',
                type: 'collapse',
                icon: icons.GroupsTwoToneIcon,

                children: [
                    // {
                    //     id: 'schedule',
                    //     title: 'Add Schedule',
                    //     type: 'item',
                    //     icon: AssessmentOutlinedIcon,
                    //     url: '/consultation/schedule',
                    //     target: false
                    // },
                    {
                        id: 'viewBookings',
                        title: 'View Bookings',
                        type: 'item',
                        icon: AssignmentIndIcon,
                        url: '/consultation/view',
                        target: false
                    },
                    consultation?.can_create ? {
                        id: 'dashboard',
                        title: 'Dashboard',
                        type: 'item',
                        icon: DashboardIcon,
                        url: '/consultation/dashboard',
                        target: false
                    } : null,
                    // {
                    //     id: 'slotSettings',
                    //     title: 'Slot Settings',
                    //     type: 'item',
                    //     icon: SettingsIcon,
                    //     url: '/consultation/slotsetting',
                    //     target: false
                    // }
                ].filter((item) => item !== null)
            } : null,
            ingredients?.can_view || recipe?.can_view || verification?.can_view ? {
                id: 'nutrition plan',
                title: 'Nutrition Plan',
                type: 'collapse',
                icon: DiningTwoToneIcon,
                children: [
                    ingredients?.can_view ? {
                        id: 'ingredients panel',
                        title: 'Ingredients Panel',
                        type: 'item',
                        icon: EggTwoToneIcon,
                        url: '/nutrition/ingredients',
                        target: false
                    } : null,
                    recipe?.can_view ? {
                        id: 'recipe panel',
                        title: 'Recipe Panel',
                        type: 'item',
                        icon: MenuBookTwoToneIcon,
                        url: '/nutrition/recipe',
                        target: false
                    } : null,
                    {
                        id: 'verificationV2',
                        title: 'Verification Panel v2',
                        type: 'item',
                        icon: VerifiedTwoToneIcon,
                        url: `${process.env.REACT_APP_VERIFICATION_V2_URL}plan/verification`,
                        target: true,
                        external:true
                    },
                    // verification?.can_view ? {
                    //     id: 'verification',
                    //     title: 'Verification Panel',
                    //     type: 'item',
                    //     icon: VerifiedTwoToneIcon,
                    //     url: '/nutrition/verification',
                    //     target: false
                    // } : null,
                    // {
                    //     id: 'ProgressTracker',
                    //     title: 'Progress Tracker',
                    //     type: 'item',
                    //     icon: VerifiedTwoToneIcon,
                    //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
                    //     target: true,
                    //     external:true
                    // }
                ].filter((item) => item !== null)
            } : null,
            barcode?.can_view ? {
                id: 'Barcode',
                title: 'Add Barcode',
                type: 'collapse',
                icon: QrCode2Icon,
                children: [
                    barcode?.can_create ? {
                        id: 'Add_Barcode',
                        title: 'Add Code',
                        type: 'item',
                        url: '/barcode/add',
                        icon: icons.AddIcon,
                        target: false
                    } : null,
                    {
                        id: 'View Barcode',
                        title: 'View Code',
                        type: 'item',
                        url: '/barcode/view',
                        icon: TableViewIcon,
                        target: false
                    }
                ].filter((item) => item !== null)
            } : null
        ].filter((item) => item !== null);

            // if (role === '6' || role === '7') {
    //     return [
    //         // {
    //         //     id: 'default',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     url: '/',
    //         //     icon: icons.IconDashboard
    //         // },
    //         {
    //             id: 'Reports',
    //             title: 'Reports',
    //             type: 'collapse',
    //             icon: BiotechTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'BloodReport',
    //                     title: 'Blood Reports',
    //                     type: 'item',
    //                     url: '/user/report',
    //                     icon: icons.BloodSample,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'DNAReport',
    //                     title: 'DNA Report',
    //                     type: 'item',
    //                     icon: DNATestIcon,
    //                     url: '/customer-field'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'slotSettings',
    //                 //     title: 'Slot Settings',
    //                 //     type: 'item',
    //                 //     icon: SettingsIcon,
    //                 //     url: '/consultation/slotsetting',
    //                 //     target: false
    //                 // }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification Panel',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 },
    //                 //  {
    //                 //     id: 'verificationV2',
    //                 //     title: 'Verification Panel v2',
    //                 //     type: 'item',
    //                 //     icon: VerifiedTwoToneIcon,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}`,                
    //                 //     target: true,
    //                 //     external:true
    //                 // },
    //                 // {
    //                 //     id: 'ProgressTracker',
    //                 //     title: 'Progress Tracker',
    //                 //     type: 'item',
    //                 //     icon: VerifiedTwoToneIcon,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //                 //     target: true,
    //                 //     external:true
    //                 // }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //     ];
    // }
    // if (role === '10') {
    //     return [
    //         // {
    //         //     id: 'default',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     url: '/',
    //         //     icon: icons.IconDashboard
    //         // },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'Reports',
    //             title: 'Reports',
    //             type: 'collapse',
    //             icon: BiotechTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'BloodReport',
    //                     title: 'Blood Reports',
    //                     type: 'item',
    //                     url: '/user/report',
    //                     icon: icons.BloodSample,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'DNAReport',
    //                     title: 'DNA Report',
    //                     type: 'item',
    //                     icon: DNATestIcon,
    //                     url: '/customer-field'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'slotSettings',
    //                 //     title: 'Slot Settings',
    //                 //     type: 'item',
    //                 //     icon: SettingsIcon,
    //                 //     url: '/consultation/slotsetting',
    //                 //     target: false
    //                 // }
    //             ]
    //         },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification Panel',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'verificationV2',
    //                 //     title: 'Verification Panel v2',
    //                 //     type: 'item',
    //                 //     icon: VerifiedTwoToneIcon,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}`,                
    //                 //     target: true,
    //                 //     external:true
    //                 // },
    //             ]
    //         },
    //     ];
    // }
    // if (role === '2') {
    //     return [
    //         {
    //             id: 'Organizations',
    //             title: 'Organizations',
    //             type: 'collapse',
    //             icon: CorporateFareIcon,

    //             children: [
    //                 {
    //                     id: 'view',
    //                     title: 'View',
    //                     type: 'item',
    //                     icon: icons.TableViewIcon,
    //                     url: '/organization/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'Fitness Video',
    //             title: 'Fitness Videos',
    //             type: 'collapse',
    //             icon: icons.FitnessCenterTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'view video',
    //                     title: 'View Videos',
    //                     type: 'item',
    //                     icon: OndemandVideoTwoToneIcon,
    //                     url: '/fitnessvideo/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'user data',
    //             title: 'User Data',
    //             type: 'collapse',
    //             icon: icons.AccountCircleTwoToneIcon,

    //             children: [
    //                 // {
    //                 //     id: 'all user',
    //                 //     title: 'All Users',
    //                 //     type: 'item',
    //                 //     icon: PersonOutlineTwoToneIcon,
    //                 //     url: '/user/all',
    //                 //     target: false
    //                 // },
    //                 // {
    //                 //     id: 'subscribed user',
    //                 //     title: 'Subscribed Users',
    //                 //     type: 'item',
    //                 //     icon: VerifiedUserTwoToneIcon,
    //                 //     url: '/user/subscribed',
    //                 //     target: false
    //                 // },
    //                 {
    //                     id: 'UploadReport',
    //                     title: 'Upload Reports',
    //                     type: 'item',
    //                     url: '/user/newTest',
    //                     icon: BiotechTwoToneIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'notification',
    //             title: 'Notification',
    //             type: 'item',
    //             url: '/notification',
    //             icon: icons.NotificationsNoneIcon
    //         },
    //         {
    //             id: 'products',
    //             title: 'Products',
    //             type: 'collapse',
    //             icon: icons.ShoppingBagTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'view product',
    //                     title: 'View',
    //                     type: 'item',
    //                     icon: ShoppingCartTwoToneIcon,
    //                     url: '/product/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'Barcode',
    //             title: 'Add Barcode',
    //             type: 'collapse',
    //             icon: QrCode2Icon,
    //             children: [
    //                 {
    //                     id: 'Add_Barcode',
    //                     title: 'Add Code',
    //                     type: 'item',
    //                     url: '/barcode/add',
    //                     icon: icons.AddIcon,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'View Barcode',
    //                     title: 'View Code',
    //                     type: 'item',
    //                     url: '/barcode/view',
    //                     icon: TableViewIcon,
    //                     target: false
    //                 }
    //             ]
    //         }
    //     ];
    // }
    // if (role === 'trainer') {
    //     return [
    //         // {
    //         //     id: 'default',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     url: '/',
    //         //     icon: icons.IconDashboard,
    //         //     breadcrumbs: true
    //         // },
    //         {
    //             id: 'Fitness Video',
    //             title: 'Fitness Videos',
    //             type: 'collapse',
    //             icon: icons.FitnessCenterTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'add new video',
    //                     title: 'Add New Video',
    //                     type: 'item',
    //                     icon: QueuePlayNextTwoToneIcon,
    //                     url: '/fitnessvideo/add',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'assign new video',
    //                     title: 'Assign New Videos',
    //                     type: 'item',
    //                     icon: LibraryAddCheckTwoToneIcon,
    //                     url: '/fitnessvideo/assign',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'view video',
    //                     title: 'View Videos',
    //                     type: 'item',
    //                     icon: OndemandVideoTwoToneIcon,
    //                     url: '/fitnessvideo/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'notification',
    //             title: 'Notification',
    //             type: 'item',
    //             url: '/notification',
    //             icon: icons.NotificationsNoneIcon
    //         },
    //         {
    //             id: 'consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'assign_slot',
    //                     title: 'Assign Slots',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 }
    //             ]
    //         }
    //     ];
    // }
    // if (role === 'nutritionist') {
    //     return [
    //         {
    //             id: 'default',
    //             title: 'Dashboard',
    //             type: 'item',
    //             url: '/',
    //             icon: icons.IconDashboard,
    //             breadcrumbs: true
    //         },

    //         {
    //             id: 'user data',
    //             title: 'User Data',
    //             type: 'collapse',
    //             icon: icons.AccountCircleTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'UploadReport',
    //                     title: 'Upload Reports',
    //                     type: 'item',
    //                     url: '/user/newTest',
    //                     icon: BiotechTwoToneIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'notification',
    //             title: 'Notification',
    //             type: 'item',
    //             url: '/notification',
    //             icon: icons.NotificationsNoneIcon
    //         },
    //         {
    //             id: 'products',
    //             title: 'Products',
    //             type: 'collapse',
    //             icon: icons.ShoppingBagTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'view product',
    //                     title: 'View',
    //                     type: 'item',
    //                     icon: ShoppingCartTwoToneIcon,
    //                     url: '/product/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'assign_slot',
    //                     title: 'Assign Slots',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 }
    //             ]
    //         }
    //     ];
    // }
    // if (role === '6') {
    //     return [
    //         // {
    //         //     id: 'default',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     url: '/',
    //         //     icon: icons.IconDashboard
    //         // },
    //         // {
    //         //     id: 'Organizations',
    //         //     title: 'Organizations',
    //         //     type: 'collapse',
    //         //     icon: CorporateFareIcon,

    //         //     children: [
    //         //         {
    //         //             id: 'add',
    //         //             title: 'Add Organization',
    //         //             type: 'item',
    //         //             icon: icons.AddIcon,
    //         //             url: '/organization/add'
    //         //         },
    //         //         {
    //         //             id: 'view',
    //         //             title: 'View Organization',
    //         //             type: 'item',
    //         //             icon: icons.TableViewIcon,
    //         //             url: '/organization/view',
    //         //             target: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id: 'Fitness Video',
    //         //     title: 'Fitness Videos',
    //         //     type: 'collapse',
    //         //     icon: icons.FitnessCenterTwoToneIcon,

    //         //     children: [
    //         //         {
    //         //             id: 'add new video',
    //         //             title: 'Add New Video',
    //         //             type: 'item',
    //         //             icon: QueuePlayNextTwoToneIcon,
    //         //             url: '/fitnessvideo/add',
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'assign new video',
    //         //             title: 'Assign New Videos',
    //         //             type: 'item',
    //         //             icon: LibraryAddCheckTwoToneIcon,
    //         //             url: '/fitnessvideo/assign',
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'view video',
    //         //             title: 'View Videos',
    //         //             type: 'item',
    //         //             icon: OndemandVideoTwoToneIcon,
    //         //             url: '/fitnessvideo/view',
    //         //             target: false
    //         //         }
    //         //     ]
    //         // },
    //         {
    //             id: 'user data',
    //             title: 'User Data',
    //             type: 'collapse',
    //             icon: icons.AccountCircleTwoToneIcon,

    //             children: [
    //                 // {
    //                 //     id: 'all user',
    //                 //     title: 'All Users',
    //                 //     type: 'item',
    //                 //     icon: PersonOutlineTwoToneIcon,
    //                 //     url: '/user/all',
    //                 //     target: false
    //                 // },
    //                 // {
    //                 //     id: 'subscribed user',
    //                 //     title: 'Subscribed Users',
    //                 //     type: 'item',
    //                 //     icon: VerifiedUserTwoToneIcon,
    //                 //     url: '/user/subscribed',
    //                 //     target: false
    //                 // },
    //                 {
    //                     id: 'UploadReport',
    //                     title: 'Upload Reports',
    //                     type: 'item',
    //                     url: '/user/newTest',
    //                     icon: BiotechTwoToneIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         // {
    //         //     id: 'user assessment',
    //         //     title: 'User Video Assessment',
    //         //     type: 'collapse',
    //         //     icon: VideoCameraFrontIcon,

    //         //     children: [
    //         //         {
    //         //             id: 'new assessment',
    //         //             title: 'New Assessment',
    //         //             type: 'item',
    //         //             url: '/assessment/new',
    //         //             target: false,
    //         //             icon: VideoLibraryIcon
    //         //         },
    //         //         {
    //         //             id: 'approved video',
    //         //             title: 'Approved Videos',
    //         //             type: 'item',
    //         //             url: '/assessment/approved',
    //         //             target: false,
    //         //             icon: HowToRegIcon
    //         //         },
    //         //         {
    //         //             id: 'rejected video',
    //         //             title: 'Rejected Videos',
    //         //             type: 'item',
    //         //             url: '/assessment/rejected',
    //         //             target: false,
    //         //             icon: ThumbDownOffAltIcon
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id: 'coupon',
    //         //     title: 'Coupon',
    //         //     type: 'collapse',
    //         //     icon: icons.LocalOfferTwoToneIcon,

    //         //     children: [
    //         //         {
    //         //             id: 'add coupon',
    //         //             title: 'Add Coupon',
    //         //             type: 'item',
    //         //             url: '/coupon/add',
    //         //             icon: AddCircleOutlineTwoToneIcon,
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'view coupon',
    //         //             title: 'View Coupon',
    //         //             type: 'item',
    //         //             url: '/coupon/view',
    //         //             icon: ViewAgendaTwoToneIcon,
    //         //             target: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id: 'notification',
    //         //     title: 'Notification',
    //         //     type: 'item',
    //         //     url: '/notification',
    //         //     icon: icons.NotificationsNoneIcon
    //         // },
    //         // {
    //         //     id: 'products',
    //         //     title: 'Products',
    //         //     type: 'collapse',
    //         //     icon: icons.ShoppingBagTwoToneIcon,

    //         //     children: [
    //         //         {
    //         //             id: 'add product',
    //         //             title: 'Add Product',
    //         //             type: 'item',
    //         //             url: '/product/add',
    //         //             icon: AddShoppingCartTwoToneIcon,
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'view product',
    //         //             title: 'View Product',
    //         //             type: 'item',
    //         //             icon: ShoppingCartTwoToneIcon,
    //         //             url: '/product/view',
    //         //             target: false
    //         //         }
    //         //     ]
    //         // },
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 // {
    //                 //     id: 'schedule',
    //                 //     title: 'Add Schedule',
    //                 //     type: 'item',
    //                 //     icon: AssessmentOutlinedIcon,
    //                 //     url: '/consultation/schedule',
    //                 //     target: false
    //                 // },
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'slotSettings',
    //                     title: 'Slot Settings',
    //                     type: 'item',
    //                     icon: SettingsIcon,
    //                     url: '/consultation/slotsetting',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         // {
    //         //     id: 'nutrition plan',
    //         //     title: 'Nutrition Plan',
    //         //     type: 'collapse',
    //         //     icon: DiningTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'ingredients panel',
    //         //             title: 'Ingredients Panel',
    //         //             type: 'item',
    //         //             icon: EggTwoToneIcon,
    //         //             url: '/nutrition/ingredients',
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'recipe panel',
    //         //             title: 'Recipe Panel',
    //         //             type: 'item',
    //         //             icon: MenuBookTwoToneIcon,
    //         //             url: '/nutrition/recipe',
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'verification',
    //         //             title: 'Verification Panel',
    //         //             type: 'item',
    //         //             icon: VerifiedTwoToneIcon,
    //         //             url: '/nutrition/verification',
    //         //             target: false
    //         //         }
    //         //     ]
    //         // },
    //         // {
    //         //     id: 'Barcode',
    //         //     title: 'Add Barcode',
    //         //     type: 'collapse',
    //         //     icon: QrCode2Icon,
    //         //     children: [
    //         //         {
    //         //             id: 'Add_Barcode',
    //         //             title: 'Add Code',
    //         //             type: 'item',
    //         //             url: '/barcode/add',
    //         //             icon: icons.AddIcon,
    //         //             target: false
    //         //         },
    //         //         {
    //         //             id: 'View Barcode',
    //         //             title: 'View Code',
    //         //             type: 'item',
    //         //             url: '/barcode/view',
    //         //             icon: TableViewIcon,
    //         //             target: false
    //         //         }
    //         //     ]
    //         // }
    //     ];
    // }

    // if (role === '11') {
    //     return [
    //         // {
    //         //     id: 'default',
    //         //     title: 'Dashboard',
    //         //     type: 'item',
    //         //     url: '/',
    //         //     icon: icons.IconDashboard
    //         // },
    //         {
    //             id: 'Organizations',
    //             title: 'Organizations',
    //             type: 'collapse',
    //             icon: CorporateFareIcon,

    //             children: [
    //                 {
    //                     id: 'add',
    //                     title: 'Add Organization',
    //                     type: 'item',
    //                     icon: icons.AddIcon,
    //                     url: '/organization/add'
    //                 },
    //                 {
    //                     id: 'view',
    //                     title: 'View Organization',
    //                     type: 'item',
    //                     icon: icons.TableViewIcon,
    //                     url: '/organization/view',
    //                     target: false
    //                 }
    //             ]
    //         },
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'Reports',
    //             title: 'Reports',
    //             type: 'collapse',
    //             icon: BiotechTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'BloodReport',
    //                     title: 'Blood Reports',
    //                     type: 'item',
    //                     url: '/user/report',
    //                     icon: icons.BloodSample,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'DNAReport',
    //                     title: 'DNA Report',
    //                     type: 'item',
    //                     icon: DNATestIcon,
    //                     url: '/customer-field'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'coupon',
    //             title: 'Coupon',
    //             type: 'collapse',
    //             icon: icons.LocalOfferTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'add coupon',
    //                     title: 'Add Coupon',
    //                     type: 'item',
    //                     url: '/coupon/add',
    //                     icon: AddCircleOutlineTwoToneIcon,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'view coupon',
    //                     title: 'View Coupon',
    //                     type: 'item',
    //                     url: '/coupon/view',
    //                     icon: ViewAgendaTwoToneIcon,
    //                     target: false
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'notification',
    //             title: 'Notification',
    //             type: 'item',
    //             url: '/notification',
    //             icon: icons.NotificationsNoneIcon
    //         },
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification Panel',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'verificationV2',
    //                 //     title: 'Verification Panel v2',
    //                 //     type: 'item',
    //                 //     icon: VerifiedTwoToneIcon,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}`,
    //                 //     target: true,
    //                 //     external:true
    //                 // },
    //                 // {
    //                 //     id: 'ProgressTracker',
    //                 //     title: 'Progress Tracker',
    //                 //     type: 'item',
    //                 //     icon: Progress,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //                 //     target: true,
    //                 //     external:true
    //                 // }
    //             ]
    //         },
    //     ];
    // }
    // if(role === '12') {
    //     return [
    //         //          {
    //         //     id: 'admin panel v2',
    //         //     title: 'Admin Panel V2',
    //         //     type: 'collapse',
    //         //     icon: icons.AccountCircleTwoToneIcon,
    //         //     children: [
    //         //         {
    //         //             id: 'customer dashboard',
    //         //             title: 'Customer Dashboard',
    //         //             type: 'item',
    //         //             icon: icons.AccountCircleTwoToneIcon,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=customerDashboard`,
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //         {
    //         //             id: 'ProgressTracker',
    //         //             title: 'Progress Tracker',
    //         //             type: 'item',
    //         //             icon: Progress,
    //         //             url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //         //             target: true,
    //         //             external:true
    //         //         },
    //         //     ]
    //         // },
    //         {
    //             id: 'Reports',
    //             title: 'Reports',
    //             type: 'collapse',
    //             icon: BiotechTwoToneIcon,

    //             children: [
    //                 {
    //                     id: 'BloodReport',
    //                     title: 'Blood Reports',
    //                     type: 'item',
    //                     url: '/user/report',
    //                     icon: icons.BloodSample,
    //                     target: false
    //                 },
    //                 {
    //                     id: 'DNAReport',
    //                     title: 'DNA Report',
    //                     type: 'item',
    //                     icon: DNATestIcon,
    //                     url: '/customer-field'
    //                 },
    //             ]
    //         },
    //         {
    //             id: 'user assessment',
    //             title: 'User Video Assessment',
    //             type: 'collapse',
    //             icon: VideoCameraFrontIcon,

    //             children: [
    //                 {
    //                     id: 'new assessment',
    //                     title: 'New Assessment',
    //                     type: 'item',
    //                     url: '/assessment/new',
    //                     target: false,
    //                     icon: VideoLibraryIcon
    //                 },
    //                 {
    //                     id: 'approved video',
    //                     title: 'Approved Videos',
    //                     type: 'item',
    //                     url: '/assessment/approved',
    //                     target: false,
    //                     icon: HowToRegIcon
    //                 },
    //                 {
    //                     id: 'rejected video',
    //                     title: 'Rejected Videos',
    //                     type: 'item',
    //                     url: '/assessment/rejected',
    //                     target: false,
    //                     icon: ThumbDownOffAltIcon
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'notification',
    //             title: 'Notification',
    //             type: 'item',
    //             url: '/notification',
    //             icon: icons.NotificationsNoneIcon
    //         },
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,

    //             children: [
    //                 // {
    //                 //     id: 'schedule',
    //                 //     title: 'Add Schedule',
    //                 //     type: 'item',
    //                 //     icon: AssessmentOutlinedIcon,
    //                 //     url: '/consultation/schedule',
    //                 //     target: false
    //                 // },
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'slotSettings',
    //                 //     title: 'Slot Settings',
    //                 //     type: 'item',
    //                 //     icon: SettingsIcon,
    //                 //     url: '/consultation/slotsetting',
    //                 //     target: false
    //                 // }
    //             ]
    //         },
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'verification',
    //                     title: 'Verification Panel',
    //                     type: 'item',
    //                     icon: VerifiedTwoToneIcon,
    //                     url: '/nutrition/verification',
    //                     target: false
    //                 },
    //                 // {
    //                 //     id: 'verificationV2',
    //                 //     title: 'Verification Panel v2',
    //                 //     type: 'item',
    //                 //     icon: VerifiedTwoToneIcon,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}`,
    //                 //     target: true,
    //                 //     external:true
    //                 // },
    //                 // {
    //                 //     id: 'ProgressTracker',
    //                 //     title: 'Progress Tracker',
    //                 //     type: 'item',
    //                 //     icon: Progress,
    //                 //     url: `${process.env.REACT_APP_VERIFICATION_V2_URL}auth-token/?token=${localStorage.getItem('token')}&page=progressTracker`,                
    //                 //     target: true,
    //                 //     external:true
    //                 // }
    //             ]
    //         },
           
    //     ]
    // }
    // if (role === '13' || role === '14' || role === '15') {
    //     return [
    //         {
    //             id: 'Consultation',
    //             title: 'Consultation',
    //             type: 'collapse',
    //             icon: icons.GroupsTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'viewBookings',
    //                     title: 'View Bookings',
    //                     type: 'item',
    //                     icon: AssignmentIndIcon,
    //                     url: '/consultation/view',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'dashboard',
    //                     title: 'Dashboard',
    //                     type: 'item',
    //                     icon: DashboardIcon,
    //                     url: '/consultation/dashboard',
    //                     target: false
    //                 },
    //             ]
    //         }
    //     ]
    
    // }
    // if(role === '16' || role === '17') {
    //     return [
    //         {
    //             id: 'nutrition plan',
    //             title: 'Nutrition Plan',
    //             type: 'collapse',
    //             icon: DiningTwoToneIcon,
    //             children: [
    //                 {
    //                     id: 'ingredients panel',
    //                     title: 'Ingredients Panel',
    //                     type: 'item',
    //                     icon: EggTwoToneIcon,
    //                     url: '/nutrition/ingredients',
    //                     target: false
    //                 },
    //                 {
    //                     id: 'recipe panel',
    //                     title: 'Recipe Panel',
    //                     type: 'item',
    //                     icon: MenuBookTwoToneIcon,
    //                     url: '/nutrition/recipe',
    //                     target: false
    //                 },
    //             ]
    //         }
    //     ]
    // }
}

const dashboard = organizationPermissions?.can_view || 
fitnessVideo?.can_view || 
uploadReport?.can_view || 
dna?.can_view || 
blood?.can_view || 
usa?.can_view || 
coupon?.can_view || 
notification?.can_view || 
products?.can_view || 
consultation?.can_view ||
ingredients?.can_view || 
recipe?.can_view || 
barcode?.can_view ? {
    id: 'dashboard',
    title: 'APP MANAGEMENT',
    type: 'group',
    children: roles()
} : null;

export default dashboard;
