/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import { Pagination, Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment, Switch } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ReactLoading from 'react-loading';
import CircularProgress from '@material-ui/core/CircularProgress';

const ViewCoupon = () => {
    // Authenticated();
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [searchKey, setSearchKey] = useState('coupon_title');
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);

    const filterOptions = [
        { value: 'coupon_title', label: 'Coupon' },
        { value: 'org_name', label: 'Organization' },
    ]

    const columns = [
        { title: 'S.No', field: 'sno' },
        { title: 'Coupon', field: 'title' },
        { title: 'Organization', field: 'org_name' },
        { title: 'Applicable On', field: 'product_id' },
        { title: 'Offer', field: 'percent' },
        { title: 'Issue Date', field: 'created_at' },
        { title: 'Expiry Date', field: 'expire_date' },
        { title: 'Total Issued', field: 'max_used' },
        { title: 'Total Used', field: 'total_used' },
        { title: 'Channel Partner', field: 'channel_partner' },
        { title: 'Active', field: 'is_active' }
    ];

    const [Data, setData] = useState(null);


    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        // const response = await axios.get(`http://localhost:4002/admin/user/getCoupon?page=${page}&size=${limit}&key=${searchKey}&search=${searchValue}`);
        // response.data.data.forEach((element) => {
        //     array.push(element);
        // });
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getCoupon?page=${page}&size=${limit}&key=${searchKey}&search=${searchValue}`);
        response.data.data.forEach((element) => {
            array.push(element);
        });
        setLoadingsearch(false);
        setTotal(response.data.total_count);
        setData(array);
    };

    useEffect(() => {
        // Authenticated();
        setLoadingsearch(true);
    }, []);

    useEffect(() => {
        setPage(1);
    }, [searchKey, searchValue]);

    let searchTimeout;

    useEffect(() => {
        clearTimeout(searchTimeout);
        setLoadingsearch(true);

        searchTimeout = setTimeout(() => {
            if(searchValue) getData();
        }, 2000);

        return () => {
            clearTimeout(searchTimeout);
        };
    }, [
        page, limit, searchKey, searchValue
    ])

    const deactive = (item) => {
        axios.patch(`${process.env.REACT_APP_BASEURL}admin/user/coupon_status?coupon_id=${item.id}`,{is_active:!item.can_deactive}).then(()=>{
            getData()
        })
    }

    useEffect(() => {
        if(!searchValue) getData();
    }, [page, limit, searchKey, searchValue]);

    const arr = [];

    console.log('hello', Data);
    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: (limit * (page - 1)) + i + 1,
                title: item.title,
                percent: `${item.percent}%`,
                created_at: item.created_at,
                expire_date: item.expire_date,
                max_used: item.max_used,
                total_used: item.total_used,
                product_id: item.product_id?.split(',').join('  |  '),
                org_name: item.org_name,
                channel_partner: item.channel_partner,
                is_active: <Switch checked={item.is_active}
                onChange={() => deactive(item)} 
                inputProps={{ 'aria-label': 'controlled' }}
                />
            });
        }
    });

    const handlePageChange = (event, value) => {
        setPage(value);
        // getData();
    };

    return (
        <MainCard>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    margin: "0 10px"
                                }}
                            >
                                <FormControl sx={{ m: 1, minWidth: 180 }}>
                                    <InputLabel id="select-search-key-label">Search by</InputLabel>
                                    <Select
                                        labelId="select-search-key-label"
                                        id="select-search-key"
                                        value={searchKey}
                                        label="Search by"
                                        onChange={(event) => { setSearchKey(event.target.value) }}
                                    >
                                        {filterOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                &nbsp;&nbsp;
                                <TextField
                                    autoComplete="off"
                                    key="search"
                                    autoFocus="autoFocus"
                                    id="standard-basic"
                                    placeholder='Search'
                                    variant="standard"
                                    // style={{ marginBottom: '1rem' }}
                                    InputProps={{
                                        startAdornment: (
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                                <InputAdornment position="end">
                                                    <SearchRoundedIcon />
                                                </InputAdornment>
                                            </div>),
                                    }}
                                    value={searchValue}
                                    onChange={(e) => {
                                        if (searchKey === '') alert('Please select search key first');
                                        else setSearchValue(e.target.value); setLoadingsearch(true);
                                    }}
                                />
                            </div>
            <div>

                <MaterialTable
                    isLoading={loadingsearch}
                    title={null}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        // pageSize: 20,
                        toolbar:false,
                        paging: false,
                        search: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return { backgroundColor: '#eef9ff' };
                            }
                        }
                    }}
                />
                <div
                    width="100%"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '1rem',
                        width: '100%'
                    }}
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={limit}
                            label="Results per Page"
                            onChange={(event) => { setLimit(event.target.value) }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    &nbsp;&nbsp;
                    <Pagination
                        count={Math.ceil(total / limit)}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        size="small"
                    />
                </div>
            </div>
        </MainCard>
    );
};

export default ViewCoupon;
