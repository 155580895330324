import styled from 'styled-components';

export const wrapper = styled.div``;

export const Container = styled.div`
    padding: 10px;
`;

export const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
