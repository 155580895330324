import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import styled from 'styled-components';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  borderCollapse: "separate",
  borderRadius: "0.7rem",
  boxShadow: 24,
  p: 4,
};

const Content = styled.div`
    flex: 1;
    height: 100%;
`;

const ViewProduct = () => {
    // Authenticated();
    const [open, setOpen] = React.useState(false);
    const [priceDetails, setPriceDetails] = React.useState(null);
    const handleOpen = (e, item) => {setOpen(true);setPriceDetails(item);};
    const handleClose = () => {setOpen(false)};

    const columns = [
        { title: 'S.No', field: 'sno' },
        { title: 'Product Name', field: 'package_title' },
        { title: 'Product Type', field: 'productType' },
        { title: 'Product Details', field: 'tags' },
        { title: 'Organization', field: 'org_name' },
        { title: 'Added On', field: 'created_at' },
        { title: 'Pricing Details', field: 'view' },

        // { title: 'Special Price', field: 'special_price' }
    ];

    const [Data, setData] = React.useState(null);

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getSubscriptionPlan`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        const response = await axios.get(baseURL);

        response?.data?.data?.forEach((element) => {
            array.push(element);
        });
        console.log("API DATA", array);
        setData(array);
    };

    React.useEffect(() => {
        // Authenticated();
        getData();
    }, []);

    React.useEffect(() => {
        console.log("Price Details", priceDetails);
    }, [priceDetails]);

    const arr = [];

    console.log('hello', Data);
    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: i + 1,
                package_title: item.package_title,
                org_name: item.org_name,
                created_at: item.created_at,
                productType: item.is_add_on ? "Add on" : "Core",
                // standard_price: `₹ ${item.standard_price}`,
                // special_price: `₹ ${item.special_price}`,
                tags: item.tags.split(',').join('  |  '),
                view: <Button variant="contained" sx={{color: "#fff", cursor: "pointer"}} onClick={(e) => handleOpen(e, item)}>View</Button>
                // standard_price_gst: `₹ ${item.standard_price_gst}`
            });
        }
    });

    return (
        <MainCard>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
                <Content>
                        <h3>Price Table</h3>
                    </Content>
                    <Table sx={{ maxWidth: 600, border:"1px solid #C4C4C4", borderCollapse: "separate", borderRadius: "1rem"}} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {
                                priceDetails && priceDetails.has_durations ? 
                                <>

                                    <TableCell align='center'>Sl. No.</TableCell>
                                    <TableCell align='center'>Duration (in Months)</TableCell>
                                    <TableCell align='center'>Standard Price</TableCell>
                                    <TableCell align='center'>Special Price</TableCell>
                                    <TableCell align='center'>Standard Price (with GST)</TableCell>
                                    <TableCell align='center'>Special Price (with GST)</TableCell>
                                </>
                                :
                                <>
                                    <TableCell align='center'>Sl. No.</TableCell>
                                    <TableCell align='center'>Standard Price</TableCell>
                                    <TableCell align='center'>Special Price</TableCell>
                                    <TableCell align='center'>Standard Price (with GST)</TableCell>
                                    <TableCell align='center'>Special Price (with GST)</TableCell>
                                </>
                            }
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                priceDetails && !priceDetails.has_durations ?
                                <TableRow
                                key="0"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">1</TableCell>
                                    <TableCell align="center">{priceDetails.standard_price}</TableCell>
                                    <TableCell align="center">{priceDetails.special_price}</TableCell>
                                    <TableCell align="center">{priceDetails.standard_price_gst}</TableCell>
                                    <TableCell align="center">{priceDetails.special_price_gst}</TableCell>
                                </TableRow>
                            :
                            priceDetails && priceDetails.durations.map((duration, i) => <TableRow
                                key="1"
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{i + 1}</TableCell>
                                    <TableCell align="center">{duration.life}</TableCell>
                                    <TableCell align="center">{duration.standard_price}</TableCell>
                                    <TableCell align="center">{duration.special_price}</TableCell>
                                    <TableCell align="center">{duration.standard_price_gst}</TableCell>
                                    <TableCell align="center">{duration.special_price_gst}</TableCell>
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Modal>
            <div>
                <MaterialTable
                    title={null}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        // pageSize: 20,
                        paging: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return { backgroundColor: '#eef9ff' };
                            }
                        }
                    }}
                />
            </div>
        </MainCard>
    );
};

export default ViewProduct;
