/* eslint-disable jsx-a11y/media-has-caption */
import MainCard from 'ui-component/cards/MainCard';
import { Authenticated } from 'utils/checkerfunction';
import { Button, TextField } from '@mui/material';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useState, forwardRef } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { UserData } from 'utils/userData';

const MainWrapper = styled.div`
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    max-width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    padding: 1rem;
`;

const VideoContainerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: #212121;
`;

const VideoContainer = styled.div`
    width: 30%;
    max-height: 40%;
    text-align: center;
    video {
        background-color: #e8e8e8;
        width: 80%;
        height: 60%;
        padding: 2rem;
        border-radius: 15px;
    }
`;

const FormContainer = styled.div`
    width: 100%;
`;

const UserDataConatiner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 80%;
    align-items: left;
    margin: 2rem 0;
    h3 {
        margin: 10px;
        color: #000;
        padding: 13px;
    }
`;

const InformationContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    table {
        text-align: center;
        border-collapse: collapse;
        border: 1px solid black;

        th {
            font-size: 1.2rem;
            padding: 2rem;
            border: 1px solid black;
        }

        tr {
            font-size: 1rem;
            padding: 2rem;
        }
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        margin: 0.5rem;
    }
`;

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const ApprovedView = () => {
    // Authenticated();
    const finalPermission = UserData().permissions
    const usa = finalPermission?.find(permission => permission.name === "User Video Assessment");

    const search = useLocation().search;
    const name = new URLSearchParams(search).get('name');
    const uservideo = new URLSearchParams(search).get('uservideo');
    const trainervideo = new URLSearchParams(search).get('trainervideo');
    const activityname = new URLSearchParams(search).get('activityname');
    const level = new URLSearchParams(search).get('level');
    const category = new URLSearchParams(search).get('category');
    const day = new URLSearchParams(search).get('day');
    const orgname = new URLSearchParams(search).get('orgname');
    const enable = new URLSearchParams(search).get('enable');

    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        data.is_approved = value;
        data.id = new URLSearchParams(search).get('user_video_day_id');
        console.log(data);

        const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/addViedoComment`;

        axios.defaults.headers.common = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        };

        axios.post(baseURL, data).then((response) => {
            console.log(response.data.result);
            if (response.data.result === 'success') {
                handleClick();
                setTimeout(() => {
                    window.open('/assessment/new', '_self');
                }, 100);
            } else {
                alert('Failed to Update');
            }
        });
    };

    console.log(errors);

    return (
        <MainCard title="Review ">
            <Button onClick={() => window.open('/assessment/new', '_self')} style={{ color: 'darkgrey' }}>
                ← Back
            </Button>
            <MainWrapper>
                <UserDataConatiner>
                    <h3>Activity : {activityname}</h3>
                    <h3>Day : {day}</h3>
                    <h3>Level : {level}</h3>
                    <h3>Category : {category}</h3>
                    <h3>Organization : {orgname}</h3>
                </UserDataConatiner>
                <VideoContainerWrapper>
                    <VideoContainer>
                        <h3>Instuctor Video</h3>
                        <video controls controlsList="nodownload">
                            <source src={trainervideo} type="video/mp4" />
                        </video>
                    </VideoContainer>
                    <VideoContainer>
                        <h3>{name} User Video</h3>
                        <video controls controlsList="nodownload">
                            <source src={uservideo} type="video/mp4" />
                        </video>
                    </VideoContainer>
                </VideoContainerWrapper>
                <FormContainer>
                    {enable && UserData().role === '1' ? (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <h2> Trainer Feedback</h2>
                            <TextField
                                disabled={!usa?.can_create}
                                {...register('comment', { required: true, maxLength: 100 })}
                                label="Comment"
                                style={{ width: '60%' }}
                            />
                            <br />
                            <br />
                            <div>
                                <Button
                                    variant="contained"
                                    disabled={!usa?.can_create}
                                    style={{ backgroundColor: 'green', color: 'white' }}
                                    onClick={() => setValue(1)}
                                    type="submit"
                                >
                                    Approve
                                </Button>
                                <span />
                                <Button
                                    variant="contained"
                                    disabled={!usa?.can_create}
                                    style={{ backgroundColor: 'red', color: 'white' }}
                                    onClick={() => setValue(2)}
                                    type="submit"
                                >
                                    Reject
                                </Button>
                            </div>
                        </Form>
                    ) : null}
                </FormContainer>
            </MainWrapper>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Comment Added Successfully
                </Alert>
            </Snackbar>
        </MainCard>
    );
};

export default ApprovedView;
