/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import MainCard from 'ui-component/cards/MainCard';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useEffect, useState, forwardRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// ============================|| StyleZone ||============================ //

const Formdiv = styled.div`
    label {
        color: #000;
        font-size: 1rem;
    }
`;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50
    },
    preview: {
        marginTop: 50,
        display: 'flex',
        flexDirection: 'column'
    },
    image: { maxWidth: '100%', maxHeight: 320 },

    delete: {
        cursor: 'pointer',
        padding: 15,
        background: 'red',
        color: 'white',
        border: 'none'
    }
};

const AddNewVideo = () => {
    const [selectedImage, setSelectedImage] = useState();
    const navigate = useNavigate();
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const [Level, setLevel] = useState(null);
    const [Work, setWork] = useState(null);
    const [Category, SetCategory] = useState(null);
    const [tag, setTag] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event) => {
        const levels = ['Beginner', 'Intermediate', 'Advance'];
        const workouts = ['Endurance', 'Strength', 'Mix'];
        if (levels.includes(event.target.value)) {
            setLevel(event.target.value);
        } else if (workouts.includes(event.target.value)) {
            setWork(event.target.value);
        } else {
            SetCategory(event.target.value);
        }
    };

    const removeSelectedImage = () => {
        setSelectedImage();
    };
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        const formData = new FormData();

        formData.append('video_title', data.video_title);
        formData.append('video_description', data.video_description);
        formData.append('download_url', selectedImage);
        formData.append('tag', [...tag]);
        formData.append('calorie', data.calorie);
        formData.append('calorie2', data.calorie2);
        formData.append('level', Level);
        formData.append('workout', Work);
        formData.append('category', Category);
        console.log(Category, Work, Level);

        // axios.defaults.headers.common = {
        //     Authorization: `Bearer ${process.env.REACT_APP_BERER_TOKEN}`
        // };

        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/addFitnessVideos`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((response) => {
                console.log(response.data.result);
                if (response.data.result === 'success') {
                    handleClick();
                    setTimeout(() => {
                        navigate('/fitnessvideo/view');
                    }, 200);
                } else {
                    console.log('error');
                }
            });
    };

    useEffect(() => {
        // Authenticated();
    }, []);

    console.log(errors);

    const Arr = [
        { data: 'Neck', tag: 1 },
        { data: 'Shoulder', tag: 2 },
        { data: 'Arm', tag: 3 },
        { data: 'Waist', tag: 4 },
        { data: 'Knee', tag: 5 },
        { data: 'Pelvic', tag: 6 },
        { data: 'Toe', tag: 7 },
        { data: 'Buttocks', tag: 8 },
        { data: 'Trap', tag: 9 },
        { data: 'Triceps', tag: 10 },
        { data: 'Thighs', tag: 11 },
        { data: 'Lower Back', tag: 12 },
        { data: 'Side', tag: 13 },
        { data: 'Thigh', tag: 14 },
        { data: 'Chest', tag: 15 },
        { data: 'Stomach', tag: 16 },
        { data: 'Abs', tag: 17 },
        { data: 'Back', tag: 18 },
        { data: 'Biceps', tag: 19 },
        { data: 'Forearm', tag: 20 },
        { data: 'Shoulders', tag: 21 }
    ];

    return (
        <MainCard>
            <div style={{ width: '100%' }}>
                <Formdiv style={{ width: '50%', padding: '40px' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label>Select Fitness Video</label>
                        <br />
                        <br />
                        <TextField type="file" id="img" name="img" accept="video/mp4,video/x-m4v,video/*" onChange={imageChange} />
                        <br />
                        <br />
                        {selectedImage && (
                            <div style={styles.preview}>
                                <video width="400" controls>
                                    <source src={URL.createObjectURL(selectedImage)} />
                                </video>
                                <br />
                                <br />
                                <Button onClick={removeSelectedImage}>Remove This Video</Button>
                            </div>
                        )}
                        <label>Activity Name</label>
                        <br />
                        <br />
                        <TextField
                            id="activity"
                            label="Activity Name"
                            variant="outlined"
                            {...register('video_title', { required: true, maxLength: 80 })}
                        />
                        <br />
                        <br />
                        <FormLabel id="selectLevel">Select Level:</FormLabel>
                        <RadioGroup value={Level} onChange={handleChange}>
                            <FormControlLabel value="Beginner" control={<Radio />} label="Beginner" />
                            <FormControlLabel value="Intermediate" control={<Radio />} label="Intermediate" />
                            <FormControlLabel value="Advance" control={<Radio />} label="Advance" />
                        </RadioGroup>
                        <br />
                        <br />
                        <FormLabel id="demo-row-radio-buttons-group-label">Select Workout type:</FormLabel>
                        <RadioGroup value={Work} onChange={handleChange}>
                            <FormControlLabel value="Endurance" control={<Radio />} label="Endurance" />
                            <FormControlLabel value="Strength" control={<Radio />} label="Strength" />
                            <FormControlLabel value="Mix" control={<Radio />} label="Mix" />
                        </RadioGroup>
                        <br />
                        <br />
                        <FormLabel id="demo-row-radio-buttons-group-label">Select Category:</FormLabel>
                        <RadioGroup value={Category} onChange={handleChange}>
                            <FormControlLabel value="Warm up" control={<Radio />} label="Warm up" />
                            <FormControlLabel value="Main" control={<Radio />} label="Main" />
                            <FormControlLabel value="Cool Down" control={<Radio />} label="Cool Down" />
                        </RadioGroup>
                        <br />
                        <br />
                        <FormLabel id="description">Description :</FormLabel>
                        <br />
                        <br />
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder="Empty"
                            style={{ width: '60%', minHeight: '20%' }}
                            {...register('video_description', { required: true })}
                        />
                        <br />
                        <br />
                        <FormLabel id="Tags">Tags</FormLabel>
                        <br />
                        <br />
                        <Autocomplete
                            multiple
                            id="Autocomplete"
                            options={Arr}
                            disableCloseOnSelect
                            onChange={(event, value) => setTag(value.map((v) => v.data))}
                            getOptionLabel={(option) => option.data}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.data}
                                </li>
                            )}
                            style={{ width: 500 }}
                            renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
                        />
                        <br />
                        <br />
                        <label>Calories Burnt:</label>
                        <br />
                        <br />
                        <FormLabel id="PriceLabel">
                            <TextField
                                id="MinCal"
                                label="Min"
                                variant="outlined"
                                type="number"
                                {...register('calorie', { required: true, maxLength: 4 })}
                            />
                            <span>&nbsp;</span>
                            <TextField
                                id="MaxCal"
                                label="Max"
                                variant="outlined"
                                type="number"
                                {...register('calorie2', { required: true, maxLength: 4 })}
                            />{' '}
                        </FormLabel>
                        <br />
                        <br />
                        <br />
                        <Button variant="contained" type="submit">
                            Add Video
                        </Button>
                    </form>
                </Formdiv>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Video Added Successfully
                </Alert>
            </Snackbar>
        </MainCard>
    );
};

export default AddNewVideo;
