import React from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const PdfView = () => {
  const query = useQuery();
  const pdfUrl = query.get('pdf');
  if (!pdfUrl) {
    return <div>No PDF URL provided.</div>;
  }
  const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
    pdfUrl
  )}&embedded=true`;

  return(
    <>
<div>
<iframe src={viewerUrl} title="PDF Viewer" style={{ width: '100%', height: '1000px' }} frameBorder="0" />
  </div>
  </>
  );
};

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

// const PdfView = () => {
//     let query = useQuery();
//     console.log("pdf", query.get("pdf"))

//     return (
//         <iframe
//             src={query.get("pdf")}
//             style={{ width: '100vw', height: '100vh' }}
//             frameborder="0"
//         />

//     );
// }
export default PdfView;
