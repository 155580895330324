import React, { useState, useEffect } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useNavigate } from "react-router";
import { Button, TextField, MenuItem, Select } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Header, GrayBox, MidWhiteBox } from './style';
import axios from "axios";
import './style.css'
import { useLocation } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const AddNew = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [isEdit, setIsEdit] = useState(false);
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [qualification, setQualification] = useState('');
    const [experience, setExperience] = useState('');
    // const [designation , setDesignation] = useState('');
    const [initalData, setInitalData] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [RegionTake, setRegionTake] = useState(null);
    const [Region, setRegion] = useState([]);
    const [open, setOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastSeverity, setToastSeverity] = useState("success");

    const success = (message) => {
        setToastSeverity("success");
        setToastMessage(message);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const errorToast = (message) => {
        setToastSeverity("error");
        setToastMessage(message);
        setOpen(true);
    }

    const warningToast = (message) => {
        setToastSeverity("warning");
        setToastMessage(message);
        setOpen(true);
    }

    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);


    useEffect(() => {
        if (location.state) {
            setIsEdit(true);
            setName(location.state.item.name);
            setId(location.state.item.id);
            setQualification(location.state.item.educational_qualification);
            setExperience(location.state.item.year_of_experience);
            // setDesignation(location.state.item.role_id);
            setRoleId(location.state.item.role_id);
            setRegionTake(location.state.item.region);
            setInitalData(location.state.item);
        }
        const getRoles = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/consultation/roles`);
            setRoles(response.data.data);
        }
        const getRegion = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/region`);
            setRegion(response.data.data);
        }
        getRegion();
        getRoles();
    }, []);

    const updateConsultant = async () => {
        console.log("update");
        if (initalData.name === name && initalData.educational_qualification === qualification && initalData.year_of_experience === experience && initalData.roleId === roleId && initalData.region === RegionTake) {
            warningToast("No changes made");
        } else {
            const data = {
                name,
                educational_qualification: qualification,
                year_of_experience: experience,
                designation: getDesignation(roleId),
                role_id: roleId,
                region: RegionTake,
            }
            try {
                const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/consultation/consultants/${id}`, data);
                if (res.data.status === "success") {
                    success(res.data.message)
                    setTimeout(() => {
                        navigate(-1);
                    }, 1000);
                }
            }
            catch (err) {
                errorToast("Name, Designation, Year of Experience, and Educational Qualification Cannot be Empty.");
            }
        }
    }

    const getDesignation = (id) => {
        const item = roles.find((item) => item.id === id);
        return item.name;
    }


    console.log("addnew ");
    return (
        <>
            <MainCard>
                <Header>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <ArrowCircleLeftIcon
                            onClick={() => {
                                navigate(-1);
                            }}
                            sx={{ cursor: 'pointer', color: '#02a9f4' }}
                        />
                        &nbsp;&nbsp;
                        <span> {isEdit ? 'Update information' : 'Add Consultant'} </span>
                    </div>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{
                            color: 'white'
                        }}
                        onClick={updateConsultant}
                    >
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </Header>
                <GrayBox>
                    <div className="box-row" >
                        <div className="input-container1" >
                            <p>Name</p>
                            <TextField
                                id="name"
                                placeholder="Name"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="input-container2">
                            <p>Educational Qualification</p>
                            <TextField
                                id="qualification"
                                placeholder="Educational Qualification"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={qualification}
                                onChange={(e) => setQualification(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="box-row">
                        <div className="input-container1">
                            <p>Years of Experience</p>
                            <TextField
                                id="years"
                                placeholder="Years of Experience"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                                onKeyPress={(event) => {
                                    if (event.target.value === "0" && event.key === "0") {
                                        event.preventDefault();
                                    }
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>
                        <div className="input-container1">
                            <p>Designation</p>
                            <Select
                                size="small"
                                placeholder="Doctor"
                                value={roleId}
                                sx={{ width: '100%' }}
                                onChange={(e) => setRoleId(e.target.value)}
                            >
                                {roles.map((item) => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="input-container1">
                            <p>Region</p>
                            <Select
                                size="small"
                                placeholder="Region"
                                value={RegionTake}
                                sx={{ width: '100%' }}
                                onChange={(e) => setRegionTake(e.target.value)}
                            >
                                {Region.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {/* <div className="box-row">
                    <div className="note" >
                    <p>Note</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi in, velit et molestiae eaque obcaecati.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi in, velit et molestiae eaque obcaecati.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi in, velit et molestiae eaque obcaecati.</p>
                    </div>
                </div> */}
                </GrayBox>
            </MainCard>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AddNew;