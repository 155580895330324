// assets
import { IconDashboard } from '@tabler/icons';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ContactsIcon from '@mui/icons-material/Contacts';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { UserData } from 'utils/userData';

// constant

const icons = { IconDashboard };
const role = UserData().role;
const finalPermission = UserData().permissions
const appAcess = finalPermission?.find(permission => permission.name === "App Access");

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const settings = appAcess?.can_view ? {
    id: 'settings',
    title: 'SETTINGS',
    type: 'group',
    children: [
        appAcess?.can_view ? {
            id: 'app access',
            title: 'App Access',
            type: 'item',
            url: '/setting/access',
            icon: icons.IconDashboard,
            breadcrumbs: false
        } : null,
        // roleAccess?.can_view ?{
        //     id: 'Roles',
        //     title: 'Roles',
        //     type: 'collapse',
        //     icon: VpnKeyOutlinedIcon,
        //     children: [
        //         roleAccess?.can_create ? {
        //             id: 'Create Roles',
        //             title: 'Create Roles',
        //             type: 'item',
        //             url: '/roles/add',
        //             icon: PersonAddAltIcon
        //         } : null,
        //         {
        //             id: 'UploadReport',
        //             title: 'View Roles',
        //             type: 'item',
        //             url: '/roles/view',
        //             icon: PeopleOutlineOutlinedIcon
        //         }
        //     ].filter((item) => item !== null)
        // } : null
    ].filter((item) => item !== null)
} : null;

export default settings;
