import React, { useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Divider } from 'rsuite';
import Configuration from './Configuration';
import SlotSelectionUpdated from './SlotSelection';
import axios from 'axios';
import { MenuItem, Select } from '@mui/material';
import moment from 'moment';
import * as _ from 'lodash';
import SaveIcon from '@mui/icons-material/Save';


const styles = {
    divider: {
        height: '2px',
        backgroundColor: '#ADADAD',
    },
    tab: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontSize: 12,
        fontWeight: 500,
        color: '#FFFFFF',
        background: '#1B99DF',
        border: '1px solid #1B99DF',
        borderRadius: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 5,
        minHeight: 30,
        cursor: 'pointer'
    },
    notActive: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontSize: 12,
        fontWeight: 500,
        color: '#1B99DF',
        background: '#FFFFFF',
        border: '1px solid #1B99DF',
        borderRadius: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 5,
        minHeight: 30,
        cursor: 'pointer'
    },
    done: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontSize: 12,
        fontWeight: 500,
        color: '#FFFFFF',
        background: '#4ED32C',
        border: '1px solid #4ED32C',
        borderRadius: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 5,
        minHeight: 30,
        cursor: 'pointer'
    },

    tablist: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        flexWrap: "wrap"
    },
    select_delete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'end',
        gap: 20
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        fontWeight: 500,
        color: "#000000"
    },
    slots: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 30,
        fontWeight: 500,
        paddingBottom: 20
    },
    tabSlot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontSize: 12,
        fontWeight: 500,
        color: '#FFFFFF',
        background: '#EAEAEA',
        border: '1px solid #EAEAEA',
        borderRadius: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 5,
        minHeight: 30,
        cursor: 'not-allowed'
    },
    disabled: {
        background: "#EAEAEA",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        fontSize: 12,
        fontWeight: 500,
        color: '#FFFFFF',
        border: '1px solid #EAEAEA',
        borderRadius: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 5,
        minHeight: 30,
        cursor: 'not-allowed'
    }
}

export default function SlotDashboard({ data, isSlotSelection, setIsSlotSelection, initWeekData, setInitWeekData, weekData, setWeekData, selectedMonthId, setSelectedMonthId, success, errorToast, selectedYearId, setSelectedYearId }) {

    const [selectedDuration, setSelectedDuration] = useState("10")
    const [breakBetweenSlots, setBreakBetweenSlots] = useState("10")
    const [activeWeek, setActiveWeek] = useState(null)
    const [open, setOpen] = useState(false);
    const [weeks, setWeeks] = useState([])
    const [config, setConfig] = useState()
    const [id, setId] = useState(0)


    const monthNames = [
        {name:"January",value:0}, 
        {name:"February",value:1}, 
        {name:"March",value:2}, 
        {name:"April",value:3}, 
        {name:"May",value:4}, 
        {name:"June",value:5},
        {name:"July",value:6}, 
        {name:"August",value:7}, 
        {name:"September",value:8}, 
        {name:"October",value:9}, 
        {name:"November",value:10}, 
        {name:"December",value:11}
    ];




    useEffect(() => {
        if (data.item) {
            setId(data.item.id);
        }
    }, [])

    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    await axios.get(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/user/${id}?month_id=${selectedMonthId + 1}&year=${selectedYearId}`).then((response) => {
                        const arr = []
                        setSelectedDuration(response.data.data[0].slot_configuration.slot_duration)
                        setBreakBetweenSlots(response.data.data[0].slot_configuration.break_duration)
                        response.data.data[0].availabilty.forEach((items, index) => {
                            if (items === undefined) return
                            arr.push({
                                available_timing: items.available_timing,
                                day: items.day,
                                id: items.id,
                                is_selected: items.is_selected
                            })
                        })

                        setConfig(arr)
                    }).catch((error) => {
                        console.log(error);
                    });
                } catch (error) {
                    console.log(error)
                }
            }
        })()
    }, [id, selectedMonthId])



    useEffect(async () => {
        if (isSlotSelection) {
            await fetchWeekData()
        }

    }, [selectedMonthId, isSlotSelection])


    useEffect(async () => {
        if (weeks) {
            await fetchAvailableSlot()
        }
    }, [weeks])


    const slotSelection = async () => {
        setOpen(true)

        try {
            const configPayload = {
                admin_user_id: id,
                month_id: selectedMonthId + 1,
                slot_duration: parseInt(selectedDuration, 10),
                break_duration: parseInt(breakBetweenSlots, 10),
                availablity: config,
                year: selectedYearId
            }
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/slot_configuration`, configPayload)
            if (response.data.is_changed) {
                success(response.data.message)
            }
            setIsSlotSelection(true)

        } catch (error) {
            errorToast(error.response.data.message)
            console.log(error)
        }
    }

    const fetchAvailableSlot = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/slots?admin_user_id=${id}&month_id=${selectedMonthId + 1}&year=${selectedYearId}`)
        const availability = response.data.data
        let startingWeek;
        if (availability) {
            console.log(weeks)
            const availableWeek = availability.map((data) => {
                const currentWeek = weeks.find((week) => week.week_number === data.week_number)
                if (currentWeek) {
                    if (!startingWeek && currentWeek.is_enabled) {
                        startingWeek = currentWeek.week_number
                    }
                    const dateArray = [];
                    let currentDate = moment(currentWeek.week_start_date);
                    const stopDate = moment(currentWeek.week_end_date);
                    while (currentDate <= stopDate) {
                        const currentDateWeek = moment(currentDate).format('YYYY-MM-DD')
                        const currentDateData = data.slots.filter((slot) => slot.date === currentDateWeek)
                        if (currentDateData.length > 0) {
                            const formattedData = {
                                slots: currentDateData,
                                date: moment(currentDate).format('YYYY-MM-DD')
                            }
                            dateArray.push(formattedData)
                        }
                        currentDate = moment(currentDate).add(1, 'days');
                    }
                    return { week_number: currentWeek.week_number, data: dateArray }
                }
                return {}
            })
            setActiveWeek(startingWeek - 1)
            setWeekData(availableWeek)
            console.log(availableWeek)
            const initAvailableWeekData = _.cloneDeep(availableWeek);
            setInitWeekData(initAvailableWeekData)
        }

    }

    const saveUpdates = async () => {
        try {
            const configPayload = {
                admin_user_id: id,
                month_id: selectedMonthId + 1,
                slot_duration: parseInt(selectedDuration, 10),
                break_duration: parseInt(breakBetweenSlots, 10),
                availablity: config,
                year: selectedYearId
            }
            const response = await axios.post(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/slot_configuration`, configPayload)
            if (response.data.is_changed) {
                success(response.data.message)
            } else {
                errorToast("There is no change")
            }
        } catch (error) {
            errorToast(error.response.data.message)
            console.log(error)
        }
    }

    const fetchWeekData = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/v1/consultation/weeks?&month_id=${selectedMonthId + 1}&year=${selectedYearId}`)

        setWeeks(response.data.data)
    }

    const handleSelectedDuration = (e) => {
        setSelectedDuration(e.target.value)
    }
    const handleBreak = (e) => {
        setBreakBetweenSlots(e.target.value)
    }
    const handleSelectedMonth = (e) => {
        const selectedValue = e.target.value;
        const [selectedMonthValue, selectedYear] = selectedValue.split('-');

        setSelectedMonthId(Number(selectedMonthValue));
        setSelectedYearId(Number(selectedYear));
    }
    const handleWeeks = (index) => {
        setActiveWeek(index)
    }


    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getUTCFullYear();
    
    const monthsToShow = 12;

    console.log('hello',selectedMonthId)

    return (
        <>
            <div className='select_slots' >
                <div className="select_slots_child" >
                    <h4 className="heading" >Slots Duration  <br />(in mins)</h4>
                    <input placeholder='Slot Duration' onKeyPress={(event) => {
                        if (event.target.value === "" && event.key === "0") {
                            event.preventDefault();
                        }
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} disabled={isSlotSelection} type="text" className={isSlotSelection ? 'input_time_child_disabled' : 'input_time_child_normal'} onChange={(e) => handleSelectedDuration(e)} value={selectedDuration} />

                </div>

                <div className="select_slots_child" >
                    <h4 className="heading" >Break Between Slots <br /> (in mins)</h4>
                    <input placeholder='Break Between Slots' onKeyPress={(event) => {
                        if (event.target.value === "" && event.key === "0") {
                            event.preventDefault();
                        }
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} disabled={isSlotSelection} type="text" className={isSlotSelection ? 'input_time_child_disabled' : 'input_time_child_normal'} onChange={(e) => handleBreak(e)} value={breakBetweenSlots} />

                </div>

                <div className="select_slots_child" >
                    <h4 className="heading" >Month <br /> &nbsp;</h4>
                    <Select
                        disabled={isSlotSelection}
                        SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8, minWidth: "160px" } }}
                        sx={{
                            boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.12)", height: "40px",
                            minHeight: "40px", paddingLeft: "6px",
                            '.MuiSelect-select': {
                                padding: 0
                            },
                            '.MuiInputBase-input': {
                                height: "20px",
                                minHeight: "20px",
                                maxHeight: '150px',
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                padding: 0,
                                border: "2px solid #ADADAD"
                            }, '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: "2px solid #ADADAD"
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: "2px solid #ADADAD"
                            }
                        }}
                        value={`${selectedMonthId}-${selectedYearId}`}
                        onChange={(e) => { handleSelectedMonth(e) }}
                        fullWidth


                    >
                   {Array.from({ length: monthsToShow }).map((_, index) => {
                    const monthIndex = (currentMonth + index) % 12;
                    const year = currentYear + Math.floor((currentMonth + index) / 12);

                    return (
                        <MenuItem key={index} value={`${monthNames[monthIndex].value}-${year}`}>
                        {monthNames[monthIndex].name}&nbsp;{year}
                        </MenuItem>
                    );
                    })}
                    </Select>
                </div>

            </div>
            <div style={{ padding: "20px" }}>

                <Divider style={styles.divider} />
            </div>

            <div className="selection-row week-selection" style={{
                justifyContent: 'space-between',
                marginBottom: '-10px'
            }}>
                <div>
                    <h4 className="heading">{!isSlotSelection ? "Configure availability time" : "Select slots for weeks"}</h4>
                </div>
                {isSlotSelection && <div style={styles.tablist} id="weeks">
                    {weeks.map((week, index) => (
                        <button key={index} style={week.is_enabled ? activeWeek === index ? styles.tab : styles.notActive : styles.disabled} type="button" disabled={!week.is_enabled} onClick={() => handleWeeks(index)}>Week {week.week_number}</button>
                    ))}


                </div>}
                <div>
                    <div style={styles.tablist}>
                        {isSlotSelection ?
                            <>
                                <button type='button' onClick={() => setIsSlotSelection(false)} style={styles.tab}>
                                    <ArrowBackIosIcon style={{ fontSize: "small" }} />
                                    <span> Configuration</span>
                                </button>
                                <button type='button' style={styles.tabSlot}><span>Slot Selection</span> <ArrowForwardIosIcon style={{ fontSize: "small" }} /> </button>
                            </> :
                            <>
                                <button type='button' onClick={() => saveUpdates()} style={styles.tab}>
                                    <SaveIcon style={{ fontSize: "small" }} />
                                    <span>Save</span>
                                </button>
                                <button type='button' style={styles.tabSlot}> <ArrowBackIosIcon style={{ fontSize: "small" }} /> Configuration</button>
                                <button type='button' onClick={() => slotSelection()} style={styles.tab}>Slot Selection <ArrowForwardIosIcon style={{ fontSize: "small" }} /></button>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div style={{ padding: "20px" }}><Divider style={styles.divider} /></div>

            {
                isSlotSelection ? <SlotSelectionUpdated weekData={weekData} setWeekData={setWeekData} initWeekData={initWeekData} activeWeek={activeWeek} /> : <Configuration data={data} config={config} setConfig={setConfig} id={id} setId={setId} errorToast={errorToast} />
            }
        </>
    )
}
