import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const Authenticated = () => {
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    // eslint-disable-next-line consistent-return
    axios.get(`${process.env.REACT_APP_BASEURL}admin/user/tokenCheck`).then((response) => {
        console.log('respose', response.data.code);
        if (response.data.code === '200') {
            return true;
        }
        localStorage.clear();
             window.location.replace(`${process.env.REACT_APP_VERIFICATION_V2_URL}login`);

    }).catch( async (error) => {
        await localStorage.clear();
             window.location.replace(`${process.env.REACT_APP_VERIFICATION_V2_URL}login`);

    });
};
