/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0
};

const ViewVideo = () => {
    // Authenticated();

    const columns = [
        { title: 'S.No', field: 'sno' },
        {
            title: 'Thumbnail',
            field: 'thumb_url',
            // eslint-disable-next-line jsx-a11y/alt-text
            render: (rowData) => <img src={rowData.thumb_url} style={{ width: 80 }} />
        },
        { title: 'Added On', field: 'created_at' },
        { title: 'Activity', field: 'video_title' },
        { title: 'Day', field: 'day_id' },
        { title: 'Level', field: 'levels' },
        { title: 'Category', field: 'category' },
        { title: 'Workout Type', field: 'workout_type' },
        { title: 'Pressure Points', field: 'tag' },
        { title: 'Video', field: 'download_url' }
    ];

    const [Data, setData] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [url, setUrl] = React.useState('');

    const handleOpen = (link) => {
        setUrl(link);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getVideoList`;

    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        const response = await axios.get(baseURL);

        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
    };

    React.useEffect(() => {
        // Authenticated();
        getData();
    }, []);

    const arr = [];

    console.log('hello', Data);
    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: i + 1,
                created_at: item.created_at,
                video_title: item.video_title,
                day_id: item.day_id,
                levels: item.levels,
                category: item.category,
                workout_type: item.workout_type,
                tag: item.tag,
                download_url: item.download_url ? <Button onClick={() => handleOpen(item.download_url)}>View</Button> : null,
                thumb_url: item.thumb_url
            });
        }
    });

    return (
        <MainCard>
            <div>
                <MaterialTable
                    title={null}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        pageSize: 20,
                        // paging: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return { backgroundColor: '#eef9ff' };
                            }
                        }
                    }}
                />
            </div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <video width="100%" controls autoPlay controlsList="nodownload">
                        <source src={url} type="video/mp4" />
                    </video>
                </Box>
            </Modal>
        </MainCard>
    );
};

export default ViewVideo;
