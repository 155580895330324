import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { UserData } from 'utils/userData';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const redirect = UserData().logoRedirection;

return(    
    <ButtonBase disableRipple component={Link} to={redirect ? redirect : config.defaultPath}>
        <Logo />
    </ButtonBase>
)};

export default LogoSection;
