import React, { useEffect } from 'react';
import MainCard from '../../../ui-component/cards/MainCard';
import { Button, Checkbox, Chip, FormControlLabel, Input, MenuItem, Select, TextField } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { ButtonContainer, BottomContainer, Container, Content, LeftWrapper, RightContainer, ListSchedule, Data } from './style';
import axios from 'axios';
import { useNavigate } from 'react-router';

const AddSchedule = () => {
    const [data, setData] = React.useState(null);
    const [clearedData, setClearedData] = React.useState(null);
    const [dateState, setDateState] = React.useState(new Date());
    const [selected, setSelected] = React.useState(new Set());
    const [user, setUserData] = React.useState(null);
    const [userId, setUserId] = React.useState(null);
    const [link, setLink] = React.useState('');
    const [basic, setBasic] = React.useState(false);
    const [secondData, setSecondData] = React.useState(false);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    const handleChange = (e) => {
        setDateState(e);
        console.log('Handel Change Happnned', e);
        const finalValue = data?.find((i) => i.date === moment(e).format('DD-MM-YYYY'));
        console.log('Final Value', finalValue);
        setClearedData(finalValue);
    };

    const Refetcher = () => {
        const finalValue = data?.find((i) => i.date === moment(dateState).format('DD-MM-YYYY'));
        console.log('Final Value', finalValue);
        setClearedData(finalValue);
    };

    const linkChange = (event) => {
        setLink(event.target.value);
        console.log('value is:', event.target.value);
    };

    const navigate = useNavigate();

    const SubmitLink = (id) => {
        console.log('Clicked');
        console.log('link is:', link, 'id is:', id);
        setLink('');
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/updateCalenderLink`, {
                id,
                link
            })
            .then((res) => {
                if (res.data.result === 'success') {
                    alert('Link Assigned Successfully');
                    // cleanup and reRender the component
                    setLink('');
                } else alert.error('Something went wrong');
            });
    };

    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getNutritionists`).then((response) => {
            setUserData(response.data.response);
            console.log(response.data.response);
        });

        if (userId !== null) {
            axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getuserCalender?userId=${userId}`).then((response) => {
                setData(response.data.response);
                console.log('dateSheet', response.data.response);
            });
        }

        Refetcher();
    }, [userId, basic]);

    // whenever data is changed, this useEffect will be called to update the clearedData
    useEffect(() => {
        Refetcher();
    }, [data]);

    function handleSelectionChanged(id) {
        // treat state as immutable
        // React only does a shallow comparison so we need a new Set
        const newSet = new Set(selected);
        if (newSet.has(id)) newSet.delete(id);
        else newSet.add(id);

        const newArray = Array.from(newSet);
        setSelected(newArray);
    }

    const SubmitForm = () => {
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/user/setUnavailabilityUserCalender`, {
                ids: selected.toString().toString()
            })
            .then((res) => {
                if (res.data.result === 'success') {
                    alert('Successfully Blocked');
                    // cleanup and reRender the component
                    setSelected([]);
                    setBasic(!basic);
                    // refresh the page in order to see the changes
                    window.location.reload();
                } else alert.error('Something went wrong');
            });
    };

    const userList = user?.map((i) => <MenuItem value={i.user_id}>{i.name}</MenuItem>);

    const listItems = (
        <div>
            {clearedData ? (
                clearedData?.slots.map((value) => (
                    <>
                        {value.book_status === 1 || value.book_status === 2 ? (
                            <FormControlLabel
                                control={<Checkbox value={value.id} onChange={() => handleSelectionChanged(value.id)} />}
                                label={value.times}
                                style={{ textDecoration: 'line-through' }}
                                disabled
                            />
                        ) : (
                            <FormControlLabel
                                control={<Checkbox value={value.id} onChange={() => handleSelectionChanged(value.id)} />}
                                label={value.times}
                            />
                        )}
                    </>
                ))
            ) : (
                <h1 style={{ textAlign: 'center', lineHeight: '2' }}>You cannot update time slots for past date</h1>
            )}
        </div>
    );

    const malaki = (event) => {
        setUserId(event.target.value);
    };

    const ThatDaySchedule = (
        <div>
            {clearedData?.slots.map((value, i) => (
                <>
                    {value.book_status === 2 ? (
                        <Data>
                            <h3>{i + 1}</h3>
                            <h3>{value.times}</h3>
                            <h3>{value.username}</h3>
                            <h3>{value.phone}</h3>
                            {console.log('value is:', value.link === null)}
                            {value.link !== null && value.link !== '' ? (
                                <p>{value.link}</p>
                            ) : (
                                <TextField id="outlined-basic" label="Meeting Link" variant="outlined" onChange={linkChange} />
                            )}
                            {value.link !== null && value.link !== '' ? (
                                <Button variant="outlined" onClick={() => window.open(value.link, '_blank')}>
                                    Join
                                </Button>
                            ) : (
                                <Button variant="outlined" onClick={() => SubmitLink(value.id)}>
                                    Assign
                                </Button>
                            )}
                        </Data>
                    ) : null}
                </>
            ))}
        </div>
    );

    React.useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize)
    })

    console.log('Selected Result', selected);
    return (
        <MainCard>
            <Container
                direction={windowWidth > 800 ? 'row' : 'column'}
            >
                <LeftWrapper>
                    <h2>Select User</h2>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={userId} label="Age" onChange={malaki}>
                        {userList}
                    </Select>
                    {console.log('User List', user)}
                    <h2>Select Date</h2>
                    <Calendar value={dateState} onChange={handleChange} />
                </LeftWrapper>
                <RightContainer>
                    <h2>{clearedData?.date}</h2>
                    <Content>{listItems}</Content>
                    {clearedData ? (
                        <ButtonContainer>
                            <Button onClick={SubmitForm} variant="contained">
                                Submit
                            </Button>
                        </ButtonContainer>
                    ) : null}
                </RightContainer>
            </Container>
            <BottomContainer>
                <h2>Bookings for {moment(dateState).format(' D MMM YYYY')}</h2>
                <ListSchedule>{ThatDaySchedule}</ListSchedule>
            </BottomContainer>
        </MainCard>
    );
};

export default AddSchedule;
