import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import { Pagination, Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../utils/userData';
import ReactLoading from 'react-loading';
import CircularProgress from '@material-ui/core/CircularProgress';

const NewScheduledTest = () => {
    const [Data, setData] = React.useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(100);
    const [searchKey, setSearchKey] = useState('email');
    const [searchValue, setSearchValue] = useState('');
    const [loadingsearch, setLoadingsearch] = useState(false);

    const navigate = useNavigate();

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/newUserReports?page=${page}&size=${limit}&key=${searchKey}&value=${searchValue}`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    const array = [];
    const getData = async () => {
        const response = await axios.get(baseURL);
        setTotal(response.data.total_count);
        setLoadingsearch(false);
        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
    };

    let searchTimeout;

    React.useEffect(() => {
        setLoadingsearch(true);
        clearTimeout(searchTimeout);
        
        searchTimeout = setTimeout(() => {
            if(searchValue){
            // Authenticated();
            getData();}
        }, 2000);
        
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [page, limit, searchValue, searchKey]);

    useEffect(() => {
        setPage(1);
    }, [searchValue, limit, searchKey]);

    useEffect(() => {
        if(!searchValue) getData();
    }, [page, limit, searchValue, searchKey]);

    const columns = [
        { title: 'S.No', field: 'id' },
        { title: 'Organization', field: 'org_name' },


        { title: 'Customer Id', field: 'customer_id', render: (rowData) => {
            if (rowData && rowData.customer_id) {
              return rowData.customer_id;
            }
            return 'null';
          },
        },





        {
            title: 'Name',
            field: 'name'
        },
        { title: 'Email', field: 'email' },
        { title: 'NAF Report', field: 'naf' },
        {
            title: 'Diet Plan',
            field: 'diet'
        },
        { title: 'Blood Test', field: 'blood' },
        {
            title: 'DNA Test',
            field: 'dna'
        },
        {
            title: 'Prescribe Workout',
            field: 'Prescribe_Workout',
            render: (rowData) => <Button onClick={() => navigate(`/fitnessvideo/prescribe/${rowData.user_id}`)} disabled={!uploadReport?.can_view} >Assign</Button>
        },
        { title: 'Pincode ', field: 'Blood_Pincode' },
        { title: 'Barcode', field: 'qrcode' }
    ];

    const handlePageChange = (event, value) => {
        setPage(value);
        // getData();
    };

    const arr = [];
    const finalPermission = UserData().permissions
    const uploadReport = finalPermission?.find(permission => permission.name === "Upload Reports");

    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        arr.push({
            id: (limit * (page - 1)) + i + 1,
            user_id: item.user_id,
            customer_id: item.customer_id,
            name: item.name,
            email: item.email,
            org_name: item.org_name,
            qrcode: item.qrcode,
            blood: item.blood_schedule_date ? (
                <Button
                    onClick={() =>{
                        // navigate(`/user/upload-report/?id=${item.user_id}&reportType=Blood&user=${item.name}&email=${item.email}&phone=${item.phone}&customer_id=${item.customer_id}`, { state: { email: item.email, phone: item.phone } });
                        navigate(`/user/upload-report`, {
                            state: { 
                              id: item.user_id,
                              reportType: 'Blood',
                              email: item.email,
                              phone: item.phone,
                              name: item.name,
                              customerId: item.customer_id,
                            }
                          });
                        localStorage.setItem('bloodReports', JSON.stringify(item.bloodHistoryList))}
                    }
                >
                    {item.blood_schedule_date}
                </Button>
            ) : (
                <Button disabled>No</Button>
            ),
            Blood_Pincode: item.blood_pincode,
            naf: item.naf ? (
                <Button onClick={() => navigate(`/user/naf/?userid=${item.user_id}`)} disabled={!uploadReport?.can_view}>View</Button>
            ) : (
                <Button disabled>No</Button>
            ),
            dna: item.dna_schedule_date ? (
                <Button
                    onClick={() => {
                        // navigate(`/user/upload-report/?id=${item.user_id}&reportType=Dna&user=${item.name}&url=${encodeURIComponent(item.dna_url)}&email=${item.email}&phone=${item.phone}&customer_id=${item.customer_id}`, { state: { email: item.email, phone: item.phone } })
                        navigate(`/user/upload-report`, {
                            state: {
                              id: item.user_id,
                              reportType: 'Dna',
                              name: item.name,
                              url: item.dna_url,
                              email: item.email,
                              phone: item.phone,
                              customerId: item.customer_id,
                            }
                          })
                    }}
                >
                    {item.dna_schedule_date}
                </Button>
            ) : (
                <Button disabled>No</Button>
            ),
            diet: item.diet ? (
                <Button
                    onClick={() => {
                        // navigate(`/user/upload-report/?email=${item.email}&phone=${item.phone}&id=${item.user_id}&reportType=Diet&user=${item.name}&url=${item.diet_url}&customer_id=${item.customer_id}`, { state: { email: item.email, phone: item.phone } })
                        navigate(`/user/upload-report`, {
                            state: {
                              email: item.email,
                              phone: item.phone,
                              id: item.user_id,
                              reportType: 'Diet',
                              name: item.name,
                              url: item.diet_url,
                              customerId: item.customer_id,
                            }
                        });        
                        }                  
                    }
                    disabled={!uploadReport?.can_view}
                >
                    View
                </Button>
            ) : (
                <Button disabled>No</Button>
            )
        });
    });

    //

    return (
        <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                margin: "0 10px"
                            }}
                        >
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel id="select-search-key-label">Search by</InputLabel>
                                <Select
                                    labelId="select-search-key-label"
                                    id="select-search-key"
                                    value={searchKey}
                                    label="Search by"
                                    onChange={(event) => { setSearchKey(event.target.value) }}
                                >
                                    <MenuItem value='customer_id'>Customer Id</MenuItem>
                                    <MenuItem value='name'>Name</MenuItem>
                                    <MenuItem value='email'>Email</MenuItem>
                                    <MenuItem value='org_name'>Organization</MenuItem>
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;
                            <TextField
                                autoComplete="off"
                                key="search"
                                autoFocus="autoFocus"
                                id="standard-basic"
                                placeholder='Search'
                                variant="standard"
                                // style={{ marginBottom: '1rem' }}
                                InputProps={{
                                    startAdornment: (
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                            <InputAdornment position="end">
                                                <SearchRoundedIcon />
                                            </InputAdornment>
                                        </div>
                                    ),
                                }}
                                value={searchValue}
                                onChange={(e) => {
                                    if (searchKey === '') alert('Please select search key first');
                                    else setSearchValue(e.target.value); setLoadingsearch(true); setPage(1);
                                }}
                            />
                            {/* &nbsp;&nbsp;&nbsp; */}
                            {/* <Button 
                        variant="contained" 
                        size="small"
                        sx={{color: 'white'}} 
                        onClick={() => {
                        if(searchKey === '' || searchValue === '') {
                            alert('Please select search key and enter search value');
                        } else {
                            getData();
                        }
                    }}>Search</Button> */}
                            {/* <div style={{ width: "13rem" }}>
                        <MTableToolbar {...props} />
                    </div> */}
                        </div>
        <>
                <MaterialTable
                isLoading={loadingsearch}
                title={null}
                icons={tableIcons}
                columns={columns}
                data={arr}
                options={{
                    toolbar: false,
                    search: false,
                    // pageSize: 5,
                    paging: false,
                    maxBodyHeight: '700px',
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF',
                        fontWeight: 100
                    },

                    // eslint-disable-next-line consistent-return
                    rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                            return { backgroundColor: '#eef9ff' };
                        }
                    }
                }}
            />
            <div
            width="100%"
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem'
            }}
        >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={limit}
                    label="Results per Page"
                    onChange={(event) => { setLimit(event.target.value) }}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
            &nbsp;&nbsp;
            <Pagination
                count={Math.ceil(total / limit)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                size="small"
            />
        </div>
        </>
        </div>
    );
};

export default NewScheduledTest;
