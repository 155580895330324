// eslint-disable-next-line import/prefer-default-export
export const UserData = () => {
    const localStorage = window.localStorage;
    let role = localStorage.getItem('roleId');
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('userName');
    const email = localStorage.getItem('email');
    const roleName = localStorage.getItem('roleName');
    const userId = localStorage.getItem('userId');
    const logoRedirection = localStorage.getItem('logoRedirection');
    const permissions = localStorage.getItem('permissions');

    // Devloper mode

    if (role === null || role === '') {
        role = '1';
    }

    const a = {};
    a.role = role;
    a.token = token;
    a.name = name;
    a.email = email;
    a.roleName = roleName;
    a.userId = userId;
    a.logoRedirection = logoRedirection;
    a.permissions = JSON.parse(permissions);
    return a;
};
