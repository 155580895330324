import React from 'react'
import dnaTest from './DNATestgrey.png';

function DNATestIcon() {
  return (
    <>
    <img width="24px" height="24px" src={dnaTest} alt="DNA"/>
    </>
    // <DNAtest/>
  )
}

export default DNATestIcon