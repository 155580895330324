/* eslint-disable jsx-a11y/label-has-associated-control */
import MainCard from 'ui-component/cards/MainCard';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Authenticated } from '../../../utils/checkerfunction';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Checkbox, FormControlLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

// ============================|| StyleZone ||============================ //

const Formdiv = styled.div`
    label {
        color: grey;
        font-size: 1rem;
    }
`;

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

//  Functions

const alphanumeric = (len) => {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < len; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

const AddCoupon = () => {
    // Authenticated();
    //  Hooks
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [toastSeverity, setToastSeverity] = useState("success");
    const [toastMessage, setToastMessage] = useState("");
    const [org, setOrg] = useState('');
    const [Data, setData] = useState(null);
    const [Channels, setChannels] = useState(null);
    const [Channel, setChannel] = useState('');
    const [checked, setChecked] = useState(false);
    const handleClick = () => setChecked(!checked);
    const [productId, setProductId] = useState(null);
    // Function

    const success = (message) => {
        setToastSeverity("success");
        setToastMessage(message);
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastMessage("");
        setOpen(false);
    }

    const errorToast = (message) => {
        setToastSeverity("error");
        setToastMessage(message);
        setOpen(true);
    }

    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    // Submit
    const onSubmit = (data) => {
        data.duration_id = [];
        console.log("prod title", data.product_title)
        data.product_id.forEach(product => {
            productId.forEach(item => {
                if(String(item.package_id) === product.split(" ")[0] && item.package_title === product.split(" ").slice(1).join(" ")){
                    if(item.duration_id !== null && !data.duration_id.includes(String(item.duration_id))){
                        data.duration_id.push(String(item.duration_id));
                    }
                }
            })
        })
        data.product_id = data.product_id.map(product => 
            product.split(" ")[0]
        )
        if (!data.channel_partner) {
            data.channel_partner = 'None';
        }
        console.log('Data', data);
        axios.post(`${process.env.REACT_APP_BASEURL}admin/user/v1/add_coupon`, data).then((response) => {
            if (response.data.result === 'success') {
                console.log(response);
                success(response.data.message);
                navigator.clipboard.writeText(
                    ` Hi there, we've issued a ${data.percent}% coupon code exclusively for you. \n \n Your Coupon Code is:   ${data.title} \n \n This coupon will expire on ${data.expire_date}. \n \n Looking forward to start your wellness journey with unlock.fit`
                );
                setTimeout(() => {
                    navigate('/coupon/view');
                }, 2200);
            } else {
                console.log('error');
            }
        }).catch((error)=>{
            const res = error.response.data
            console.log('first',error)
            errorToast(res.message);
            if(res.message === "Coupon title must be between 3 to 50 characters") window.scrollTo(0, 0);
        });
    };

    // Product Data Fetch && SetOrgID
    const handleChange = (event) => {
        setOrg(event.target.value);
        const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getOrgProduct?org_id=${event.target.value}`;
        axios.get(baseURL).then((response) => {
            console.log(response.data.data);
            if (response.data.data.length > 0) {
                setProductId(response.data.data);
            } else {
                setProductId(null);
            }
        });
    };

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getOrgList/`;
    const baseURLChannel = `${process.env.REACT_APP_BASEURL}admin/user/channel_partners/`;

    // Fetch Org List
    useEffect(() => {
        // Authenticated();
        axios.get(baseURLChannel).then((response) => {
            setChannels(response.data.data);
        })
        axios.get(baseURL).then((response) => {
            setData(response.data.data);
        });
    }, []);

    return (
        <MainCard>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <div style={{ width: '100%' }}>
                <div style={{ width: '50%', padding: '40px',color: 'grey', fontSize: '1rem' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <label>Coupon </label> */}
                        <label>Coupon Name :</label>
                        <br />
                        <br />
                        <TextField id="outlined-basic" placeholder="Coupon Name" variant="outlined" {...register('title')} style={{ width: '60%' }} />
                        <br />
                        {/* {showError ? (
                            <div style={{ color: 'red' }}>
                                <p>Coupon Name must be less than 5 characters</p>
                            </div>
                        ) : null} */}
                        <br />
                        {/* <TextField
                            id="outlined-basic"
                            label="Coupon Code"
                            variant="outlined"
                            value={alphanumeric(6)}
                            {...register('coupon', { required: true, minLength: 6, maxLength: 6 })}
                        />
                        <span />
                        <br />
                        <br /> */}
                        <label>Expiry Date :</label>
                        <br />
                        <br />
                        <TextField type="date" id="date" name="date" {...register('expire_date', { required: true })} style={{ width: '60%' }} />
                        <br />
                        <br />
                        <label>Offer Percentage :</label>
                        <br />
                        <br />
                        <TextField
                            id="offer"
                            type="number"
                            placeholder="Percentage %"
                            variant="outlined"
                            style={{ width: '60%' }}
                            inputProps={{
                                min: 0,
                                max: 100,
                            }}
                            {...register('percent', { required: true, minLength: 1, maxLength: 3 })}
                        />{' '}
                        <span />
                        <br />
                        <br />
                        <label>Number of use :</label>
                        <br />
                        <br />
                        <TextField
                            id="use"
                            type="number"
                            placeholder="use"
                            variant="outlined"
                            style={{ width: '60%' }}
                            inputProps={{
                                min: 1,
                                max: 10000
                            }}
                            {...register('max_used', {required: true, minLength: 1, maxLength: 5 })}
                        />
                        <br />
                        <br />
                        <InputLabel id="chooseOrganization">Channel Partner:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            {...register('channel_partner')}
                            value={Channel}
                            placeholder="Select Organization"
                            onChange={(e)=>{setChannel(e.target.value)}}
                            style={{ width: '60%' }}
                        >
                            {Channels && Channels.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
                        </Select>
                        <br />
                        <br />
                        <InputLabel id="chooseOrganization">Choose Organization:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            {...register('org_id', { required: true })}
                            value={org}
                            placeholder="Select Organization"
                            onChange={handleChange}
                            style={{ width: '60%' }}
                        >
                            {Data && Data.map((item) => <MenuItem value={item.id}>{item.org_name}</MenuItem>)}
                        </Select>
                        <br />
                        <br />
                        {productId ? <InputLabel id="chooseProduct">Choose Product:</InputLabel> : null}
                        <br />
                        {productId
                            ? productId.map((item) => (
                                  <>
                                      <InputLabel id="chooseProduct"> </InputLabel>
                                      <FormControlLabel
                                          control={<Checkbox 
                                            color="primary" 
                                            value={item.package_id + " " + item.package_title}  // eslint-disable-line prefer-template
                                            {...register('product_id', {required: true})}
                                            />}
                                          label={item.package_title}
                                      />
                                  </>
                              ))
                            : null}
                        <br />
                        <br />
                        <Button variant="contained" type="submit" sx={{color:'#fff'}}>
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        </MainCard>
    );
};

export default AddCoupon;
