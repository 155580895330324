import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import { Pagination, Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment, Modal, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../../utils/userData';
import excell from "../../../assets/images/excell.svg";
import { Box, color, margin, padding, width } from '@mui/system';
import { GridCloseIcon } from '@mui/x-data-grid';
import styled from 'styled-components';
import moment from 'moment';
import ReactLoading from 'react-loading';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DatePicker1 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { add } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';

const BloodReport = () => {
    const [Data, setData] = React.useState(null);
    const [BloodType, setBloodType] = useState([]);
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchKey, setSearchKey] = useState('email');
    const [searchValue, setSearchValue] = useState('');
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState('');
    const [arr, setArr] = useState([]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [historyModal, setHistoryModal] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [editReportOpen, setEditReportOpen] = useState(false);
    const [addReportOpen, setAddReportOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [sampleCollectionTime, setSampleCollectionTime] = useState({
        hour: "",
        minute: "",
        ampm: "AM",
    });
    const finalPermission = UserData().permissions
    const blood = finalPermission?.find(permission => permission.name === "Blood Reports");

    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

    const [editReportData, setEditReportData] = useState({
        email_id: "",
        customer_id: "",
        receiveDate: "",
        referenceOrderId: "",
    })

    const [addReportData, setAddReportData] = useState({
        customer_id: "",
        sample_collection_date: "",
        sample_collection_time: "",
        thyrocare_id: "",
        diff_address: false,
        address: "",
        type: "",
    })

    const [historyArr, setHistoryArr] = useState([])

    const handleClose = () => setOpen(false);
    const handleEditReportClose = () => {
        setEditReportOpen(false);
        setEditReportData({
            email_id: "",
            customer_id: "",
            receiveDate: "",
            referenceOrderId: "",
        })
    };
    const handleAddReportClose = () => {
        setAddReportOpen(false)
        setAddReportData({
            customer_id: "",
            sample_collection_date: "",
            sample_collection_time: "",
            thyrocare_id: "",
            diff_address: false,
            address: "",
            type: "",
        })
    };

    const handleHistoryModalClose = () => setHistoryModal(false)
    const navigate = useNavigate();

    const [toastMessage, setToastMessage] = useState('');
    const [toastSeverity, setToastSeverity] = useState('success');

    // time interval in AM PM format with 30 min interval
    const timeInterval = ["12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM", "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM", "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM", "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM", "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"];
    const success = (message) => {
        setToastSeverity('success');
        setToastMessage(message);
        setToastOpen(true);
    };

    const CloseSucess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastOpen(false);
    };

    const errorToast = (message) => {
        setToastSeverity('error');
        setToastMessage(message);
        setToastOpen(true);
    }

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/blood_test_report?page=${page}&size=${limit}&key=${searchKey}&value=${searchValue}`;
    // const baseURL = `http://localhost:4002/admin/user/blood_test_report?page=${page}&size=${limit}&key=${searchKey}&value=${searchValue}`;
    const exportBaseURL = `${process.env.REACT_APP_BASEURL}admin/user/blood_test_report/export`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
    const getData = async () => {
        const response = await axios.get(baseURL);
        setIsLoading(false)
        setTotal(response.data.total_count);
        setIsLoading(false);
        setData(response.data.data);
    };
    const getTypes = () => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/user/blood_test_types`).then((response) => {
            const finalData = response.data.data
            console.log('respose', response.data);
            setBloodType(finalData)
        }).catch( async (error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        getTypes()
    }, [])
    
    const buttonStyle = {
        color: 'white',
        fontSize: '0.9rem',
        padding: '5px 45px',
        marginTop: '0px',
        margin: '0px 5px'
    }

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '354px',
        maxHeight: '80vh',
        bgcolor: 'background.paper',
        borderCollapse: "separate",
        borderRadius: "0.7rem",
        boxShadow: 24,
        p: 2,
        paddingTop: '44px',
        overflow: 'auto'
    };

    const editModalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '420px',
        width: '38%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        borderCollapse: "separate",
        borderRadius: "0.7rem",
        boxShadow: 24,
        p: 2,
        paddingTop: '44px',
        overflow: 'auto'
    };

    const modalHistoryTable = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderCollapse: "separate",
        borderRadius: "0.7rem",
        boxShadow: 24,
        p: 2,
        overflow: 'auto',
        'max-height': '50vh'
    };


    const Header = styled.div`
        background: #FAFAFA;
        border: 1px solid #ADADAD;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        font-weight: 500;
        font-family: 'Poppins';
        color: #313131;
        padding: 15px;
        font-size: larger;
        display: flex;
        flex-direction: ${props => props.direction};
        justify-content: space-between;
        align-items: center;
    `;


    const TableHeading = styled.div`
        font-size :  1.5rem;
    `;

    const ModalTableHeading = styled.div`
    font-size :  1rem;
    font-weight: 700;
    `;

    React.useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    })

    let searchTimeout;

    useEffect(() => {
        setIsLoading(true);
        clearTimeout(searchTimeout);
        
        searchTimeout = setTimeout(() => {
            if(searchValue)
            {
            // Authenticated();
            getData();
            }
        }, 2000);
        
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [page, limit, searchValue, searchKey]);

    useEffect(() => {
        setPage(1);
    }, [searchValue, limit, searchKey]);

    useEffect(() => {
        if(!searchValue) getData();
    }, [page, limit, searchValue, searchKey]);

    const columnNames = []

    const jsonObj = [{
        "name": {
            "coloumn_name": "Name"
        },
        "customer_id": {
            "coloumn_name": "Customer Id"
        },
        "email": {
            "coloumn_name": "Email"
        },
        "type": {
            "coloumn_name": "Blood Test Type"
        },
        "phone_number": {
            "coloumn_name": "Phone"
        },
        "address": {
            "coloumn_name": "Address"
        },
        "date_of_blood_request": {
            "coloumn_name": "Request Date"
        },
        "request_status": {
            "coloumn_name": "Request Status"
        },
        "failure_reason": {
            "coloumn_name": " Reason for non-submission"
        },
        "date_of_blood_collection": {
            "coloumn_name": "Sample Collection Date"
        },
        "report_status": {
            "coloumn_name": "Report Status"
        },
        "date_of_generated": {
            "coloumn_name": "Report Generation Date"
        },
        "date_of_upload": {
            "coloumn_name": "Report Upload Date"
        },
        "reschedule_status": {
            "coloumn_name": "Reschedule Status",
        },
        "view_history": {
            "coloumn_name": "View History",
        },
        "created_at": ""
    }]


    const keys = Object.keys(jsonObj[0]);
    for (let i = 0; i < (keys.length - 1); i += 1) {
        const columnObj = {
            title: jsonObj[0][keys[i]].coloumn_name,
            field: keys[i],

        }
        if (keys[i] === "view_history") {
            columnObj.render = ((rowData) => {
                console.log(rowData.reschedule_status)
                if (rowData.reschedule_status === 'Yes') {
                    return <Button variant="contained" onClick={() => {
                        // eslint-disable-next-line array-callback-return
                        const viewHistory = rowData.view_history.map((data) => ({
                            address: data.line_A + ' ' + data.line_B + ' ' + data.landmark + ' ' + data.city + ' ' + data.state + ' ' + data.country + ' ' + data.pincode,
                            startTime: data.from_time,
                            endTime: data.to_time,
                            receiveDate: moment(data.receive_date).format('DD-MM-YYYY'),
                            scheduleDate: moment(data.created_at).format('DD-MM-YYYY')
                        }))
                        console.log(viewHistory)
                        setHistoryModal(true)
                        setHistoryArr(viewHistory)
                    }} sx={{ color: "#fff", cursor: "pointer" }}>View</Button>
                }
                return <Button variant="contained" disabled sx={{ color: "#fff", cursor: "pointer" }}>View</Button>
            })
        }

        columnNames.push(columnObj)
    }

    if (blood?.can_create) {
        const actionColumn = {
          title: 'Action',
          field: 'action',
        };
        columnNames.push(actionColumn);
      }

    React.useEffect(() => {
        if (Data) {
            const arr = [];
            // eslint-disable-next-line array-callback-return
            Data.map((item, i) => {
                arr.push({
                    customer_id: item.customer_id.value,
                    address: item.address.value,
                    date_of_blood_collection: item.date_of_blood_collection.value,
                    date_of_blood_request: item.date_of_blood_request.value,
                    date_of_generated: item.date_of_generated.value,
                    date_of_upload: item.date_of_upload.value,
                    email: item.email.value,
                    type: item.type.value,
                    failure_reason: item.failure_reason.value,
                    name: item.name.value,
                    phone_number: item.phone_number.value,
                    report_status: item.report_status.value,
                    request_status: item.request_status.value,
                    reschedule_status: item.reschedule_status.value,
                    view_history: item.history_detail.value,
                    action: blood?.can_create ? <EditIcon onClick={() => {
                        handleEditReport(item.email.value, item.customer_id.value, item.date_of_blood_collection.value)
                    }} sx={{ color: "#02a9f4", cursor: "pointer" }} /> : ''
                });
            });

            setArr(arr)
        }

    }, [Data]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    async function exportExcel(callUrl) {
        setError('')
        if (callUrl) {
            try {
                setError('')
                const response = await axios.post(exportBaseURL, { "start_date": startDate, "end_date": endDate });
                const excelUrl = response.data.data[0].excel_url
                window.open(excelUrl, "_blank")
                setError('')
            } catch (error) {
                console.log(error.response.data.message)
                setError(error.response.data.message)
            }

        }
        setOpen(true);
    }

    const swap = (dateString) => {
        const dateArray = dateString.split("-")
        const temp = dateArray[0];
        dateArray[0] = dateArray[2];
        dateArray[2] = temp;
        return dateArray.join("/")
    }


    const editModalStyle = {
        row: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            margin: "5px 10px",
            padding: "0px 10px"
        },
        header: {
            borderBottom: "1px solid #ccc",
            width: "100%",
        },
        heading: {
            textAlign: "center",
            marginTop: "0px",
            fontSize: "1.3rem",
        },
        button: {
            color: 'white',
            fontSize: '0.9rem',
            padding: '5px 45px',
            margin: '0px 5px',
            marginTop: '10px'
        },
        inputContainer: {
            width: "60%",
        },
        timeInput: {
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
        inputTimeChild: {
            padding: "10px 10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            fontSize: "1.1rem",
            width: "5rem",
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
        selectTimeChild: {
            padding: "0px 0px",
            // border: "1px solid #ccc",
            marginTop: "20px",
            borderRadius: "5px",
            fontSize: "1.1rem",
            width: "5rem",
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }
    }

    // function to chane time format in string from 12 hour to 24 hour
    const changeTimeFormat = (time) => {
        const timeArray = time.split(" ")
        const timeString = timeArray[0].split(":")
        const hour = timeString[0]
        const minute = timeString[1]
        const ampm = timeArray[1]
        if (ampm === "PM") {
            if (hour === "12") {
                return `${hour}:${minute}`
            }
            // eslint-disable-next-line radix
            return `${parseInt(hour) + 12}:${minute}`
        }
        if (hour === "12") {
            return `00:${minute}`
        }
        return `${hour}:${minute}`
    }


    const postReport = async () => {
        try {
            console.log(addReportData, "addReportData")
            const result = await axios.post(`${process.env.REACT_APP_BASEURL}admin/user/blood_test_report/add`, {
                // const result = await axios.post(`http://localhost:4002/admin/user/blood_test_report/add`, {
                customer_id: addReportData.customer_id.toUpperCase(),
                sample_collection_date: addReportData.sample_collection_date,
                sample_collection_time: `${sampleCollectionTime.hour ? (sampleCollectionTime.hour.toString().padStart(2, '0')) : '07'}:${sampleCollectionTime.minute ? (sampleCollectionTime.minute.toString().padStart(2, '0')) : '00'} ${sampleCollectionTime.ampm}`,
                thyrocare_id: addReportData.thyrocare_id,
                address: addReportData.address,
                type: addReportData.type
            })
            console.log(result)
            if (result.data.result === 'success') {
                setAddReportOpen(false)
                setAddReportData({
                    customer_id: "",
                    sample_collection_date: "",
                    sample_collection_time: "",
                    thyrocare_id: "",
                    address: "",
                    diff_address: false,
                    type: "",
                })
                success(result.data.message)
                getData();
            } else {
                errorToast(result.data.message)
            }
        } catch (error) {
            console.log(error)
            errorToast(error.response.data.message)
        }
    }

    const editReport = async () => {
        try {
            const result = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/user/blood_test_report/edit`, {
                // const result = await axios.patch(`http://localhost:4002/admin/user/blood_test_report/edit`, {
                email: editReportData.email_id,
                reference_order_id: editReportData.referenceOrderId,
                receive_date: editReportData.receiveDate,
            })
            console.log(result)
            if (result.data.result === 'success') {
                setEditReportOpen(false)
                setEditReportData({
                    email_id: "",
                    customer_id: "",
                    receiveDate: "",
                    referenceOrderId: "",
                })
                success(result.data.message)
                getData();
            } else {
                errorToast(result.data.message)
            }
        } catch (error) {
            console.log(error)
            errorToast(error.response.data.message)
        }
    }

    const handleEditReport = (email, customerId, sampleCollectionDate) => {
        // change sampleCollectionDate format from dd-mm-yyyy to yyyy-mm-dd
        const sampleCollectionDateArray = sampleCollectionDate.split("-")
        sampleCollectionDate = sampleCollectionDateArray[2] + "-" + sampleCollectionDateArray[1] + "-" + sampleCollectionDateArray[0]
        const newEditReportData = { ...editReportData }
        newEditReportData.email_id = email
        newEditReportData.customer_id = customerId
        newEditReportData.receiveDate = new Date(sampleCollectionDate).toDateString()
        console.log(newEditReportData)
        setEditReportData(newEditReportData)
        setEditReportOpen(true);
    }

    const EditBloodReportModal = () => (
        <Modal open={editReportOpen} onClose={handleEditReportClose}>

            <Box sx={editModalBoxStyle}>

                <IconButton onClick={() => handleEditReportClose()} style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px"
                }}>
                    <GridCloseIcon />
                </IconButton>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "8px" }}>

                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <div style={editModalStyle.header}>
                        <h3 style={editModalStyle.heading} >Update Report</h3>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            Email<span style={{ color: "red" }} >*</span>
                        </div>
                        <div style={editModalStyle.inputContainer}>
                            <TextField type="text" placeholder='Email ID' value={editReportData.email_id} disabled
                                onChange={(e) => setEditReportData({ ...editReportData, email_id: e.target.value })} fullWidth />
                        </div>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            Customer ID<span style={{ color: "red" }} >*</span>
                        </div>
                        <div style={editModalStyle.inputContainer}>
                            <TextField type="text" placeholder='Email ID' value={editReportData.customer_id} disabled
                                onChange={(e) => setEditReportData({ ...editReportData, customer_id: e.target.value })} fullWidth />
                        </div>
                    </div>
                    <div style={editModalStyle.row}><div>
                        Sample Collection Date<span style={{ color: "red" }} >*</span>
                    </div><div style={editModalStyle.inputContainer}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{
                                    borderRadius: "8px",
                                    '& .MuiInputBase-root': {
                                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                    },
                                    width: "100%"
                                }}
                                    value={editReportData.receiveDate ? dayjs(editReportData.receiveDate) : null}
                                    onChange={(event) => {
                                        setEditReportData({ ...editReportData, receiveDate: convertToDateISOString(dayjs(event).format("YYYY-MM-DD")) })
                                    }}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </div> </div>
                    <div style={editModalStyle.row}><div>
                        Thyrocare ID
                    </div>  <div style={editModalStyle.inputContainer}><TextField type="text" placeholder='Reference Order ID' value={editReportData.referenceOrderId} onChange={(e) => setEditReportData({ ...editReportData, referenceOrderId: e.target.value })} fullWidth /></div></div>

                    <Button size="small" variant="contained"
                        sx={editModalStyle.button}
                        onClick={() => editReport()}
                    >
                        Update
                    </Button>
                </div>

                {/* </div> */}
            </Box>
        </Modal>)

    function convertToDateISOString(dateString) {
        const [year, month, day] = dateString.split('-');
        const dateObj = new Date(year, month - 1, day);

        const timezoneOffset = dateObj.getTimezoneOffset();
        dateObj.setMinutes(dateObj.getMinutes() - timezoneOffset);

        const dateTimeString = dateObj.toISOString().slice(0, -1) + 'Z';
        return dateTimeString;
    }

    const NewBloodReportModal = () => (
        <Modal open={addReportOpen} onClose={handleAddReportClose}>

            <Box sx={editModalBoxStyle}>

                <IconButton onClick={() => handleAddReportClose()} style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px"
                }}>
                    <GridCloseIcon />
                </IconButton>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "8px" }}>

                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <div style={editModalStyle.header}>
                        <h3 style={editModalStyle.heading} >New Report</h3>
                    </div>
                    <div style={editModalStyle.row}><div>
                        Blood Report Type<span style={{ color: "red" }} >*</span>
                    </div> <div style={editModalStyle.inputContainer} >
                        <Select
                            id="demo-simple-select"
                            value={addReportData.type}
                            label='Select Blood Type'
                            onChange={(e) => setAddReportData({ ...addReportData, type: e.target.value })}
                            style={{ width: '100%' }}
                            sx={{
                                padding:'5px 10px'
                            }}
                        >
                            {BloodType && BloodType.map((item) => <MenuItem value={item.name}>{item.name}</MenuItem>)}
                        </Select>
                        </div>
                    </div>
                    <div style={editModalStyle.row}><div>
                        Customer ID<span style={{ color: "red" }} >*</span>
                    </div> <div style={editModalStyle.inputContainer} >
                            <TextField
                                type="text"
                                placeholder='Customer ID'
                                value={addReportData.customer_id}
                                onChange={(e) => setAddReportData({ ...addReportData, customer_id: e.target.value })}
                                fullWidth />
                        </div>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            Sample Collection Date<span style={{ color: "red" }} >*</span>
                        </div>
                        <div style={editModalStyle.inputContainer} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{
                                    borderRadius: "8px",
                                    '& .MuiInputBase-root': {
                                        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                    },
                                    width: "100%"
                                }}
                                    value={addReportData.sample_collection_date ? dayjs(addReportData.sample_collection_date) : null}
                                    onChange={(event) => {
                                        setAddReportData({ ...addReportData, sample_collection_date: convertToDateISOString(dayjs(event).format("YYYY-MM-DD")) })
                                    }}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            Sample Collection Time
                        </div>
                        <div style={editModalStyle.inputContainer} >
                            {/* select option from time interval */}
                            <div style={editModalStyle.timeInput}>
                                <div style={{ width: "25%"}}>
                                    <div style={{ fontSize: "10px" }}>Hours</div>
                                    <TextField
                                        type="number"
                                        placeholder='00'
                                        value={sampleCollectionTime.hour}
                                        onChange={(e) => {
                                            // input should be between 0 to 12
                                            if (e.target.value >= 0 && e.target.value <= 12) {
                                                setSampleCollectionTime({ ...sampleCollectionTime, hour: e.target.value })
                                            }
                                        }
                                        }

                                        fullWidth
                                    />

                                </div>
                                &nbsp;
                                &nbsp;
                                <span style={{ marginTop: "30px" }}>:</span>
                                &nbsp;
                                &nbsp;
                                <div style={{ width: "25%" }} >
                                    <div style={{ fontSize: "10px" }}>Minute</div>
                                    <TextField
                                        type="number"
                                        placeholder='00'
                                        value={sampleCollectionTime.minute}
                                        onChange={(e) => {
                                            // input should be between 0 to 59
                                            if (e.target.value >= 0 && e.target.value <= 59) {
                                                setSampleCollectionTime({ ...sampleCollectionTime, minute: e.target.value })
                                            }
                                        }
                                        }
                                        fullWidth
                                    />

                                </div>
                                &nbsp;
                                &nbsp;
                                <Select
                                    style={{ marginTop: "19px" }}
                                    sx={{
                                        width: "25%", "& .MuiSelect-select": {
                                            padding: "15px!important"
                                        }
                                    }}
                                    value={sampleCollectionTime.ampm}
                                    onChange={(e) => setSampleCollectionTime({ ...sampleCollectionTime, ampm: e.target.value })}
                                >
                                    <MenuItem value="AM">AM</MenuItem>
                                    <MenuItem value="PM">PM</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            Thyrocare ID
                        </div>
                        <div style={editModalStyle.inputContainer} >
                            <TextField
                                type="text"
                                placeholder='Thyrocare ID'
                                value={addReportData.thyrocare_id}
                                onChange={(e) => setAddReportData({ ...addReportData, thyrocare_id: e.target.value })}
                                fullWidth />
                        </div>
                    </div>
                    <div style={editModalStyle.row}>
                        <div>
                            <Checkbox
                                checked={addReportData.diff_address}
                                onChange={(e) => setAddReportData({ ...addReportData, diff_address: e.target.checked })}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            Add Address
                        </div>
                    </div>
                    {
                        addReportData.diff_address && (
                            <div style={editModalStyle.row}>
                                <div>
                                    Address
                                </div>
                                <div style={editModalStyle.inputContainer} >
                                    <TextField
                                        type="text"
                                        placeholder='Address'
                                        value={addReportData.address}
                                        onChange={(e) => setAddReportData({ ...addReportData, address: e.target.value })}
                                        multiline  // Add multiline prop to enable multiple lines
                                        rows={3}   // Specify the number of visible lines
                                        fullWidth
                                        sx={{ boxShadow: "0px 3px 8px 0px rgba(0,0,0,0.3)", borderRadius: "10px" }}
                                    />

                                </div>
                            </div>
                        )
                    }
                    <Button size="small" variant="contained"
                        sx={editModalStyle.button}
                        onClick={() => postReport()}
                    >
                        Add
                    </Button>
                </div>

                {/* </div> */}
            </Box>
        </Modal>)

    return (
        <div>
            <Header direction={windowWidth > 700 ? 'row' : 'column'}>
                <TableHeading>  Blood Report List </TableHeading>

                &nbsp;&nbsp;
                <div
                    className="header-buttons"
                    style={{
                        display: 'flex',
                        flexDirection: windowWidth > 700 ? 'row' : 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={buttonStyle}
                        disabled={!blood?.can_create}
                        onClick={() => setAddReportOpen(true)}
                    >
                        New Report
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<img src={excell} alt=" " />}
                        sx={buttonStyle}
                        disabled={!blood?.can_create}
                        onClick={() => exportExcel()}
                    >
                        Export
                    </Button>
                    &nbsp;
                </div>
            </Header>
            &nbsp;
            <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        margin: "0 10px"
                                    }}
                                >
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="select-search-key-label">Search by</InputLabel>
                                        <Select
                                            labelId="select-search-key-label"
                                            id="select-search-key"
                                            value={searchKey}
                                            label="Search by"
                                            onChange={(event) => { setSearchKey(event.target.value) }}
                                        >
                                            <MenuItem value='customer_id'>Customer Id</MenuItem>
                                            <MenuItem value='name'>Name</MenuItem>
                                            <MenuItem value='email'>Email</MenuItem>
                                            <MenuItem value='phone'>Phone</MenuItem>

                                        </Select>
                                    </FormControl>
                                    &nbsp;&nbsp;
                                    <TextField
                                        autoComplete="off"
                                        key="search"
                                        // autoFocus="autoFocus"
                                        id="standard-basic"
                                        placeholder='Search'
                                        variant="standard"
                                        // style={{ marginBottom: '1rem' }}
                                        InputProps={{
                                            startAdornment: (
                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                                    <InputAdornment position="end">
                                                        <SearchRoundedIcon />
                                                    </InputAdornment>
                                                </div>
                                                ),
                                        }}
                                        value={searchValue}
                                        onChange={(e) => {
                                            if (searchKey === '') alert('Please select search key first');
                                            else setSearchValue(e.target.value); 
                                        }}
                                        autoFocus
                                    />
                                </div>
                    <MaterialTable
                    isLoading={isLoading}
                        title={null}
                        style={{ width: "100%", padding: "8px", boxShadow: "none" }}
                        icons={tableIcons}
                        columns={columnNames}
                        data={arr}
                        options={{
                            // pageSize: 5,
                            toolbar:false,
                            search:false,
                            paging: false,
                            maxBodyHeight: '700px',
                            headerStyle: {
                                backgroundColor: '#01579b',
                                color: '#FFF',
                                fontWeight: 100
                            },

                            // eslint-disable-next-line consistent-return
                            rowStyle: (x) => {
                                if (x.tableData.id % 2) {
                                    return { backgroundColor: '#eef9ff', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' };
                                }
                            }
                        }}
                    />
            <div
                width="100%"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '1rem'
                }}
            >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={limit}
                        label="Results per Page"
                        onChange={(event) => { setLimit(event.target.value) }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    count={Math.ceil(total / limit)}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    size="small"
                />
            </div>
            <Modal open={open} onClose={handleClose}>

                <Box sx={modalBoxStyle}>

                    <IconButton onClick={() => setOpen(false)} style={{
                        position: "absolute",
                        right: "10px",
                        top: "8px"
                    }}>
                        <GridCloseIcon />
                    </IconButton>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "8px" }}>

                        {/* eslint-disable-next-line react/jsx-no-bind */}
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "8px" }}>Start Date  <TextField type="date" placeholder='DD/MM/YYY' onChange={(e) => setStartDate(swap(e.target.value))} /></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "8px", marginLeft: "8px" }}>End Date  <TextField type="date" onChange={(e) => setEndDate(swap(e.target.value))} /></div>

                        <Button size="small" variant="contained"
                            sx={buttonStyle}
                            onClick={() => exportExcel(true)}
                        >
                            Download
                        </Button>
                        <div style={{ color: "red" }}>{error}</div>
                    </div>

                    {/* </div> */}
                </Box>
            </Modal>

            <Modal open={historyModal} onClose={handleHistoryModalClose}>



                <Box sx={modalHistoryTable}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <ModalTableHeading>  Booking History </ModalTableHeading>
                        <IconButton onClick={() => setHistoryModal(false)} style={{
                        }}>
                            <GridCloseIcon />
                        </IconButton>
                    </div>
                    <Table sx={{ maxWidth: 600, border: "1px solid #C4C4C4", borderCollapse: "separate", borderRadius: "1rem" }} aria-label="simple table">
                        <TableHead>
                            <TableRow>

                                <>
                                    <TableCell align='center'>Reschedule Date</TableCell>
                                    <TableCell align='center'>Start Time</TableCell>
                                    <TableCell align='center'>End Time</TableCell>
                                    <TableCell align='center'>Receive Date</TableCell>
                                    <TableCell align='center'>Address</TableCell>

                                </>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {

                                historyArr && historyArr.map((history) => <><TableRow
                                    key="1"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{history.scheduleDate}</TableCell>
                                    <TableCell align="center">{history.startTime}</TableCell>
                                    <TableCell align="center">{history.endTime}</TableCell>
                                    <TableCell align="center">{history.receiveDate}</TableCell>
                                    <TableCell align="center">{history.address}</TableCell>
                                </TableRow>
                                </>
                                )

                            }
                        </TableBody>
                    </Table>
                </Box>
            </Modal>
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={CloseSucess}>
                <Alert onClose={CloseSucess} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            {EditBloodReportModal()}
            {NewBloodReportModal()}
        </div>

    );
};

export default BloodReport;
