/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import MainCard from 'ui-component/cards/MainCard';
import { Authenticated } from '../../../utils/checkerfunction';
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import styled from 'styled-components';
import './style.css'

const UserDataConatiner = styled.div`
    ${'' /* display: flex;
    flex-direction: col; */}
    ${'' /* justify-content: left; */}
    /* width: 50%; */
    ${'' /* align-items: left; */}
    margin: 2rem 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
    h3{
        font-weight: 500;
    }
    ${'' /* h3 {
        margin: 10px;
        color: #000;
        padding: 13px;
    } */}
`;

const NafContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin: 2rem 0;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 10px;
`;

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const ViewNaf = () => {
    // Authenticated();

    const search = useLocation().search;
    const userid = new URLSearchParams(search).get('userid');

    const [Data, setData] = React.useState(null);
    const [user, setUser] = React.useState({});

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/user_naf?id=${userid}`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    React.useEffect(() => {
        axios
            .get(baseURL)
            .then((res) => {
                setData(res?.data?.data?.naf_data);
                setUser(res.data.userdata);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [baseURL]);

    // dna bloot naf if yes == that data will come
    // dna bllot naf if there report are filled and thr return is yes


    return (
        <MainCard>
            {/* <Button onClick={() => window.history.back()} style={{ color: '#2d2d2d' }}>
                ← Back
            </Button>
            <span /> */}

            <h2>NAF Response</h2>
            <span />
            <UserDataConatiner>
                <div className='userDataSection' >
                    <h3 className='userData2' >Name: {user.name}</h3>
                    <h3 className='userData'>DOB: {user.dob}</h3>
                    <h3 className='userData'>Height: {user.height} cm</h3>
                    <h3 className='userData'>Weight: {user.weight} kg</h3>
                    <h3 className='userData'>Age: {user.date_of_birth} years</h3>
                </div>
                <div className='userDataSection'>
                    <h3 className='userData1'>Email: {user.email}</h3>
                    <h3 className='userData'>Phone: {user.phone ? user.phone : "NA"}</h3>
                    <h3 className='userData'>Gender: {capitalize(user.gender)}</h3>
                </div>
            </UserDataConatiner>

            <NafContainer>
                {Data && (
                    <div>
                        {Data?.map((data) => {
                            if (data.question_type === 'input')
                                return (
                                    <p style={{ margin: '2rem 0' }}>
                                        <h4>Question : {data.statement}</h4>
                                        <p>
                                            {' '}
                                            <h5>Ans : {data.input.value} </h5>
                                        </p>
                                    </p>
                                );
                            if (data.question_type !== 'input')
                                return (
                                    <p style={{ margin: '2rem 0' }}>
                                        <h4>Question : {data.statement}</h4>
                                        <p>
                                            {' '}
                                            {data?.options?.map((val) => {
                                                if (val.isSelected)
                                                    return (
                                                        <p>
                                                            <h5> Ans : {val.text}</h5>
                                                        </p>
                                                    );
                                            })}
                                        </p>
                                    </p>
                                );
                        })}
                    </div>
                )}
            </NafContainer>
        </MainCard>
    );
};

export default ViewNaf;
