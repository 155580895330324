/* eslint-disable no-else-return */
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link as Link1 } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import {
    Grid,
    Container,
    Box,
    IconButton,
    Button,
    TextField,
    Link,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Modal,
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    InputAdornment,
    Pagination,
    Autocomplete,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MaterialTable from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import WarningIcon from '@mui/icons-material/Warning';
import { Header, UserDetails, Text, ModalBox, AddMealBox, NutritionFoodWrap, BreakfastWrap, EnergyDistributionWrap, AddMealBoxV1 } from './style';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Loader } from 'rsuite';
import { SignalCellularNullTwoTone } from '@material-ui/icons';
import $, { get } from 'jquery';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import StopSharpIcon from '@mui/icons-material/StopSharp';
import { shadows } from '@mui/system';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { makeStyles } from '@material-ui/core/styles';


import './style.css';
import { TramOutlined } from '@mui/icons-material';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { UserData } from 'utils/userData';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                paddingRight: "25px"
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
}));

const tableData = [
    {
        name: 'John Smith',
        status: 'Employed'
    },
    {
        name: 'Randal White',
        status: 'Unemployed'
    },
    {
        name: 'Stephanie Sanders',
        status: 'Employed'
    },
    {
        name: 'Steve Brown',
        status: 'Employed'
    },
    {
        name: 'Joyce Whitten',
        status: 'Employed'
    },
    {
        name: 'Samuel Roberts',
        status: 'Employed'
    },
    {
        name: 'Adam Moore',
        status: 'Employed'
    }
];

const Verify = (navigation) => {
    const [unsaveMeal, setUnsaveMeal] = useState(0);

    const [confirmDeleteMeal, setConfirmDeleteMeal] = useState(0);
    const finalPermission = UserData().permissions
    const verification = finalPermission?.find(permission => permission.name === "Verification Panel");

    const [plan, setPlan] = useState([]);
    const [open, setOpen] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState(null);
    const [recipeToEdit, setRecipeToEdit] = useState(null);
    const [newServing, setNewServing] = useState(1);
    const [dialogAction, setDialogAction] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [baseTypes, setBaseTypes] = useState([]);
    const [selectedBaseType, setSelectedBaseType] = useState(null);
    const [allModalRecipes, setAllModalRecipes] = useState([]);
    const [addingRecipe, setAddingRecipe] = useState(false);
    const [totalRecipes, setTotalRecipes] = useState(0);
    const [totalIngredients, setTotalIngredients] = useState(0);
    const [customBaseName, setCustomBaseName] = useState('');
    const [customBaseTiming, setCustomBaseTiming] = useState('');
    const [openCustomBaseModal, setOpenCustomBaseModal] = useState(false);
    const [additionType, setAdditionType] = useState('recipe');
    const [allModalIngredients, setAllModalIngredients] = useState([]);
    const [addRecipeMessage, setAddRecipeMessage] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [editMedicalCondition, setEditMedicalCondition] = useState(false);
    const [trigger, setTrigger] = useState(1);
    const [prevMedicalCondition, setPrevMedicalCondition] = useState('');
    const [prevAllergies, setPrevAllergies] = useState('');
    const [prevAboutWidth, setPrevAboutWidth] = useState('');



    const { id } = useParams();
    const navigate = useNavigate();
    const newServingRef = useRef();
    newServingRef.current = newServing;
    const userId = id;

    const [servingsValue, setServingsValue] = useState([]);
    const [measurementValue, setMeasurementValue] = useState([]);

    // for modal
    const [openReport, setOpenReport] = useState(false);

    const handleReportClose = () => {
        setOpenReport(false);
    };

    // for Approved
    const [openApprovedPlanModal, setOpenApprovedPlanModal] = useState(false);

    const handleApprovedPlanClose = () => {
        setOpenApprovedPlanModal(false);
    };

    // for Delete Meal
    const [openDeletePlanModal, setOpenDeletePlanModal] = useState(false);
    const [delRecipeId, setDelRecipeId] = useState('');
    const [delMealTypeId, setDelMealTypeId] = useState('');

    const handleDeletePlanClose = () => {
        setOpenDeletePlanModal(false);
    };

    const [mealTypeSelect, setMealTypeSelect] = useState('');

    const DeletePlan = (meal, recipe, mealType) => {
        setDelRecipeId(recipe);
        setDelMealTypeId(meal);
        setMealTypeSelect(mealType);
        handleDeletePlanFinal(meal, recipe, mealType);
        // setOpenDeletePlanModal(true);
    };

    const [deleteRecipeMessage, setDeleteRecipeMessage] = useState('');
    const [deleteRecipeFailureMessage, setDeleteRecipeFailureMessage] = useState('');
    const [deleteMealTypeArr, setDeleteMealTypeArr] = useState({});
    const DeleteMealPlan = (deleteMealTypeVal) => {
        setDeleteMealTypeArr(deleteMealTypeVal);
        // setOpenMealDeleteModal(true);
        DeleteMealTypePlan(deleteMealTypeVal);
    };

    console.log(deleteMealTypeArr, 'deltearrr');

    const DeleteMealTypePlan = async (deleteMealTypeVal) => {
        console.log(deleteMealTypeArr, 'deleteMealTypeVal66666666666666666');
        console.log(recipesList, 'recipesList 88888888');
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/remove_bulk_meal?meal_type_id=${deleteMealTypeVal.meal_type_id}&plan_id=${deleteMealTypeVal.plan_id}`)
            if (res.data.result === "success") {
                axios
                    .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/user_plan`, {
                        user_id: id,
                        plan_id: planId
                    })
                    .then((res) => {
                        setUserPlan(res.data.data[0]);
                        setBmi(res.data.data[0].bmi);
                        setPlanDetails(res.data.data[0].plan_details);
                        setRecipesList(res.data.data[0].plan_details.recipes);
                        setBreakfastList(res.data.data[0].plan_details.recipes[0]);
                        setBreakfastNutritionProfile(res.data.data[0].plan_details.recipes[0].nutrition_profile);
                        setBreakfastSuggestedRecipes(res.data.data[0].plan_details.recipes[0].suggested_recipes);
                        setPrevAllergies(arrayToString(res.data.data[0].allergies.answer_data));
                        setPrevMedicalCondition(res.data.data[0].medical_condition.answer_data);
                    });
                getEnergyDistrubution(id, planId);
                setTimeout(() => {
                    setOpenMealDeleteModal(false);
                    setDeleteRecipeMessage(res.data.message);
                    // window.location.reload();
                }, 200);
            }
        } catch (error) {
            console.log(error.response.data.message, 'error message del');
            setDeleteRecipeFailureMessage(error.response.data.message);
        }

    };

    const handleDeletePlanFinal = (mealId, recipeId, mealType) => {
        const res = axios
            .delete(
                `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/remove_meal_recipe?meal_type_id=${mealId}&recipe_id=${recipeId}&plan_id=${planId}`
            )
            .then((response) => {
                console.log(response.data.message, 'success message del');
                setDeleteRecipeMessage(response.data.message);
                if (response.data.result === 'success') {
                    axios
                        .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/user_plan`, {
                            user_id: id,
                            plan_id: planId
                        })
                        .then(res => {
                            setUserPlan(res.data.data[0]);
                            setBmi(res.data.data[0].bmi);
                            setPlanDetails(res.data.data[0].plan_details);
                            setRecipesList(res.data.data[0].plan_details.recipes);
                            setBreakfastList(res.data.data[0].plan_details.recipes[0]);
                            setBreakfastNutritionProfile(res.data.data[0].plan_details.recipes[0].nutrition_profile);
                            setBreakfastSuggestedRecipes(res.data.data[0].plan_details.recipes[0].suggested_recipes);
                            setPrevAllergies(arrayToString(res.data.data[0].allergies.answer_data));
                            setPrevMedicalCondition(res.data.data[0].medical_condition.answer_data);
                        })
                    setTimeout(() => {
                        setOpenDeletePlanModal(false);
                        setDeleteRecipeMessage();
                    }, 2000);
                }
            })
            .catch(error => {
                // console.log('Errorrrrrrr: ', (error.response.data.message));
                // setFailureMessage(error.res.data.message);
                console.log(error.response.data.message, "error message del");
                setDeleteRecipeFailureMessage(error.response.data.message);
            })
        if (userId !== " " && planId !== " ") {
            axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/energy_distrubution?user_id=${userId}&plan_id=${planId}`, {
                user_id: userId,
                plan_id: planId,
            })
                .then(res => {
                    console.log(res.data.data, "Energy Distrubution");
                    setEnergyDistruction(res.data.data[0].energy_distruction);
                    setNutritionProfile(res.data.data[0].nutrition_profile);
                })
                .then(() => setLoading(false))
        }
    }


    // console.log(mealTypeSelect,"mealTypeSelect 999999999999999");

    console.log(id, 'id from varify screen');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setRecipeToDelete(null);
        setRecipeToEdit(null);
        setDialogAction(null);
    };

    const [mealTypeId, setMealTypeId] = useState();
    const [selectMealType, setSelectMealType] = useState();
    const handleModalOpen = (val) => {
        // console.log(val, "val99999999");
        setMealTypeId(val.meal_type_id);
        setAddingRecipe(true);
        setModalOpen(true);
        // setTimeout(() => {
        //     setSelectedBaseType(String(val.meal_type));
        //     console.log(val.meal_type.toString(), "sdsdsdsd 555555555599999999");
        // }, "3000")
        // setSelectedBaseType(val.meal_type.trim());
    }
    console.log(selectedBaseType, "selectMealType 8888888");
    // console.log(mealTypeId,"mealTypeId55555555");

    const handleModalClose = () => {
        setAddingRecipe(false);
        setModalOpen(false);
        setSelectedBaseType(null);
    };

    useEffect(async () => {
        if (modalOpen) {
            setLoading(true);
            let res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/base_types`)
            setBaseTypes(res.data.data);

            if (additionType === "recipe") {
                res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipeV2?size=5&page=${page}&search=${search}`)
                setTotalRecipes(res.data.data[0].total_records);
                setAllModalRecipes(res.data.data[0].rows);
            }
            else {
                res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredientV1?size=5&page=${page}&search=${search}`)
                setTotalIngredients(res.data.total_count)
                setAllModalIngredients(res.data.data);
                console.log(totalIngredients, "totalIngredients")
            }
            setLoading(false)
        }
    }, [modalOpen, search, page, additionType]);

    useEffect(() => {
        setPage(1);
        setSearch("");
    }, [additionType]);

    useEffect(() => {
        setPage(1);
        setSearch("");
        setAdditionType("recipe");
    }, [modalOpen]);

    useEffect(() => {
        setPage(1);
    }, [search])

    // useEffect(() => {
    //     if (totalRecipes) {
    //         axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/recipev1?size=${totalRecipes}&page=1&search=`).then((res) => {

    //             setAllModalRecipes(res.data.data[0].rows);
    //         });
    //     }
    // }, [totalRecipes]);

    // useEffect(() => {
    //     if (totalIngredients) {
    //         axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/ingredient?size=20&page=1&search=`).then((res) => {               console.log(res.data.data, "res.data.data[0].rows")
    //                 setAllModalIngredients(res.data.data);
    //         });
    //     }
    // }, [totalIngredients]);


    const IngredientModalColumns = [
        {
            title: 'Ingredient Name',
            field: 'name',
            cellStyle: {
                padding: '5px 10px',
                paddingLeft: 20,
                color: '#616161',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderRadius: '10px 0 0 0',
                fontWeight: '500',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '2px solid #ADADAD'
            }
        },
        {
            title: 'Action',
            field: 'action',
            sorting: false,
            cellStyle: {
                textDecoration: 'underline',
                color: '#1B99DF',
                padding: '5px 10px',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191',
                borderLeft: '1px solid #616161'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderRadius: '0 10px 0 0',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '1px solid #616161',
                borderRight: '2px solid #ADADAD',
                fontWeight: '500'
            }
        }
    ]

    const modalColumns = [
        {
            title: 'Recipe Name',
            field: 'recipe_name',
            cellStyle: {
                padding: '5px 10px',
                paddingLeft: 20,
                color: '#616161',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderRadius: '10px 0 0 0',
                fontWeight: '500',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '2px solid #ADADAD'
            }
        },
        {
            title: 'Recipe Type',
            field: 'recipe_type',
            cellStyle: {
                padding: '5px 10px',
                color: '#616161',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191',
                borderLeft: '1px solid #616161'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '1px solid #616161',
                fontWeight: '500'
            }
        },
        {
            title: 'Servings',
            field: 'servings',
            cellStyle: {
                padding: '5px 10px',
                color: '#616161',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191',
                borderLeft: '1px solid #616161'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '1px solid #616161',
                fontWeight: '500'
            }
        },
        {
            title: 'Action',
            field: 'action',
            sorting: false,
            cellStyle: {
                textDecoration: 'underline',
                color: '#1B99DF',
                padding: '5px 10px',
                fontWeight: '500',
                fontSize: 14,
                borderBottom: '2px solid #919191',
                borderLeft: '1px solid #616161'
            },
            headerStyle: {
                fontSize: '15px',
                color: '#616161',
                backgroundColor: '#EDEDED',
                padding: '5px 10px',
                borderRadius: '0 10px 0 0',
                borderTop: '2px solid #ADADAD',
                borderBottom: '2px solid #ADADAD',
                borderLeft: '1px solid #616161',
                borderRight: '2px solid #ADADAD',
                fontWeight: '500'
            }
        }
    ];

    const [openAddServingsModal, setOpenAddServingsModal] = useState(false);
    const openAddServingsClose = () => {
        setOpenAddServingsModal(false);
        setLoading(false);
        window.location.reload();
    };
    const addServingsDialog = () => (
        <Dialog
            open={openAddServingsModal}
            onClose={openAddServingsClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Add Servings
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 30,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            How many servings would you suggest for this <br /> new recipe?
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >
                            <Text
                                style={{
                                    width: '50%',
                                    color: '#313131',
                                    fontSize: 18,
                                    paddingTop: 15
                                }}
                            >
                                Servings
                            </Text>
                            <TextField
                                variant="outlined"
                                placeholder="Value"
                                sx={{ m: 0, width: '30ch' }}
                                value={servingsValue.serving}
                                onChange={(e) =>
                                    setServingsValue({
                                        ...servingsValue,
                                        serving: e.target.value
                                    })
                                }
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 15,
                                paddingTop: 0,
                                display: 'flex',
                                color: '#313131'
                            }}
                        >
                            <Text
                                style={{
                                    width: '50%',
                                    color: '#313131',
                                    fontSize: 18,
                                    paddingTop: 15
                                }}
                            >
                                Measurement
                            </Text>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder="Measurement"
                                sx={{ m: 0, width: '33ch' }}
                                value={measurementValue.measurement}
                                onChange={(e) =>
                                    setMeasurementValue({
                                        ...measurementValue,
                                        measurement: e.target.value
                                    })
                                }
                            >
                                {measurements?.map((mes, index) => (
                                    <MenuItem value={mes.name}>{mes.name}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </span>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#009727',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {addRecipeMessage}
                    </p>
                    <p
                        style={{
                            fontSize: 14,
                            color: '#D32C2C',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {failureMessage}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <Button
                        onClick={openAddServingsClose}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={addServingsMeal}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    const [recipeId, setRecipeId] = useState();
    const showAddServingsModal = (val) => {
        setServingsValue({
            serving: val.recipe_serving
        });
        setMeasurementValue({
            measurement: 'Bowl'
        });
        setRecipeId(val.recipe_id);
        setMealTypeId(val.meal_type_id);
        addServingsMeal(val);
    };

    console.log(servingsValue, 'servingsValue 5454545');

    const [failureMessage, setFailureMessage] = useState('');


    const addServingsMeal = (val) => {
        // alert("fdfdfdf");
        // console.log(planId, "planId from save");
        // console.log(mealTypeId, "meal_type_id from save");
        // console.log(recipeId, "recipe_id from save");
        // console.log(servingsValue.serving, "servingsValue.serving from save");
        // console.log(measurementValue.measurement, "measurementValue.measurement from save");
        setAddRecipeMessage("Adding recipe to meal...");
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/add_recipe_to_meal`, {
                type: additionType,
                plan_id: planId,
                meal_type_id: val.meal_type_id,
                recipe_id: val.recipe_id,
                serving: 1,
                measurement: "Bowl"
            })
            .then((response) => {
                console.log(response, 'res sdsdsds');
                console.log(response.data.message, 'res message');
                setAddRecipeMessage(response.data.message);
                setOpenAddServingsModal(false);
                setModalOpen(false);
                setAddRecipeMessage('');
                setFailureMessage('');
                if (response.data.result === 'success') {
                    axios
                        .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/user_plan`, {
                            user_id: id,
                            plan_id: planId
                        })
                        .then((res) => {
                            setUserPlan(res.data.data[0]);
                            setBmi(res.data.data[0].bmi);
                            setPlanDetails(res.data.data[0].plan_details);
                            setRecipesList(res.data.data[0].plan_details.recipes);
                            setBreakfastList(res.data.data[0].plan_details.recipes[0]);
                            setBreakfastNutritionProfile(res.data.data[0].plan_details.recipes[0].nutrition_profile);
                            setBreakfastSuggestedRecipes(res.data.data[0].plan_details.recipes[0].suggested_recipes);
                            getEnergyDistrubution(id, planId);
                            setPrevAllergies(arrayToString(res.data.data[0].allergies.answer_data));
                            setPrevMedicalCondition(res.data.data[0].medical_condition.answer_data);
                        })
                        .then(() => {
                            setLoading(false)

                        });

                }
            })
            .catch((error) => {
                // console.log('Errorrrrrrr: ', (error.response.data.message));
                setAddRecipeMessage('');
                setFailureMessage(error.response.data.message);
                alert(error.response.data.message);
                setLoading(false);
            });
    };


    // axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/energy_distrubution?user_id=${userId}&plan_id=${planId}`, {
    //     user_id: userId,
    //     plan_id: planId,
    // })
    //     .then(res => {
    //         console.log(res.data.data, "Energy Distrubution");
    //         setEnergyDistruction(res.data.data[0].energy_distruction);
    //         setNutritionProfile(res.data.data[0].nutrition_profile);
    //     })
    //     .then(() => setLoading(false))


    const modalArr = []
    allModalRecipes?.map((item, index) =>
        modalArr.push({
            recipe_name: item.name,
            recipe_type: item.type,
            servings: item.serving,
            action: (
                <p
                    style={{
                        color: '#1B99DF',
                        cursor: 'pointer'
                    }}
                    // onClick={() => {
                    //     if (selectedBaseType !== null) setRecipeToEdit(item);
                    //     else alert('Please select a base type first');
                    // }}
                    // onClick={showAddServingsModal}
                    onClick={() => {
                        console.log(selectedBaseType, 'basetypre');
                        if (selectedBaseType !== null) {
                            // setRecipeToEdit(item);
                            showAddServingsModal({
                                recipe_id: item.id,
                                recipe_serving: item.serving,
                                meal_type_id: selectedBaseType
                            });
                        } else alert('Please select a base type first');
                    }}
                >
                    Add
                </p>
            )
        })
    );

    const ingredientModalRows = [];
    allModalIngredients?.map((item, index) =>
        ingredientModalRows.push({
            name: item.name,
            action: (
                <p
                    style={{
                        color: '#1B99DF',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        console.log(selectedBaseType, 'basetypre');
                        if (selectedBaseType !== null) {
                            // setRecipeToEdit(item);
                            showAddServingsModal({
                                recipe_id: item.id,
                                recipe_serving: 1,
                                meal_type_id: selectedBaseType
                            });
                        } else alert('Please select a base type first');
                    }}
                >
                    Add
                </p>
            )
        })
    )

    console.log(allModalRecipes, 'allModalRecipes 99999');
    console.log(ingredientModalRows, 'ingredientModalRows 99999');

    const handleCustomBaseModalClose = () => {
        setOpenCustomBaseModal(false);
    };

    const handleCustomBaseModalSave = async () => {
        console.log(customBaseName, 'customBaseName is clicked');
        if (customBaseName === '') {
            alert('Please enter a base name');
            return;
        }
        if (customBaseTiming === '') {
            alert('Please enter default timing');
            return;
        }
        try {
            const result = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/base_types`, {
                "name": customBaseName,
                "description": "description",
                "default_meal_time": customBaseTiming
            });
            console.log(result, 'result');
            setMealTypeId(result.data.data[0].id);
            setBaseTypes([...baseTypes, result.data.data[0]]);
            setSelectedBaseType(result.data.data[0].id);
            // alert('Base added successfully');
            // window.location.reload();
        }
        catch (err) {
            console.log(err, 'err');
            alert(err.response.data.message);
        }
        setOpenCustomBaseModal(false);
    }

    const renderCustomBaseDialog = () => {
        console.log('customBaseNameno');
        return (
            <Dialog
                open={openCustomBaseModal}
                onClose={handleCustomBaseModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span
                            style={{
                                fontSize: 15,
                                fontWeight: 500,
                                color: '#313131',
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 20,
                                    paddingTop: 0,
                                    borderBottom: '1px solid #919191',
                                    fontSize: 20,
                                    marginBottom: 20,
                                    color: '#313131'
                                }}
                            >
                                Custom Base Type
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 30,
                                    paddingTop: 0,
                                    fontSize: 18,
                                    lineHeight: '30px',
                                    marginBottom: 0,
                                    color: '#616161'
                                }}
                            >
                                Add details about the custom base type.
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                        paddingTop: 15
                                    }}
                                >
                                    Meal Type
                                </Text>
                                <TextField
                                    variant="outlined"
                                    placeholder="Post workout"
                                    sx={{ m: 0, width: '30ch' }}
                                    value={customBaseName}
                                    onChange={(e) => {
                                        setCustomBaseName(e.target.value);
                                    }
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 15,
                                    paddingTop: 0,
                                    display: 'flex',
                                    color: '#313131'
                                }}
                            >
                                <Text
                                    style={{
                                        width: '50%',
                                        color: '#313131',
                                        fontSize: 18,
                                        paddingTop: 15
                                    }}
                                >
                                    <span>Default Time</span>
                                </Text>
                                <TextField
                                    variant="outlined"
                                    placeholder="09:00 AM"
                                    sx={{ m: 0, width: '30ch' }}
                                    value={customBaseTiming}
                                    onChange={(e) => {
                                        setCustomBaseTiming(e.target.value);
                                    }
                                    }
                                />
                            </div>
                        </span>
                        <p
                            style={{
                                fontSize: 14,
                                color: '#D32C2C',
                                textAlign: 'center',
                                padding: 0,
                                margin: 0
                            }}
                        >
                            {failureMessage}
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            borderTop: '1px solid #919191',
                            paddingTop: 20,
                            paddingBottom: 10
                        }}
                    >
                        <Button
                            onClick={handleCustomBaseModalClose}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#FFFFFF',
                                borderRadius: 10,
                                color: '#1B99DF',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                console.log('confirm clicked');
                                handleCustomBaseModalSave();
                            }}
                            style={{
                                border: '1px solid #1B99DF',
                                background: '#1B99DF',
                                borderRadius: 10,
                                color: '#ffffff',
                                fontSize: 15,
                                fontWeight: 500,
                                padding: '6px 75px',
                                margin: '0 5px'
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        )
    };

    const classes = useStyles();

    const toolbar = () => {
        console.log('toolbar');
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px 0px" }} >
                <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '20px' }}>What do you want to add?</p>
                    <FormControl>
                        {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={additionType}
                            onChange={(e) => {
                                setAdditionType(e.target.value);
                            }}
                        >
                            <FormControlLabel value="recipe" control={<Radio />} label="Recipe" />
                            <FormControlLabel value="ingredient" control={<Radio />} label="Ingredient" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className='search-bar' >
                    <TextField
                        autoComplete="off"
                        key="search"
                        autoFocus="autoFocus"
                        className={classes.root}
                        id="standard-basic"
                        placeholder='Search for Recipe'
                        variant="outlined"
                        inputProps={{ style: { borderRadius: "50px" } }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                    <div className='search-icon-container' >
                        <SearchRoundedIcon />
                        <span>Search</span>
                    </div>
                </div>
            </div>
        )
    }

    const getBaseName = (baseId) => {
        const base = baseTypes.find((base) => base.id === baseId);
        return base.name;
    }

    const renderModal = () => {
        console.log('renderModal');
        return (
            <Modal
                className="select_recepic_modal"
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBox>
                    {/* <h2 id="modal-modal-title">Select Recipe</h2> */}
                    {/* <Divider /> */}
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }} >
                        <FormControl>
                            <div className='base-type-container' >
                                <p style={{ width: "200px" }} >Select Base Type</p>
                                <Select
                                    value={selectedBaseType || ''}
                                    onChange={(e) => {
                                        if (e.target.value === 'Custom Base') {
                                            setSelectedBaseType(e.target.value);
                                            setOpenCustomBaseModal(true);
                                        } else {
                                            setSelectedBaseType(e.target.value);
                                        }
                                    }}
                                    style={{ width: "300px" }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select Base Type' }}
                                >
                                    <MenuItem value="" disabled>
                                        Select Base Type
                                    </MenuItem>
                                    {baseTypes.map((baseType) => (
                                        <MenuItem key={baseType.id} value={baseType.id}>{baseType.name}</MenuItem>
                                    ))}
                                    <MenuItem value="Custom Base">Custom Base Type</MenuItem>
                                </Select>
                            </div>
                        </FormControl>
                        <CloseIcon
                            onClick={handleModalClose} style={{ cursor: 'pointer', color: '#1B99DF' }}
                        />
                    </div>
                    <Divider />
                    {toolbar()}
                    <Divider />
                    &nbsp;&nbsp;
                            <MaterialTable
                                isLoading={loading}
                                title={additionType === 'recipe' ? 'Recipes' : 'Ingredients'}
                                icons={tableIcons}
                                columns={additionType === 'recipe' ? modalColumns : IngredientModalColumns}
                                data={additionType === 'recipe' ? modalArr : ingredientModalRows}
                                options={{
                                    // toolbar: false,
                                    search: false,
                                    // pageSize: 6,
                                    paging: false,
                                    // maxBodyHeight: '800px',
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return {
                                                backgroundColor: '#FFFFFF'
                                            };
                                        }
                                        return {
                                            backgroundColor: '#FFFFFF'
                                        };
                                    }
                                }}
                            />
                                <div
                                    width="100%"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '1rem'
                                    }}
                                >
                                    {
                                        additionType === 'recipe' ? (
                                            <Pagination
                                                count={Math.ceil(totalRecipes / 5)}
                                                page={page}
                                                onChange={(e, page) => { setPage(page) }}
                                                variant="outlined"
                                                shape="rounded"
                                                size="small"
                                            />
                                        ) : (
                                            <Pagination
                                                count={Math.ceil(totalIngredients / 5)}
                                                page={page}
                                                onChange={(e, page) => { setPage(page) }}
                                                variant="outlined"
                                                shape="rounded"
                                                size="small"
                                            />
                                        )
                                    }
                                </div>
                </ModalBox>
            </Modal>
        );
    };

    const deleteRecipe = async () => {
        const res = await axios.delete(
            `${process.env.REACT_APP_BASEURL}admin/nutrition_plans/remove_recipe?base_type_id=${recipeToDelete.recipe_type_id}&recipe_id=${recipeToDelete.recipe_id}&plan_id=${plan.plan_id}`
        );
        getEnergyDistrubution(userId, plan.plan_id);
        console.log(res);
        handleClose();
        window.location.reload();
    };

    const approvePlan = async () => {
        setOpenApprovedPlanModal(true);

        // const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/approve_plan`, { plan_id: planId })
        // alert(res.data.message);
    };

    const approvePlanFinal = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/approve_plan`, { plan_id: planId });
            setOpenApprovedPlanModal(false);
            console.log(res, 'approved');
            // alert(res.data.message);
            setIsApproved(true);
        } catch (err) {
            console.log(err);
            setOpenApprovedPlanModal(false);
            alert(err.response.data.message)
        }
    }

    const addRecipe = async () => {
        setLoading(true);
        const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/add_recipe_to_plan`, {
            recipe_id: recipeToEdit.id,
            serving: newServingRef.current,
            base_type_id: selectedBaseType,
            plan_id: plan.plan_id
        });
        getEnergyDistrubution(userId, plan.plan_id);
        console.log(res);
        setLoading(false);
        handleClose();
        setRecipeToEdit(null);
        setNewServing(null);
        window.location.reload();
    };

    const updateServings = async () => {
        setLoading(true);
        const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/update_serving`, {
            recipe_id: recipeToEdit.recipe_id,
            serving: newServingRef.current,
            base_type_id: recipeToEdit.recipe_type_id,
            plan_id: plan.plan_id
        });
        getEnergyDistrubution(userId, plan.plan_id);
        console.log(res);
        setLoading(false);
        setRecipeToEdit(null);
        setNewServing(null);
        window.location.reload();
    };

    const deleteDialogContent = {
        title: 'Delete Recipe',
        content: 'Are you sure you want to delete this recipe?',
        action: 'Delete',
        color: 'error',
        function: deleteRecipe
    };

    const editDialogContent = {
        title: addingRecipe ? 'Add Servings' : 'Edit Servings',
        content: addingRecipe
            ? 'How many servings would you like to add for this recipe?'
            : 'How many servings do you suggest for this recipe?',
        action: 'Confirm',
        color: 'primary',
        function: addingRecipe ? addRecipe : updateServings
    };

    const goToPrevPage = () => {
        navigate(-1);
    };

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    });

    const [planId, setPlanId] = useState('');

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/get_plan/${userId}`)
    //         .then(res => {
    //             setPlan(res.data.data[0]);
    //             setPlanId(res.data.data[0].plan_id);
    //         })
    // }, [])

    useEffect(() => {
        if (recipeToEdit !== null) setDialogAction(editDialogContent);
        if (recipeToDelete !== null) setDialogAction(deleteDialogContent);
        if (recipeToDelete !== null || recipeToEdit !== null) handleClickOpen();
    }, [recipeToEdit, recipeToDelete]);

    const columns = [
        { title: 'Recipe Name', field: 'recipe_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'Recipe Tag', field: 'recipe_tag' },
        { title: 'Recipe Type', field: 'recipe_type' },
        { title: 'Servings', field: 'servings' },
        { title: 'Macro-Nutrients', field: 'macro_nutrients' },
        { title: 'Action', field: 'action', headerStyle: { borderRadius: '0 10px 0 0' }, sorting: false }
    ];

    const arr = [];
    plan?.recipes?.map((item, index) =>
        arr.push({
            recipe_name: item.recipe_name,
            recipe_tag: item.recipe_tags.join(', '),
            recipe_type: item.recipe_type,
            servings: item.serving,
            macro_nutrients:
                item.food_breakdown.length === 0
                    ? 'Calories: 0 | Carbs: 0 | Protein: 0 | Fat: 0'
                    : item.food_breakdown.map((ele, index) => (
                        <span>{ele.key + ':' + ele.value + (index !== item.food_breakdown.length - 1 ? ' | ' : '')}</span>
                    )),
            action: (
                <div style={{ display: 'inline' }}>
                    <Tooltip title="Edit Servings">
                        <EditIcon
                            sx={{ cursor: 'pointer', color: '#02a9f4' }}
                            onClick={() => {
                                setRecipeToEdit(item);
                                // setDialogAction(editDialogContent)
                            }}
                        />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title="Delete Recipe">
                        <DeleteIcon
                            color="error"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setRecipeToDelete(item);
                                // setDialogAction(deleteDialogContent)
                            }}
                        />
                    </Tooltip>
                </div>
            )
        })
    );

    const [toggle, setToggle] = useState(true);

    console.log(toggle, 'toggle 2333333');

    const [toggleNutrition, setToggleNutrition] = useState(false);

    //

    const [allDay, setAllDay] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/diet_plans_dates?user_id=${id}`).then((res) => {
            console.log(res, "[777777777777777777]");
            setAllDay(res.data.data);
            setPlanId(res.data.data[0].id);
        });
    }, [userId]);

    // console.log(planId, "planidddd");

    const [userPlan, setUserPlan] = useState([]);
    const [bmi, setBmi] = useState([]);
    const [planDetails, setPlanDetails] = useState([]);
    const [recipesList, setRecipesList] = useState([]);

    const [BreakfastList, setBreakfastList] = useState([]);
    const [BreakfastNutritionProfile, setBreakfastNutritionProfile] = useState([]);
    const [BreakfastSuggestedRecipes, setBreakfastSuggestedRecipes] = useState([]);

    const [LunchList, setLunchList] = useState([]);
    const [LunchNutritionProfile, setLunchNutritionProfile] = useState([]);
    const [LunchSuggestedRecipes, setLunchSuggestedRecipes] = useState([]);

    const [DinnerList, setDinnerList] = useState([]);
    const [DinnerNutritionProfile, setDinnerNutritionProfile] = useState([]);
    const [DinnerSuggestedRecipes, setDinnerSuggestedRecipes] = useState([]);

    const [allergiesValue, setAllergiesValue] = useState([]);
    const [medicalConditionValue, setMedicalConditionValue] = useState([]);

    const [dangerousRecipes, setDangerousRecipes] = useState([]);

    // console.log(planDetails, "planDetails 88888888");
    // console.log(recipesList, "recipesList 88888888");

    const [planCreatedDate, setPlanCreatedDate] = useState('');
    useEffect(() => {
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/user_plan`, {
                user_id: id
            })
            .then((res) => {
                // console.log(res.data.data[0].plan_details.recipes, "111111");
                // console.log(res.data.data[0].plan_details, "222222");
                setUserPlan(res.data.data[0]);
                setBmi(res.data.data[0].bmi);
                setPlanDetails(res.data.data[0].plan_details);
                setRecipesList(res.data.data[0].plan_details.recipes);
                setIsApproved(res.data.data[0].plan_details.is_approved);
                console.log(res.data.data[0].plan_details, 'hiiiiiiiiiiiiiiiii');

                setBreakfastList(res.data.data[0].plan_details.recipes[0]);
                setBreakfastNutritionProfile(res.data.data[0].plan_details.recipes[0] ? res.data.data[0].plan_details.recipes[0].nutrition_profile : []);
                setBreakfastSuggestedRecipes(res.data.data[0].plan_details.recipes[0] ? res.data.data[0].plan_details.recipes[0].suggested_recipes : []);

                setPrevAllergies(arrayToString(res.data.data[0].allergies.answer_data));
                setPrevMedicalCondition(res.data.data[0].medical_condition.answer_data);

                console.log(res.data.data[0].plan_details.plan_date, 'hiiiiiiiiiiiiiiiii Date');
                const createdDate = new Date(res.data.data[0].plan_details.plan_date);
                const createdYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(createdDate);
                const createdMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(createdDate);
                const createdDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(createdDate);
                const finalCreatedDate = createdDay + ' ' + createdMonth + ' ' + createdYear;
                setPlanCreatedDate(finalCreatedDate);

                // setLunchList(res.data.data[0].plan_details.recipes[1]);
                // setLunchNutritionProfile(res.data.data[0].plan_details.recipes[1].nutrition_profile);
                // setLunchSuggestedRecipes(res.data.data[0].plan_details.recipes[1].suggested_recipes);

                // setDinnerList(res.data.data[0].plan_details.recipes[2]);
                // setDinnerNutritionProfile(res.data.data[0].plan_details.recipes[2].nutrition_profile);
                // setDinnerSuggestedRecipes(res.data.data[0].plan_details.recipes[2].suggested_recipes);

                setAllergiesValue(res.data.data[0].allergies);
                setMedicalConditionValue(res.data.data[0].medical_condition);
                setDangerousRecipes(res.data.data[0].plan_details.recipes[0] ? res.data.data[0].plan_details.recipes[0].suggested_recipes : []);
            })
            .then(() => setLoading(false));
    }, [userId]);

    // console.log(allergiesValue, "allergiesValue");

    // console.log(userId, "userId 99999999999999999999");
    // console.log(planId, "planId 88888888888888888888");

    // console.log(userPlan, "userPlan 23333333");

    const [energyDistruction, setEnergyDistruction] = useState('');
    const [nutritionProfile, setNutritionProfile] = useState('');
    useEffect(() => {
        setLoading(true);
        if (userId !== '' && planId !== '') {
            axios
                .get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/energy_distrubution?user_id=${userId}&plan_id=${planId}`, {
                    user_id: userId,
                    plan_id: planId
                })
                .then((res) => {
                    console.log(res.data.data, 'Energy Distrubution');
                    setEnergyDistruction(res.data.data[0].energy_distruction);
                    setNutritionProfile(res.data.data[0].nutrition_profile);
                    setIsApproved(res.data.data[0].is_approved);
                })
                .then(() => setLoading(false));
        }
    }, [userId, planId]);

    const getEnergyDistrubution = async (userId, planId) => {
        if (userId !== "" && planId !== "") {
            const res = await axios.get(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/energy_distrubution?user_id=${userId}&plan_id=${planId}`);
            console.log(res.data.data, 'Energy Distrubution');
            setEnergyDistruction(res.data.data[0].energy_distruction);
            setNutritionProfile(res.data.data[0].nutrition_profile);
            setIsApproved(res.data.data[0].is_approved);
        }
    };
    // console.log(energyDistruction, "energyDistruction 108888888888888888888");
    // console.log(nutritionProfile, "nutritionProfile 109999999999999999999");

    // console.log(userPlan, "userPlan");
    // console.log(bmi.value, "bmi");
    // console.log(bmi.verdict, "bmi");
    // console.log(planDetails, "planDetails");
    // console.log(recipesList, "recipesList");
    // console.log(BreakfastList, "BreakfastList");
    // console.log(BreakfastNutritionProfile, "BreakfastNutritionProfile");
    // console.log(BreakfastSuggestedRecipes, "BreakfastSuggestedRecipes");

    // console.log(LunchList, "LunchList");
    // console.log(LunchNutritionProfile, "LunchNutritionProfile");
    // console.log(LunchSuggestedRecipes, "LunchSuggestedRecipes");

    // console.log(DinnerList, "DinnerList");
    // console.log(DinnerNutritionProfile, "DinnerNutritionProfile");
    // console.log(DinnerSuggestedRecipes, "DinnerSuggestedRecipes");

    // console.log(allergiesValue.question_id, "question_id");
    // console.log(allergiesValue.answer_data, "answer_data");
    // console.log(dangerousRecipes, "dangerousRecipes");

    // For day 1 start here

    // Breakfast Data
    const columnsBreakfast = [
        { title: 'Recipe name', field: 'recipe_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'Servings', field: 'serving' },
        {
            title: '',
            field: 'measurement',
            editComponent: ({ value, onChange }) => (
                <Select
                    options={measurements}
                    name="fruitSelect"
                    onChange={(selectedOption) => onChange(selectedOption.value)}
                    value={value ? value.value : value}
                />
            )
        }
    ];

    const arrBreakfast = [];
    BreakfastSuggestedRecipes?.map((item, index) =>
        arrBreakfast.push({
            recipe_name: item.recipe_name,
            servings: item.serving,
            measurement: item.measurement
        })
    );

    // Breakfast Energy Distribution
    const columnsEnergyDistribution = [
        { title: 'Nutrition Name', field: 'nutrition_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'RDA', field: 'rda_value' },
        { title: 'In-Diet', field: 'in_diet' },
        { title: 'Result', field: 'verdict', headerStyle: { borderRadius: '0 10px 0 0' } }
    ];

    console.log(nutritionProfile, 'coming');

    const arrBreakfastNutritionProfile = [];
    if (nutritionProfile !== '') {
        nutritionProfile?.map((item, index) =>
            arrBreakfastNutritionProfile.push({
                nutrition_name: `${item.nutrition_name} (${item.nutrition_unit})`,
                rda_value: item.rda_value,
                in_diet: item.in_diet,
                verdict:
                    item.verdict.status === 'increased' ? (
                        <span style={{ color: 'green' }} className="verdict-icons">
                            <ArrowUpwardIcon htmlColor="green" />
                            &nbsp; {item.verdict.value}
                        </span>
                    ) : item.verdict.status === 'decreased' ? (
                        <span style={{ color: 'red' }} className="verdict-icons">
                            <ArrowDownwardIcon htmlColor="red" />
                            &nbsp;{item.verdict.value}
                        </span>
                    ) : (
                        <span style={{ color: 'orange' }} className="verdict-icons">
                            {' '}
                            {item.verdict.value}
                        </span>
                    )
            })
        );
    }

    // Nutrients Consumed List
    const columnsNutrientsConsumed = [
        {
            title: 'Nutrition Name',
            field: 'nutritent_name',
            headerStyle: { borderRadius: '10px 0 0 0', fontWeight: '500', color: '#313131' }
        },
        { title: 'Consumed', field: 'consumed_value', headerStyle: { fontWeight: '500', color: '#313131' } },
        { title: 'RDA value', field: 'rda_value', headerStyle: { borderRadius: '0 10px 0 0', fontWeight: '500', color: '#313131' } }
    ];

    const getRows = (rows) => {
        const newRows = [];
        const newItem = {
            title: 'Consumed',
        };
        if (rows && rows.length > 0) {
        rows.forEach((row) => {
            newItem[row.nutritent_name] = row.consumed_value;
        })}
        newRows.push(newItem);
        // newItem = {
        //     title: 'RDA value',
        // };
        // rows.forEach((row) => {
        //     newItem[row.nutritent_name] = row.rda_value;
        // })
        // newRows.push(newItem);
        return newRows;
    }

    const getColumns = (rows) => {
        const newColumns = [];
        newColumns.push({
            title: 'Nutrition Name',
            field: 'title',
            headerStyle: { fontWeight: '500', color: '#313131', columnWidth: 150, sorting: false }
        })
        if (rows && rows.length > 0) {
        rows.forEach((row) => {
            newColumns.push({
                title: row.nutritent_name,
                field: row.nutritent_name,
                headerStyle: { fontWeight: '500', color: '#313131', columnWidth: 150, sorting: false }
            })
        })}
        return newColumns;
    }
    // Lunch Data
    const columnsLunch = [
        { title: 'Recipe name', field: 'recipe_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'Servings', field: 'servings' },
        { title: '', field: 'measurement' }
    ];



    const arrLunch = [];
    LunchSuggestedRecipes?.map((item, index) =>
        arrLunch.push({
            recipe_name: item.recipe_name,
            servings: item.serving,
            measurement: item.measurement
        })
    );

    // Breakfast Energy Distribution
    const columnsLunchEnergyDistribution = [
        { title: 'Nutrition Name', field: 'nutrition_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'RDA', field: 'rda' },
        { title: 'In-Diet', field: 'in_diet' }
    ];

    const arrLunchNutritionProfile = [];
    LunchNutritionProfile?.map((item, index) =>
        arrLunchNutritionProfile.push({
            nutrition_name: item.nutritent_name,
            rda: item.rda_value,
            in_diet: item.consumed_value
        })
    );

    // Dinner Data
    const columnsDinner = [
        { title: 'Recipe name', field: 'recipe_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'Servings', field: 'servings' },
        { title: '', field: 'measurement' }
    ];

    const arrDinner = [];
    DinnerSuggestedRecipes?.map((item, index) =>
        arrDinner.push({
            recipe_name: item.recipe_name,
            servings: item.serving,
            measurement: item.measurement
        })
    );

    // Breakfast Energy Distribution
    const columnsDinnerEnergyDistribution = [
        { title: 'Nutrition Name', field: 'nutrition_name', headerStyle: { borderRadius: '10px 0 0 0' } },
        { title: 'RDA', field: 'rda' },
        { title: 'In-Diet', field: 'in_diet' }
    ];

    const arrDinnerNutritionProfile = [];
    DinnerNutritionProfile?.map((item, index) =>
        arrDinnerNutritionProfile.push({
            nutrition_name: item.nutritent_name,
            rda: item.rda_value,
            in_diet: item.consumed_value
        })
    );

    //

    // For day 1 ends here

    // Day Wise meal data change
    const [value, setValue] = useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const dayManuChange = (newValue) => {
        setLoading(true);
        setValue(newValue.day);
        axios
            .post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/user_plan`, {
                user_id: id,
                plan_id: newValue.id
            })
            .then((res) => {
                console.log(res.data.data[0].plan_details.plan_id, 'res Plan Id');

                // console.log(res.data.data[0].plan_details.plan_date, "res 23333333 888");
                const createdDate = new Date(res.data.data[0].plan_details.plan_date);
                const createdYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(createdDate);
                const createdMonth = new Intl.DateTimeFormat('en', { month: 'short' }).format(createdDate);
                const createdDay = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(createdDate);
                const finalCreatedDate = createdDay + ' ' + createdMonth + ' ' + createdYear;
                setPlanCreatedDate(finalCreatedDate);

                setPlanId(res.data.data[0].plan_details.plan_id);
                setIsApproved(res.data.data[0].plan_details.is_approved);

                setUserPlan(res.data.data[0]);
                setBmi(res.data.data[0].bmi);
                setPlanDetails(res.data.data[0].plan_details);
                setRecipesList(res.data.data[0].plan_details.recipes);
                if (res.data.data[0].plan_details.recipes[0]) {
                    setBreakfastList(res.data.data[0].plan_details.recipes[0]);
                    setBreakfastNutritionProfile(res.data.data[0].plan_details.recipes[0].nutrition_profile);
                    setBreakfastSuggestedRecipes(res.data.data[0].plan_details.recipes[0].suggested_recipes);
                } else {
                    setBreakfastList([]);
                    setBreakfastNutritionProfile([]);
                    setBreakfastSuggestedRecipes([]);
                }

                setPrevAllergies(arrayToString(res.data.data[0].allergies.answer_data));
                setPrevMedicalCondition(res.data.data[0].medical_condition.answer_data);
            })
            .then(() => setLoading(false));
    };



    // console.log(value, "value day name");





    // Change Nutrition Profile
    // const [breakfastType, setBreakfastType] = useState('Breakfast');
    const handleNutritionProfileChange = (mealType) => {
        const mealVal = mealType.meal_type;
        // setToggleNutrition(!toggleNutrition);
        // setBreakfastType(mealType.meal_type);
        console.log(mealType.meal_type);
        $('#' + mealVal).slideToggle();
        $('#' + mealVal).parent().find('.clickableItem').toggleClass('opne_sec');
    };

    const handleUpdateServings = async (val) => {
        console.log(val);
        setLoading(true);
        const updateArray = [];
        recipesList.forEach((meal) => {
            if (meal.meal_type_id === val.meal_type_id) {
                meal.suggested_recipes.forEach((recipe) => {
                    updateArray.push({
                        plan_id: val.plan_id,
                        meal_type_id: val.meal_type_id,
                        recipe_id: recipe.recipe_id,
                        serving: recipe.serving,
                        measurement: recipe.measurement
                    })
                })
            }
        })
        const body = {
            servings_data: updateArray
        }
        try {
            const res = await axios.patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/update_bulk_recipe_serving`, body);
            console.log(res, "updateArray");
            // window.location.reload();
            setRecipesList(res.data.data[0].plan_details.recipes);
            setTrigger(trigger + 1);
            alert('Servings Updated Successfully');
            setLoading(false);
            setIsApproved(false);
        } catch (err) {
            console.log(err, "updateArray");
            alert("Error Occured")
            window.location.reload();
            setLoading(false);
        }
        $('#top_button_sec' + val.meal_type_id)
            .find('.save_button')
            .removeClass('not_disabled');
        // window.location.reload();
        // console.log($('#recipe_id'+val.meal_type_id).find('tbody tr td > div').attr('recipe_id'));
    };

    function convertToString(input) {
        if (input === '') {
            return 'None';
        } else if (Array.isArray(input) && input.length === 0) {
            return 'None';
        } else if (typeof input === 'string') {
            return input;
        } else if (Array.isArray(input)) {
            return input.join(', ');
        } else {
            return 'Invalid input type. Expected string or array.';
        }
    }

    const arrayToString = (arr) => {
        if (arr) {
            if (typeof arr === 'string') {
                return arr;
            } else {
                return arr.join(', ');
            }
        } else {
            return '';
        }
    }

    const saveAllergiesMedicalCondition = async (answerValue) => {
        setLoading(true);
        try {
            // console.log(stringToArray(allergiesValue.answer_data), medicalConditionValue.answer_data !== "" ? medicalConditionValue.answer_data : "None", "allergiesValue")
            if (allergiesValue.answer_data && allergiesValue.question_id) {
                if (allergiesValue.answer_data !== prevAllergies) {
                    const res = await axios
                        .patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/edit_filter_conditions`, {
                            user_id: userId,
                            question_id: allergiesValue.question_id,
                            answer: convertToString(allergiesValue.answer_data)
                        })
                    setAllergiesValue(convertToString(allergiesValue.answer_data));
                }
            }
            if (medicalConditionValue.answer_data && medicalConditionValue.question_id) {
                if (medicalConditionValue.answer_data !== prevMedicalCondition) {
                    const res1 = await axios
                        .patch(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/edit_filter_conditions`, {
                            user_id: userId,
                            question_id: medicalConditionValue.question_id,
                            answer: convertToString(medicalConditionValue.answer_data)
                        })
                    setMedicalConditionValue(convertToString(medicalConditionValue.answer_data));
                }
            }
            // alert("Updated Successfully");
            setLoading(false);
            window.location.reload();
        } catch (err) {
            console.log(err);
            alert(err.response.data.message)
            setLoading(false);
        }
        // setToggle(!toggle);
    };

    // console.log(allergiesValue, "update");
    // console.log(medicalConditionValue, "update");

    const [anchorEl, setAnchorEl] = useState(null);
    const openNew = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseNew = () => {
        setAnchorEl(null);
    };

    // const handleBloodTestReport = () => {
    //     const link = document.createElement("a");
    //     link.download = `https://www.africau.edu/images/default/sample.pdf`;
    //     link.href = "https://www.africau.edu/images/default/sample.pdf";
    //     link.target="_blank";
    //     link.click();
    // };

    const handleBloodTestReport = () => {
        if (userPlan.blood_test_report !== null) {
            // const link = document.createElement('a');
            // // link.download = userPlan.blood_test_report;
            // link.href = userPlan.blood_test_report;
            // link.target = '_blank';
            // link.click();
            window.open(userPlan.blood_test_report, '_blank');
        } else {
            setOpenReport(true);
        }
    };

    const handleDnaReport = () => {
        if (userPlan.dna_report !== null) {
            const link = document.createElement('a');
            link.download = userPlan.dna_report;
            link.href = userPlan.dna_report;
            link.target = '_blank';
            link.click();
        } else {
            setOpenReport(true);
        }
    };

    const handleHealthAssessmentReport = () => {
        if (userPlan.health_assessment_report !== null) {
            const link = document.createElement('a');
            link.download = userPlan.health_assessment_report;
            link.href = userPlan.health_assessment_report;
            link.target = '_blank';
            link.click();
        } else {
            setOpenReport(true);
        }
    };

    // console.log(userPlan.blood_test_report, "sdsdsds");
    // console.log(userPlan.dna_report, "sdsdsds fdfdfd");

    const renderDialog = () => (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{dialogAction?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{dialogAction?.content}</DialogContentText>
                &nbsp;
                {dialogAction?.title === 'Edit Servings' && (
                    <TextField
                        // autoFocus
                        // margin="dense"
                        // id="name"
                        hiddenLabel
                        value={newServing}
                        // label="Servings"
                        type="number"
                        fullWidth
                        onChange={(e) => setNewServing(e.target.value)}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() => {
                        if (newServing < 0) {
                            alert('Servings cannot be negative');
                        } else {
                            dialogAction?.function();
                        }
                    }}
                    autoFocus
                    color={dialogAction?.color}
                >
                    {dialogAction?.action}
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderReportDialog = () => (
        <Dialog
            open={openReport}
            onClose={handleReportClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 0,
                            paddingRight: 0
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Report
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 10,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            No Report Available
                        </div>
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        onClick={handleReportClose}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    const renderApprovedDialog = () => (
        <Dialog
            open={openApprovedPlanModal}
            onClose={handleApprovedPlanClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 40,
                            paddingRight: 40
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            {unsaveMeal === 0 ? <>Approve Plan</> : <>Unsaved Changes</>}
                        </div>

                        {unsaveMeal === 0 ? (
                            <div
                                style={{
                                    paddingLeft: 0,
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    fontSize: 18,
                                    lineHeight: '30px',
                                    marginBottom: 0,
                                    color: '#616161'
                                }}
                            >
                                Are you sure you want to approve Day {value} diet plan.
                            </div>
                        ) : (
                            <>
                                <div
                                    style={{
                                        paddingLeft: 0,
                                        paddingBottom: 10,
                                        paddingTop: 0,
                                        fontSize: 18,
                                        lineHeight: '30px',
                                        marginBottom: 0,
                                        color: '#616161'
                                    }}
                                >
                                    There are some unsaved changes in the <br /> nutrition plan.
                                </div>
                                <div
                                    style={{
                                        paddingLeft: 0,
                                        paddingBottom: 0,
                                        paddingTop: 0,
                                        fontSize: 18,
                                        lineHeight: '30px',
                                        marginBottom: 0,
                                        color: '#616161'
                                    }}
                                >
                                    Are you sure you want to continue with <br /> unsaved changes.
                                </div>
                            </>
                        )}
                    </span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <Button
                        onClick={handleApprovedPlanClose}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={approvePlanFinal}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    const renderDeletePlaneDialog = () => (
        <Dialog
            open={openDeletePlanModal}
            onClose={handleDeletePlanClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 40,
                            paddingRight: 40
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Delete Meal {mealTypeSelect}
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 10,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            Are you sure you want to delete this recipe from <br /> &quot;{mealTypeSelect}&rdquo; meal type.
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 0,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            The data cannot be restored once deleted.
                        </div>
                    </span>

                    <p
                        style={{
                            fontSize: 14,
                            color: '#009727',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {deleteRecipeMessage}
                    </p>

                    <p
                        style={{
                            fontSize: 14,
                            color: '#D32C2C',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {deleteRecipeFailureMessage}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <Button
                        onClick={handleDeletePlanClose}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeletePlanFinal}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    // for Delete Meal Type
    const [openMealDeleteModal, setOpenMealDeleteModal] = useState(false);

    const handleDeleteMealClose = () => {
        setOpenMealDeleteModal(false);
    };

    const mealDeleteDialog = () => (
        <Dialog
            open={openMealDeleteModal}
            onClose={handleDeleteMealClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: '#313131',
                            paddingLeft: 40,
                            paddingRight: 40
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 20,
                                paddingTop: 0,
                                borderBottom: '1px solid #919191',
                                fontSize: 20,
                                marginBottom: 20,
                                color: '#313131'
                            }}
                        >
                            Delete Meal {deleteMealTypeArr.meal_type}
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 10,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            Are you sure you want to delete the entire <br /> “{deleteMealTypeArr.meal_type}” meal type?
                        </div>
                        <div
                            style={{
                                paddingLeft: 0,
                                paddingBottom: 0,
                                paddingTop: 0,
                                fontSize: 18,
                                lineHeight: '30px',
                                marginBottom: 0,
                                color: '#616161'
                            }}
                        >
                            The data cannot be restored once deleted.
                        </div>
                    </span>

                    <p
                        style={{
                            fontSize: 14,
                            color: '#009727',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {deleteRecipeMessage}
                    </p>

                    <p
                        style={{
                            fontSize: 14,
                            color: '#D32C2C',
                            textAlign: 'center',
                            padding: 0,
                            margin: 0
                        }}
                    >
                        {deleteRecipeFailureMessage}
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        borderTop: '1px solid #919191',
                        paddingTop: 20,
                        paddingBottom: 10
                    }}
                >
                    <Button
                        onClick={handleDeleteMealClose}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#FFFFFF',
                            borderRadius: 10,
                            color: '#1B99DF',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={DeleteMealTypePlan}
                        style={{
                            border: '1px solid #1B99DF',
                            background: '#1B99DF',
                            borderRadius: 10,
                            color: '#ffffff',
                            fontSize: 15,
                            fontWeight: 500,
                            padding: '6px 75px',
                            margin: '0 5px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );

    const [selectedIngredients, setSelectedIngredients] = useState();

    const measurements = [
        { name: 'Bowl', weight: 100 },
        { name: 'Teaspoon', weight: 5 },
        { name: 'Tablespoon', weight: 15 },
        { name: 'Small Glass', weight: 100 },
        { name: 'Medium Glass', weight: 150 },
        { name: 'Large Glass', weight: 200 },
        { name: 'Small Bowl', weight: 100 },
        { name: 'Medium Bowl', weight: 150 },
        { name: 'Large Bowl', weight: 200 },
        { name: 'Soup Bowl', weight: 250 }
    ];

    const handleIngredientMeasurementChange = async (type, val) => {
        // find the ingredient in the selectedIngredients array with same id and add the value as a new property
        console.log(val, 'e.target.value');
        $('#top_button_sec' + val.meal_type_id)
            .find('.save_button')
            .addClass('not_disabled');
        $('.top_heading_sec' + val.meal_type_id)
            .find('.warning_icon')
            .show();
        setUnsaveMeal(1);

        if (type === "serving") {
            const servingData = [];
            recipesList.forEach((meal, index) => {
                if (meal.meal_type_id === val.meal_type_id) {
                    meal.suggested_recipes.forEach((recipe, index) => {
                        if (recipe.recipe_id === val.recipe_id) {
                            servingData.push(
                                {
                                    meal_type_id: val.meal_type_id,
                                    recipe_id: val.recipe_id,
                                    serving: val.serving === "" ? 0 : parseFloat(val.serving),
                                    plan_id: planId
                                }
                            )
                        } else {
                            servingData.push({
                                meal_type_id: val.meal_type_id,
                                recipe_id: recipe.recipe_id,
                                serving: recipe.serving,
                                plan_id: planId
                            })
                        }
                    })
                }
            })
            const body = {
                user_id: userId,
                servings_data: servingData,
                previous_data: [userPlan]
            }
            try {
                const res = await axios.post(`${process.env.REACT_APP_BASEURL}admin/nutrition_plans/dynamic_macros_calculate`, body)
                console.log(res.data.data, 'res.data.data1');
                setRecipesList(res.data.data[0].dynamic_meal_macros.plan_details.recipes);
                setEnergyDistruction(res.data.data[0].energy_distruction);
                setNutritionProfile(res.data.data[0].nutrition_profile);
                setTrigger(trigger + 1);
            } catch (error) {
                console.log(error, 'error');
            }
        }

    };

    useEffect(() => {
        console.log(recipesList, 'recipesList1');
        setTrigger(trigger + 1);
    }, [recipesList]);


    const clubMacros = (ingred) => {
        const macros = ingred?.macro_nutrients?.map((item, index) => item.name).join(', ');
        return macros;
    };

    const handleButtonColorChange = () => {
        $('#top_button_sec').find('.save_button').addClass('not_disabled');
        console.log('sdsdsds');
    };

    const checkIsDangerous = (mealItem) => {
        let flag = false;
        let message = '';
        if (mealItem?.suggested_recipes?.length > 0) {
            mealItem?.suggested_recipes?.forEach((item) => {
                if (item?.is_dangerous) {
                    flag = true;
                    message =
                        'One of the recipes fall under allergies selected or diet preference filter. Please remove such recipe it is not feasible';
                }
            });
        }
        return { flag, message };
    };

    const handleInputRow = (mealitem) => {
        $(`#input-row-${mealitem.meal_type}`).removeClass('hide-input-row');
        $(`#input-row-${mealitem.meal_type}`).addClass('wrapper');
    }

    const handleInputRowClose = (mealitem) => {
        $(`#input-row-${mealitem.meal_type}`).removeClass('wrapper');
        $(`#input-row-${mealitem.meal_type}`).addClass('hide-input-row');
    }

    const myDivRef = useRef(null);

    const [position, setPosition] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [aboutWidth, setAboutWidth] = useState("76%");

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(parseInt(window.scrollY, 10));
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [divTopPosition, setDivTopPosition] = useState(0);

    useEffect(() => {
        const divRect = myDivRef.current.getBoundingClientRect();
        const divTop = divRect.top + window.scrollY - 85;
        setDivTopPosition(parseInt(divTop, 10));
    }, []);

    useEffect(() => {
        if (myDivRef.current) {
            const width = myDivRef.current.offsetWidth;
            setAboutWidth(width + "px");
            setPrevAboutWidth(width + "px");
            setTrigger(trigger + 1);
            console.log('Div width:', width);
        }
    }, []);

    useEffect(() => {
        if (myDivRef.current) {
            const width = myDivRef.current.offsetWidth;
            setAboutWidth(width + "px");
            setPrevAboutWidth(width + "px");
            setTrigger(trigger + 1);
            console.log('Div width change:', width);
        }
    }, [windowWidth, myDivRef.current?.offsetWidth]);

    if (prevAboutWidth !== aboutWidth) {
        if (myDivRef.current) {
            const width = myDivRef.current.offsetWidth;
            setAboutWidth(prevAboutWidth);
            setPrevAboutWidth(width + "px");
            setTrigger(trigger + 1);
        }
    }

    if (myDivRef.current) {
        const width = myDivRef.current.offsetWidth;
        console.log('Div width of the change:', width);
    }

    useEffect(() => {

    }, [aboutWidth]);


    const columnsForMealTable = [
        {
            title: 'Recipe name',
            field: 'recipe_name',
            headerStyle: { fontWeight: '500', color: '#313131', columnWidth: 150, sorting: false }
        },
        {
            title: 'Servings',
            field: 'servings',
            headerStyle: { fontWeight: '500', color: '#313131', columnWidth: 150, sorting: false }
        },
        {
            title: 'Action',
            field: 'action',
            headerStyle: { fontWeight: '500', color: '#313131', columnWidth: 150, sorting: false }
        }
    ]

    const rowsForMealTable = () => {
        console.log("recipesList");
        return [];
    }

    return (
        <MainCard sx={{ position: 'relative' }}>
            <Header>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <ArrowCircleLeftIcon onClick={goToPrevPage} sx={{ cursor: 'pointer', color: '#02a9f4' }} />
                    &nbsp;&nbsp;
                    <span>Verify</span>
                </div>

                <div>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{
                            color: 'white',
                            fontFamily: 'Poppins',
                            borderRadius: 3,
                            marginRight: 1
                        }}
                        onClick={() => navigate(`/nutrition/formula/${id}`)}
                    >
                        Check formula
                    </Button>
                    {/* {planDetails.is_approved !== true ? (
                        <Button
                            className='app_btn '
                            color='primary'
                            variant='contained'
                            sx={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 3
                            }}
                            onClick={approvePlan}
                        >
                            Approve Plan
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 3
                            }}
                            disabled
                        >
                            Approved Plan
                        </Button>
                    )} */}
                    <Button
                        disabled={isApproved || !verification?.can_create}
                        className='app_btn '
                        color='primary'
                        variant='contained'
                        sx={{
                            color: 'white',
                            fontFamily: 'Poppins',
                            borderRadius: 3
                        }}
                        onClick={approvePlan}
                    >
                        {isApproved ? 'Approved Plan' : 'Approve Plan'}
                    </Button>
                </div>
            </Header>
            <div ref={myDivRef}>
                <AddMealBox>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: '#313131',
                                display: 'flex',
                                cursor: 'pointer'
                            }}
                            onClick={() => setToggle(!toggle)}
                        >
                            <span style={{ paddingTop: 2, paddingRight: 5 }}>About</span>
                            {toggle === true ? (
                                <KeyboardArrowDownIcon style={{ color: '#1B99DF' }} />
                            ) : (
                                <KeyboardArrowUpIcon style={{ color: '#1B99DF' }} />
                            )}
                        </span>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        id="top_button_sec"
                    >
                        <p style={{ marginRight: 15 }}>
                            {planCreatedDate}
                        </p>
                        {/* {toggle !== true ?

                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 10,
                                paddingLeft: 20,
                                paddingRight: 30
                            }}
                            onClick={() => setToggle(!toggle)}
                        >
                            <EditIcon style={{ cursor: 'pointer', color: '#ffffff', fontSize: 20, marginRight: 5 }} />
                            Edit
                        </Button>
                    ) : (
                        <Button
                            className="save_button"
                            variant="contained"
                            style={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 10,
                                paddingRight: 20,
                                paddingLeft: 20,
                                backgroundColor: '#919191'
                            }}
                            onClick={saveAllergiesMedicalCondition}
                        >
                            <SaveIcon style={{ fontSize: 20, marginRight: 5 }} /> Save
                        </Button>
                    } */}

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: windowWidth > 500 ? 'row' : 'column',
                                justifyContent: 'space-between',
                                marginLeft: 15
                            }}
                        >
                            <div>
                                <Button
                                    aria-controls={openNew ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openNew ? 'true' : undefined}
                                    onClick={handleClick}
                                    variant="outlined"
                                    startIcon={<InsertDriveFileIcon style={{ color: '#1B99DF' }} />}
                                    style={{
                                        color: '#000000',
                                        fontFamily: 'Poppins',
                                        borderRadius: 10,
                                        paddingLeft: 30,
                                        paddingRight: 30
                                    }}
                                >
                                    View Test Report
                                    <KeyboardArrowDownIcon style={{ color: '#1B99DF', marginLeft: 10 }} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openNew}
                                    onClose={handleCloseNew}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button'
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        handleBloodTestReport()
                                        handleCloseNew()
                                    }}>
                                        <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> Blood Test Report
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleDnaReport()
                                        handleCloseNew()
                                    }}>
                                        <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> DNA Report
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseNew} >
                                        <Link1 to={`/user/naf/?userid=${userId}`} target="_blank" style={{ textDecoration: "none", color: "#616161", display: "flex", alignItems: "center" }} >
                                            <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> Health assessment
                                            report
                                        </Link1>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </AddMealBox>

                <Divider />

                <>
                    {userPlan !== '' ? (
                        <UserDetails
                            direction={windowWidth > 700 ? 'row' : 'column'}
                            alignItems={windowWidth > 700 ? 'flex-end' : windowWidth < 500 ? 'center' : 'baseline'}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: windowWidth > 500 ? 'row' : 'column',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div>
                                    <Text>Name</Text>
                                    <TextField disabled variant="outlined" placeholder="Name" value={userPlan.name} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div style={{ width: "30%" }} >
                                    <Text>Email</Text>
                                    <Tooltip title={userPlan.email} placement="bottom">
                                        <TextField disabled variant="outlined" placeholder="Email" value={userPlan.email} size="small" fullWidth />
                                    </Tooltip>
                                </div>
                                &nbsp;&nbsp;
                                <div>
                                    <Text>Phone Number</Text>
                                    <TextField disabled variant="outlined" placeholder="Phone" value={userPlan.phone} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div' >
                                    <Text>Age</Text>
                                    <TextField disabled variant="outlined" placeholder="Age" value={userPlan.age} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Height (in cm)</Text>
                                    <TextField disabled variant="outlined" placeholder="Height" value={userPlan.height} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Weight (in kg)</Text>
                                    <TextField disabled variant="outlined" placeholder="Weight" value={userPlan.weight} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>BMI</Text>
                                    <TextField disabled variant="outlined" placeholder="Value" value={bmi.value} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Ideal Weight</Text>
                                    <TextField disabled variant="outlined" placeholder="Ideal Weight" value={bmi.verdict} size="small" />
                                </div>
                            </div>
                        </UserDetails>
                    ) : (
                        <></>
                    )}

                    <UserDetails>
                        {toggle && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: windowWidth > 700 ? 'row' : 'column',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        paddingRight: 15,
                                        paddingBottom: 15
                                    }}
                                >
                                    <Text>Known Allergies</Text>
                                    <TextField
                                        disabled={!editMedicalCondition}
                                        variant="outlined"
                                        placeholder="Allergy Name"
                                        multiline
                                        sx={{ m: 0, width: '54ch' }}
                                        value={allergiesValue.answer_data}
                                        onChange={(e) => {
                                            setAllergiesValue({
                                                ...allergiesValue,
                                                answer_data: e.target.value
                                            });
                                            // handleButtonColorChange();
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid #DADADA',
                                        paddingLeft: 15,
                                        paddingBottom: 15
                                    }}
                                >
                                    <Text>Medical Condition</Text>
                                    <TextField
                                        disabled={!editMedicalCondition}
                                        variant="outlined"
                                        placeholder="Medical condition"
                                        multiline
                                        sx={{ m: 0, width: '54ch' }}
                                        value={medicalConditionValue.answer_data}
                                        onChange={(e) => {
                                            setMedicalConditionValue({
                                                ...medicalConditionValue,
                                                answer_data: e.target.value
                                            });
                                            // handleButtonColorChange();
                                        }}
                                    />
                                </div>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;

                                <div>
                                    <Button
                                        className='app_btn '
                                        color='primary'
                                        variant='contained'
                                        sx={{
                                            color: 'white',
                                            fontFamily: 'Poppins',
                                            borderRadius: 3
                                        }}
                                        fullWidth
                                        onClick={() => {
                                            if (editMedicalCondition) {
                                                saveAllergiesMedicalCondition()
                                            }
                                            setEditMedicalCondition(!editMedicalCondition)
                                        }}
                                    >
                                        {editMedicalCondition ? 'Save' : 'Edit'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </UserDetails>
                </>
                <Divider />
            </div>
            <div className='fixed about' style={scrollY - divTopPosition > -5 ? { position: "fixed", top: "90px", background: "white", width: `${aboutWidth}`, zIndex: 1000, borderTop: "1px solid #eeeeee" } : { display: "none" }} >
                <AddMealBox>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: '#313131',
                                display: 'flex',
                                cursor: 'pointer'
                            }}
                            onClick={() => setToggle(!toggle)}
                        >
                            <span style={{ paddingTop: 2, paddingRight: 5 }}>About</span>
                            {toggle === true ? (
                                <KeyboardArrowDownIcon style={{ color: '#1B99DF' }} />
                            ) : (
                                <KeyboardArrowUpIcon style={{ color: '#1B99DF' }} />
                            )}
                        </span>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        id="top_button_sec"
                    >
                        <p style={{ marginRight: 15 }}>
                            {planCreatedDate}
                        </p>
                        {/* {toggle !== true ?

                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 10,
                                paddingLeft: 20,
                                paddingRight: 30
                            }}
                            onClick={() => setToggle(!toggle)}
                        >
                            <EditIcon style={{ cursor: 'pointer', color: '#ffffff', fontSize: 20, marginRight: 5 }} />
                            Edit
                        </Button>
                    ) : (
                        <Button
                            className="save_button"
                            variant="contained"
                            style={{
                                color: 'white',
                                fontFamily: 'Poppins',
                                borderRadius: 10,
                                paddingRight: 20,
                                paddingLeft: 20,
                                backgroundColor: '#919191'
                            }}
                            onClick={saveAllergiesMedicalCondition}
                        >
                            <SaveIcon style={{ fontSize: 20, marginRight: 5 }} /> Save
                        </Button>
                    } */}

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: windowWidth > 500 ? 'row' : 'column',
                                justifyContent: 'space-between',
                                marginLeft: 15
                            }}
                        >
                            <div>
                                <Button
                                    aria-controls={openNew ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openNew ? 'true' : undefined}
                                    onClick={handleClick}
                                    variant="outlined"
                                    startIcon={<InsertDriveFileIcon style={{ color: '#1B99DF' }} />}
                                    style={{
                                        color: '#000000',
                                        fontFamily: 'Poppins',
                                        borderRadius: 10,
                                        paddingLeft: 30,
                                        paddingRight: 30
                                    }}
                                >
                                    View Test Report
                                    <KeyboardArrowDownIcon style={{ color: '#1B99DF', marginLeft: 10 }} />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openNew}
                                    onClose={handleCloseNew}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button'
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        handleBloodTestReport()
                                        handleCloseNew()
                                    }}>
                                        <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> Blood Test Report
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleDnaReport()
                                        handleCloseNew()
                                    }}>
                                        <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> DNA Report
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseNew} >
                                        <Link1 to={`/user/naf/?userid=${userId}`} target="_blank" style={{ textDecoration: "none", color: "#616161", display: "flex", alignItems: "center" }} >
                                            <InsertDriveFileIcon style={{ color: '#1B99DF', fontSize: 20, marginRight: 5 }} /> Health assessment
                                            report
                                        </Link1>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </AddMealBox>

                <Divider />

                <>
                    {userPlan !== '' ? (
                        <UserDetails
                            direction={windowWidth > 700 ? 'row' : 'column'}
                            alignItems={windowWidth > 700 ? 'flex-end' : windowWidth < 500 ? 'center' : 'baseline'}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: windowWidth > 500 ? 'row' : 'column',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div>
                                    <Text>Name</Text>
                                    <TextField disabled variant="outlined" placeholder="Name" value={userPlan.name} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div style={{ width: "30%" }} >
                                    <Text>Email</Text>
                                    <Tooltip title={userPlan.email} placement="bottom">
                                        <TextField disabled variant="outlined" placeholder="Email" value={userPlan.email} size="small" fullWidth />
                                    </Tooltip>
                                </div>
                                &nbsp;&nbsp;
                                <div>
                                    <Text>Phone Number</Text>
                                    <TextField disabled variant="outlined" placeholder="Phone" value={userPlan.phone} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div' >
                                    <Text>Age</Text>
                                    <TextField disabled variant="outlined" placeholder="Age" value={userPlan.age} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Height (in cm)</Text>
                                    <TextField disabled variant="outlined" placeholder="Height" value={userPlan.height} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Weight (in kg)</Text>
                                    <TextField disabled variant="outlined" placeholder="Weight" value={userPlan.weight} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>BMI</Text>
                                    <TextField disabled variant="outlined" placeholder="Value" value={bmi.value} size="small" />
                                </div>
                                &nbsp;&nbsp;
                                <div className='user-detail-smaill-div'>
                                    <Text>Ideal Weight</Text>
                                    <TextField disabled variant="outlined" placeholder="Ideal Weight" value={bmi.verdict} size="small" />
                                </div>
                            </div>
                        </UserDetails>
                    ) : (
                        <></>
                    )}

                    <UserDetails>
                        {toggle && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: windowWidth > 700 ? 'row' : 'column',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        paddingRight: 15,
                                        paddingBottom: 15
                                    }}
                                >
                                    <Text>Known Allergies</Text>
                                    <TextField
                                        disabled={!editMedicalCondition}
                                        variant="outlined"
                                        placeholder="Allergy Name"
                                        multiline
                                        sx={{ m: 0, width: '54ch' }}
                                        value={allergiesValue.answer_data}
                                        onChange={(e) => {
                                            setAllergiesValue({
                                                ...allergiesValue,
                                                answer_data: e.target.value
                                            });
                                            // handleButtonColorChange();
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        borderLeft: '1px solid #DADADA',
                                        paddingLeft: 15,
                                        paddingBottom: 15
                                    }}
                                >
                                    <Text>Medical Condition</Text>
                                    <TextField
                                        disabled={!editMedicalCondition}
                                        variant="outlined"
                                        placeholder="Medical condition"
                                        multiline
                                        sx={{ m: 0, width: '54ch' }}
                                        value={medicalConditionValue.answer_data}
                                        onChange={(e) => {
                                            setMedicalConditionValue({
                                                ...medicalConditionValue,
                                                answer_data: e.target.value
                                            });
                                            // handleButtonColorChange();
                                        }}
                                    />
                                </div>
                                &nbsp;&nbsp;
                                &nbsp;&nbsp;

                                <div>
                                    <Button
                                        className='app_btn '
                                        color='primary'
                                        variant='contained'
                                        sx={{
                                            color: 'white',
                                            fontFamily: 'Poppins',
                                            borderRadius: 3
                                        }}
                                        fullWidth
                                        onClick={() => {
                                            if (editMedicalCondition) {
                                                saveAllergiesMedicalCondition()
                                            }
                                            setEditMedicalCondition(!editMedicalCondition)
                                        }}
                                    >
                                        {editMedicalCondition ? 'Save' : 'Edit'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </UserDetails>
                </>
                <Divider />
            </div>
            <AddMealBox>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <span
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                            color: '#313131'
                        }}
                    >
                        Nutrition Plan
                    </span>
                </div>

                <Box className="dayActiveTab">
                    <TabContext value={value}>
                        <Box>
                            {allDay.length > 0 ? (
                                <>
                                    <TabList
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                        onChange={handleChange}
                                        aria-label="lab API tabs example"
                                    >
                                        {allDay.map((item, index) => (
                                            <Tab
                                                key={index}
                                                label={'Day ' + item.day}
                                                value={index + 1}
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: '#1B99DF',
                                                    background: '#FFFFFF',
                                                    border: '1px solid #1B99DF',
                                                    borderRadius: 8,
                                                    paddingBottom: 5,
                                                    paddingTop: 5,
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginLeft: 5,
                                                    marginRight: 5,
                                                    minHeight: 40,
                                                    zIndex: 100,
                                                }}
                                                onClick={() =>
                                                    dayManuChange({
                                                        id: item.id,
                                                        day: item.day
                                                    })
                                                }
                                            />
                                        ))}
                                    </TabList>
                                </>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </TabContext>
                </Box>
            </AddMealBox>

            <Divider />

            <Box sx={{ width: '100%', typography: 'body1' }}>
                {loading ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', minHeight: '100vh', paddingTop: 100 }}>
                        <ReactLoading type="spin" color="#000" height="10%" width="10%" sx={{ alignSelf: 'center' }} />
                    </div>
                ) : (
                    <TabContext value={value}>
                        {allDay.length > 0 ? (
                            <>
                                {allDay.map((item, index) => (
                                    <TabPanel key={index} value={index + 1}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                color: 'white',
                                                fontFamily: 'Poppins',
                                                borderRadius: 10,
                                                paddingRight: 20,
                                                paddingLeft: 12,
                                                marginLeft: 5,
                                                marginBottom: "20px"
                                            }}
                                            // onClick={handleModalOpen}
                                            onClick={() => {
                                                setAddingRecipe(true);
                                                setModalOpen(true);
                                            }
                                                // handleInputRow(mealitem)
                                            }
                                            disabled={!verification?.can_create}
                                        >
                                            <AddIcon style={{ fontSize: 20 }} /> Add New Meal
                                        </Button>
                                        <NutritionFoodWrap>
                                            <Grid item xs={12} container spacing={2}>
                                                <Grid item lg={7} sm={12} xs={12}>
                                                    {
                                                        recipesList.length > 0 ? (
                                                            <>
                                                                {recipesList.map((mealitem, index) => (
                                                                    <Grid
                                                                        key={index}
                                                                        item
                                                                        style={{
                                                                            marginBottom: 10
                                                                        }}
                                                                        className="eachMealTypes"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                backgroundColor: '#ffffff',
                                                                                justifyContent: 'space-between'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    width: '55%',
                                                                                    alignItems: 'center',
                                                                                }}
                                                                            >

                                                                                <BreakfastWrap className={'top_heading_sec' + mealitem.meal_type_id}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            fontWeight: 600,
                                                                                            color: '#313131'
                                                                                        }}
                                                                                    >
                                                                                        {mealitem.meal_type}
                                                                                    </span>
                                                                                </BreakfastWrap>
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        // justifyContent: 'space-between',
                                                                                        width: '80%',
                                                                                        flexWrap: 'wrap'
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            fontSize: "1rem",
                                                                                            fontWeight: 600,
                                                                                            color: '#313131',
                                                                                            paddingRight: 5
                                                                                        }}
                                                                                    >
                                                                                        Timing &nbsp;{' '}
                                                                                        {/* <sub style={{ color: '#616161', marginLeft: '2px' }}>(12-hr)</sub>{' '} */}
                                                                                        {mealitem.day}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            fontSize: "1rem",
                                                                                            fontWeight: 600,
                                                                                            color: '#313131',
                                                                                            paddingRight: 5
                                                                                        }}
                                                                                    >
                                                                                        {mealitem.meal_time}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div id={'top_button_sec' + mealitem.meal_type_id} style={{
                                                                                width: '45%'
                                                                            }} >
                                                                                <Button
                                                                                    color="error"
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        fontFamily: 'Poppins',
                                                                                        borderRadius: 10,
                                                                                        paddingRight: 20,
                                                                                        paddingLeft: 12,
                                                                                        background: '#D32C2C'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        DeleteMealPlan({
                                                                                            meal_type_id: mealitem.meal_type_id,
                                                                                            plan_id: planDetails.plan_id,
                                                                                            meal_type: mealitem.meal_type
                                                                                        })
                                                                                    }
                                                                                    disabled={!verification?.can_create}
                                                                                >
                                                                                    <DeleteIcon style={{ fontSize: 20 }} /> Delete
                                                                                </Button>
                                                                                <Button
                                                                                    className="save_button"
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        fontFamily: 'Poppins',
                                                                                        borderRadius: 10,
                                                                                        paddingRight: 20,
                                                                                        paddingLeft: 12,
                                                                                        marginLeft: 5,
                                                                                        backgroundColor: '#919191'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleUpdateServings({
                                                                                            plan_id: planDetails.plan_id,
                                                                                            meal_type_id: mealitem.meal_type_id
                                                                                        })
                                                                                    }
                                                                                    disabled={!verification?.can_create}
                                                                                >
                                                                                    <SaveIcon style={{ fontSize: 20, marginRight: 5 }} /> Save
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    variant="contained"
                                                                                    style={{
                                                                                        color: 'white',
                                                                                        fontFamily: 'Poppins',
                                                                                        borderRadius: 10,
                                                                                        paddingRight: 20,
                                                                                        paddingLeft: 12,
                                                                                        marginLeft: 5
                                                                                    }}
                                                                                    // onClick={handleModalOpen}
                                                                                    onClick={() => {
                                                                                        setSelectedBaseType(mealitem.meal_type_id)
                                                                                        handleModalOpen({
                                                                                            meal_type_id: mealitem.meal_type_id
                                                                                        })
                                                                                    }
                                                                                        // handleInputRow(mealitem)
                                                                                    }
                                                                                    disabled={!verification?.can_create}
                                                                                >
                                                                                    <AddIcon style={{ fontSize: 20 }} /> Add
                                                                                </Button>
                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            style={{
                                                                                marginTop: 10,
                                                                                overflow: 'hidden',
                                                                                borderRadius: '12px 12px 0 0',
                                                                                maxHeight: "180px",
                                                                                overflowY: "scroll"
                                                                            }}
                                                                            sx={{ boxShadow: 3 }}
                                                                        >
                                                                            {recipesList.length > 0 ? (
                                                                                <>
                                                                                    <table
                                                                                        className="mealTypeTable"
                                                                                        id={'mealType_id' + mealitem.meal_type_id}
                                                                                    >
                                                                                        <thead>
                                                                                            <tr className="header">
                                                                                                <th style={{
                                                                                                    width: '20%'
                                                                                                }} >Recipe name</th>
                                                                                                <th>Servings</th>
                                                                                                <th>Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {mealitem.suggested_recipes.map((item, index) => (
                                                                                                <tr
                                                                                                    className={
                                                                                                        'wrapper' +
                                                                                                        (item.is_dangerous
                                                                                                            ? ' background_red'
                                                                                                            : ' background_not_red')
                                                                                                    }
                                                                                                    key={index}
                                                                                                >
                                                                                                    <td>
                                                                                                        {item.recipe_name} {item.meal_type}
                                                                                                        <span
                                                                                                            className="warning_icon"
                                                                                                            style={
                                                                                                                item.is_dangerous
                                                                                                                    ? {
                                                                                                                        marginTop: 0,
                                                                                                                        marginLeft: 4
                                                                                                                    }
                                                                                                                    : {
                                                                                                                        display: 'none',
                                                                                                                        marginTop: 0,
                                                                                                                        marginLeft: 4
                                                                                                                    }
                                                                                                            }
                                                                                                        >
                                                                                                            <Tooltip title={item.dangerous_message}>
                                                                                                                <WarningIcon style={{ fontSize: 18, color: '#1b99df' }} />
                                                                                                            </Tooltip>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <input
                                                                                                            className="hidden_recipe_id"
                                                                                                            type="hidden"
                                                                                                            value={item.recipe_id}
                                                                                                        />
                                                                                                        <TextField
                                                                                                            // disabled
                                                                                                            type="number"
                                                                                                            className="inputRounded"
                                                                                                            placeholder="Macros"
                                                                                                            variant="outlined"
                                                                                                            size="small"
                                                                                                            style={{
                                                                                                                width: '40%',
                                                                                                                marginRight: '10px'
                                                                                                            }}
                                                                                                            defaultValue={item.serving}
                                                                                                            onChange={(e) => {
                                                                                                                handleIngredientMeasurementChange("serving", {
                                                                                                                    meal_type_id: item.meal_type_id,
                                                                                                                    recipe_id: item.recipe_id,
                                                                                                                    serving: e.target.value,
                                                                                                                    plan_id: planId
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        {item?.master_measurement?.length > 1 ? (
                                                                                                            <FormControl sx={{ width: '55%' }} size="small">
                                                                                                                <Select
                                                                                                                    // disabled = {item.master_measurement.length === 1 ? true : false}
                                                                                                                    className="inputRounded"
                                                                                                                    labelId="demo-simple-select-label"
                                                                                                                    id="demo-simple-select"
                                                                                                                    defaultValue={item.measurement}
                                                                                                                    // label="Measurement"
                                                                                                                    onChange={() => {
                                                                                                                        handleIngredientMeasurementChange("measurement", {
                                                                                                                            meal_type_id: item.meal_type_id
                                                                                                                        });
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {item.master_measurements?.map(
                                                                                                                        (mes, index) => (
                                                                                                                            <MenuItem
                                                                                                                                value={mes.measurement_unit}
                                                                                                                            >
                                                                                                                                {mes.measurement_unit}
                                                                                                                            </MenuItem>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </Select>
                                                                                                            </FormControl>
                                                                                                        ) : (
                                                                                                            <TextField
                                                                                                                disabled
                                                                                                                className="inputRounded"
                                                                                                                placeholder="Macros"
                                                                                                                variant="outlined"
                                                                                                                size="small"
                                                                                                                style={{
                                                                                                                    width: '55%',
                                                                                                                    marginRight: '10px'
                                                                                                                }}
                                                                                                                defaultValue={item.measurement}
                                                                                                                onChange={() => {
                                                                                                                    handleIngredientMeasurementChange("measurement", {
                                                                                                                        meal_type_id: item.meal_type_id
                                                                                                                    });
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <IconButton
                                                                                                            aria-label="delete"
                                                                                                            sx={{
                                                                                                                color: '#D32C2C'
                                                                                                            }}
                                                                                                            onClick={() =>
                                                                                                                DeletePlan(
                                                                                                                    item.meal_type_id,
                                                                                                                    item.recipe_id,
                                                                                                                    mealitem.meal_type
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <DeleteIcon />
                                                                                                        </IconButton>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>

                                                                        <AddMealBox>
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                                className="clickableItem"
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 14,
                                                                                        fontWeight: 500,
                                                                                        color: '#1B99DF',
                                                                                        display: 'flex',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleNutritionProfileChange({
                                                                                            meal_type: mealitem.meal_type.replace(/\s/g, '')
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    Nutrients Consumed From {mealitem.meal_type}
                                                                                </span>
                                                                            </div>
                                                                        </AddMealBox>
                                                                        <div
                                                                            style={{
                                                                                marginTop: 0,
                                                                                marginBottom: 25,
                                                                                overflow: 'hidden',
                                                                                borderRadius: 0,
                                                                                display: 'none'
                                                                            }}
                                                                            className="nutrientsConsumed"
                                                                            id={mealitem.meal_type.replace(/\s/g, '')}
                                                                        >
                                                                            <MaterialTable
                                                                                icons={tableIcons}
                                                                                columns={getColumns(mealitem.nutrition_profile)}
                                                                                data={getRows(mealitem.nutrition_profile)}
                                                                                style={{ maxWidth: '100%' }}
                                                                                options={{
                                                                                    toolbar: false,
                                                                                    search: false,
                                                                                    paging: false,
                                                                                    pageSize: 5,
                                                                                    maxBodyHeight: '400px',
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#EDEDED',
                                                                                        color: '#616161',
                                                                                        fontWeight: 100
                                                                                    },
                                                                                    rowStyle: (x) => {
                                                                                        if (x.tableData.id % 2) {
                                                                                            return { backgroundColor: '#EDEDED' };
                                                                                        }
                                                                                        return { backgroundColor: '#FFFFFF' };
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                                <span style={{ fontSize: 20, fontWeight: 600, color: '#313131' }}>No Meal Added</span>
                                                            </div>

                                                        )
                                                    }
                                                </Grid>

                                                <Grid item lg={5} sm={12} xs={12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            backgroundColor: '#ffffff'
                                                        }}
                                                    >
                                                        {
                                                            recipesList.length > 0 && (
                                                                <EnergyDistributionWrap>
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 17,
                                                                                fontWeight: 600,
                                                                                color: '#313131'
                                                                            }}
                                                                        >
                                                                            Energy Distribution
                                                                        </span>
                                                                    </div>
                                                                </EnergyDistributionWrap>
                                                            )
                                                        }
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            backgroundColor: '#ffffff'
                                                        }}
                                                    >
                                                        {energyDistruction.length > 0 ? (
                                                            <>
                                                                {energyDistruction.map((item, index) => (
                                                                    <p
                                                                        style={{
                                                                            color: '#313131',
                                                                            fontFamily: 'Poppins',
                                                                            fontSize: 14,
                                                                            fontWeight: 500,
                                                                            paddingRight: 10,
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center'
                                                                        }}
                                                                        onClick={approvePlan}
                                                                    >
                                                                        <StopSharpIcon style={{ fontSize: 30, color: '#D2A62C' }} />
                                                                        {item.key} - {item.value}
                                                                    </p>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            backgroundColor: '#ffffff'
                                                        }}
                                                    >
                                                        {
                                                            recipesList.length > 0 && (
                                                                <span
                                                                    style={{
                                                                        fontSize: 17,
                                                                        fontWeight: 500,
                                                                        color: '#313131'
                                                                    }}
                                                                >
                                                                    <MaterialTable
                                                                        icons={tableIcons}
                                                                        columns={columnsEnergyDistribution}
                                                                        data={arrBreakfastNutritionProfile}
                                                                        options={{
                                                                            toolbar: false,
                                                                            search: false,
                                                                            paging: false,
                                                                            pageSize: 5,
                                                                            maxBodyHeight: '400px',
                                                                            headerStyle: {
                                                                                backgroundColor: '#EDEDED',
                                                                                color: '#616161',
                                                                                fontWeight: 100,
                                                                            },
                                                                            rowStyle: (x) => {
                                                                                if (x.tableData.id % 2) {
                                                                                    return { backgroundColor: '#EDEDED' };
                                                                                }
                                                                                return { backgroundColor: '#FFFFFF' };
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </NutritionFoodWrap>
                                    </TabPanel>
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </TabContext>
                )}
            </Box>

            {mealDeleteDialog()}
            {addServingsDialog()}
            {renderDeletePlaneDialog()}
            {renderApprovedDialog()}
            {renderReportDialog()}
            {renderDialog()}
            {renderModal()}
            {renderCustomBaseDialog()}
        </MainCard>
    );

}

export default Verify;
