import styled from 'styled-components';

export const Header = styled.div`
    background: #FAFAFA;
    border: 1px solid #ADADAD;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-weight: 500;
    font-family: 'Poppins';
    color: #313131;
    padding: 15px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const Text = styled.p`
    font-weight: 500;
`;

export const GrayBox = styled.div`
    background: #FAFAFA;
    border: 1px solid #ADADAD;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    margin-top: 15px;
`;

export const MidWhiteBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    margin: 15px -15px;
    border: 1px solid #ADADAD;
    border-style: solid none
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    padding: 7px;
    color: #01579B;
    font-weight: 500;
`;
