import React, { useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ShadowBox } from './style';
import { MenuItem, Select } from '@mui/material';
import * as _ from 'lodash';
import { boolean } from 'yup';

export default function Configuration({ data, config, setConfig, id, setId, errorToast }) {


    // console.log("data", data.item)

    const [isEdit, setIsEdit] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const [isEditClicked, setIsEditClicked] = useState(false)
    const [isAddClicked, setIsAddClicked] = useState(false)
    const [hoursFrom, setHoursFrom] = useState("")
    const [minutesFrom, setMinutesFrom] = useState('')
    const [hoursTo, setHoursTo] = useState('')
    const [minutesTo, setMinutesTo] = useState('')
    const [initData, setInitData] = useState()
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeButton, setActiveButton] = useState(0)
    const [selectedValueFrom, setSelectedValueFrom] = useState("AM");
    const [selectedValueTo, setSelectedValueTo] = useState("PM");
    const [timingIndex, setTimingIndex] = useState(0)

    const handleChangeFrom = (event) => {
        setSelectedValueFrom(event.target.value);
    };

    const handleChangeTo = (event) => {
        setSelectedValueTo(event.target.value);
    };

    const styles = {
        divider: {
            height: '2px',
            backgroundColor: '#ADADAD',
        },
        tab: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontSize: 12,
            fontWeight: 500,
            color: '#FFFFFF',
            background: '#1B99DF',
            border: '1px solid #1B99DF',
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginRight: 5,
            minHeight: 30,
            cursor: 'pointer'
        },
        tabConfig: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontSize: 12,
            fontWeight: 500,
            color: '#FFFFFF',
            background: '#EAEAEA',
            border: '1px solid #EAEAEA',
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginRight: 5,
            minHeight: 30,
            cursor: 'not-allowed'
        },
        done: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontSize: 12,
            fontWeight: 500,
            color: '#FFFFFF',
            background: '#4ED32C',
            border: '1px solid #4ED32C',
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginRight: 5,
            minHeight: 30,
            cursor: 'pointer'
        },
        clear: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontSize: 12,
            fontWeight: 500,
            color: '#FFFFFF',
            background: '#D32C2C',
            border: '1px solid #D32C2C',
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginRight: 5,
            minHeight: 30,
            cursor: 'pointer'
        },
        tablist: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            flexWrap: "wrap"
        },
        select_delete: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
            gap: 20
        },
        checkbox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 20,
            fontWeight: 500,
            color: "#000000",
            width: "15%"
        },
        disabled: {
            background: "#EAEAEA",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
            fontSize: 12,
            fontWeight: 500,
            color: '#FFFFFF',
            border: '1px solid #EAEAEA',
            borderRadius: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginRight: 5,
            minHeight: 30,
            cursor: 'not-allowed'
        }
    }


    useEffect(() => {
        if (data.item) {
            setId(data.item.id);
        }
    }, [])
    useEffect(() => {
        if (config) {
            console.log(config.length)
        }
    }, [])

    const handleEdit = (rowIndex, timeIndex) => {
        setActiveIndex(rowIndex);
        setIsEdit(true);
        setIsEditClicked(true);
        (setTimingIndex(timeIndex))
        const time = config[rowIndex].available_timing[timeIndex]
        const hour = time.split(":")
        setHoursFrom(hour[0])
        setMinutesFrom(hour[1].substring(0, 2))
        setSelectedValueFrom(hour[1].substring(3, 5))
        setHoursTo(hour[1].substring(9))
        setMinutesTo(hour[2].substring(0, 2))
        setSelectedValueTo(hour[2].substring(3, 5))
    }
    const handleClearIcon = () => {
        setIsEdit(false)
        setIsAddClicked(false)
    }
    const handleDoneIcon = (rowIndex, length) => {
        setIsEdit(false)
        if(hoursFrom === hoursTo && minutesFrom === minutesTo && selectedValueFrom === selectedValueTo) {
            errorToast("Start time and end time cannot be same")
            return
        }
        if (isEditClicked) {
            const tempConfig = [...config]
           
            const concatTime = hoursFrom.concat(`:${minutesFrom} ${selectedValueFrom} to ${hoursTo}:${minutesTo} ${selectedValueTo}`)
            tempConfig[rowIndex].available_timing[timingIndex] = concatTime
            // console.log(tempConfig[rowIndex].available_timing[timingIndex])
            setIsEditClicked(false)
            setConfig(tempConfig)
        }
        if (isAddClicked) {
            try {
                const time = config[rowIndex].available_timing
                console.log(time)
                const tempConfig = [...config]
                const concatTime = hoursFrom.concat(`:${minutesFrom} ${selectedValueFrom} to ${hoursTo}:${minutesTo} ${selectedValueTo}`)
                const isTimeExist = time.find((ele) => ele === concatTime)
                setIsAddClicked(false)
                if (isTimeExist) {
                    throw Error
                }
                tempConfig[rowIndex].available_timing.push(concatTime)
                tempConfig[rowIndex].is_selected = true
                setConfig(tempConfig)


            } catch (error) {
                errorToast("You can't add another same time slot in a row")
            }
        }

    }
    const handleAddButton = (rowIndex) => {
        setActiveIndex(rowIndex);
        setIsEdit(true);
        setIsAddClicked(true);
        setHoursFrom("10")
        setHoursTo("05")
        setMinutesFrom("00")
        setMinutesTo("00")
    }

    const handleDeleteSlot = (rowIndex, elementIndex) => {
        const tempConfig = [...config]
        tempConfig[rowIndex].available_timing.splice(elementIndex, 1)
        if (!tempConfig[rowIndex].available_timing.length > 0) {
            tempConfig[rowIndex].is_selected = false
        }
        setConfig(tempConfig)
    }

    const handleCopy = (rowIndex, timingData) => {
        const initConfigData = _.cloneDeep(config);
        setInitData(initConfigData)
        setIsCopy(true);
        setActiveButton(rowIndex);
        setIsEdit(false)
        setIsAddClicked(false)
        // setTimingData(timingData)
    }

    const handlePaste = (rowIndex) => {
        const tempConfig = [...config]
        const initLength = tempConfig[rowIndex].available_timing.length
        tempConfig[rowIndex].available_timing = _.union(tempConfig[rowIndex].available_timing, config[activeButton].available_timing)
        const afterPasteLength = tempConfig[rowIndex].available_timing.length
        tempConfig[rowIndex].is_selected = config[activeButton].is_selected
        setConfig(tempConfig)
        if (initLength === afterPasteLength) { errorToast("You can't add another same time slot in a row") }
    }
    const handleDoneButton = () => {
        setIsCopy(false);
    }


    const handleCancel = () => {
        setConfig(initData)
        setIsCopy(false)
    }

    const handleHoursFrom = (e) => {
        if(parseInt(e.target.value, 10) <= 12 || Number.isNaN(parseInt(e.target.value, 10))) {
            setHoursFrom(e.target.value)
        }
       
    }
    const handleMinutesFrom = (e) => {
        if(parseInt(e.target.value, 10) <= 60 || Number.isNaN(parseInt(e.target.value, 10))) {
            setMinutesFrom(e.target.value)
        }
        
    }
    const handleHoursTo = (e) => {
        if(parseInt(e.target.value, 10) <= 12 || Number.isNaN(parseInt(e.target.value, 10))) {
            setHoursTo(e.target.value)
        }
    }
    const handleMinutesTo = (e) => {
        if(parseInt(e.target.value, 10) <= 60 || Number.isNaN(parseInt(e.target.value, 10))) {
            setMinutesTo(e.target.value)
        }
    }

    const handleCheckbox = (e, rowIndex, data) => {
        console.log(data)

        const tempConfig = [...config]
        tempConfig[rowIndex].is_selected = e.target.checked
        setConfig(tempConfig)
    }

    return (

        <div style={{ paddingLeft: "20px", paddingBottom: "20px", paddingRight: "20px", paddingTo: "0px" }} >
            <ShadowBox>
                <div className="selection-row" style={{ padding: "0px" }} >
                    {
                        config && config.map((data, rowIndex) => (


                            <div className="configuration-row" key={rowIndex}>

                                <div className="configuration">

                                    <div style={styles.checkbox}>
                                        <input type="checkbox" checked={data.is_selected} onChange={(e) => handleCheckbox(e, rowIndex, data)} />
                                        <div>{data.day}</div>
                                    </div>
                                    <div className='select_time_div'>

                                        {data.available_timing.length > 0 ?
                                            <>
                                                {data.available_timing.map((timing, timeIndex) => (
                                                    <div className='select_time_div_child' id='edit-icon' key={timeIndex}>
                                                        {timing}
                                                        <div>
                                                            <EditIcon style={{ fontSize: "16px", color: "#1B99DF", cursor: "pointer" }} onClick={() => { handleEdit(rowIndex, timeIndex) }} />
                                                            <DeleteIcon style={{ fontSize: "16px", color: "#D32C2C", cursor: "pointer" }} onClick={() => { handleDeleteSlot(rowIndex, timeIndex) }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </> : <>{isAddClicked ? activeIndex !== rowIndex && <div className='no_availability'>No availability given</div> : <div className='no_availability'>No availability given</div>}</>
                                        }


                                        {isEdit && activeIndex === rowIndex &&

                                            <div id='edit_time' className='input_time' >
                                                <div className='input_time'>
                                                    <div className='input_time_child2'>
                                                        <span style={{ fontSize: "10px" }}>Hours</span>
                                                        <input placeholder='00' onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            if (event.target.value === "0" && event.key === "0") {
                                                                event.preventDefault();
                                                            }
                                                          
                                                        }} type="text" className='input_time_child' onChange={(e) => handleHoursFrom(e)} value={hoursFrom} />
                                                    </div> <span style={{ marginTop: "20px" }}>:</span>
                                                    <div className='input_time_child2'>
                                                        <span style={{ fontSize: "10px" }}>Minute</span>
                                                        <input placeholder='00' onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} type="text" className='input_time_child' onChange={(e) => handleMinutesFrom(e)} value={minutesFrom} />
                                                    </div>
                                                    <Select value={selectedValueFrom} onChange={handleChangeFrom} style={{ height: "40px", width: "70px", marginTop: "20px" }}>
                                                        <MenuItem value="AM">AM</MenuItem>
                                                        <MenuItem value="PM">PM</MenuItem>
                                                    </Select>
                                                    <span style={{ marginTop: "20px" }}>to</span>
                                                    <div className='input_time_child2'>
                                                        <span style={{ fontSize: "10px" }}>Hours</span>
                                                        <input placeholder='00' onKeyPress={(event) => {
                                                               if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            if (event.target.value === "0" && event.key === "0") {
                                                                event.preventDefault();
                                                            }
                                                        }} type="text" className='input_time_child' onChange={(e) => handleHoursTo(e)} value={hoursTo} />
                                                    </div> <span style={{ marginTop: "20px" }}>:</span>
                                                    <div className='input_time_child2'>
                                                        <span style={{ fontSize: "10px" }}>Minute</span>
                                                        <input placeholder='00' onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} type="text" className='input_time_child' onChange={(e) => handleMinutesTo(e)} value={minutesTo} />
                                                    </div>
                                                    <div className='am_pm'>
                                                        <Select value={selectedValueTo} onChange={handleChangeTo} style={{ height: "40px", width: "70px", marginTop: "20px" }}>
                                                            <MenuItem value="AM" className='am_pm_child'>AM</MenuItem>
                                                            <MenuItem value="PM" className='am_pm_child'>PM</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className='done_clear'>
                                                        <ClearIcon style={{ color: "#D32C2C", cursor: "pointer" }} onClick={() => handleClearIcon()} />
                                                        <DoneIcon style={{ color: "#4ED32C", cursor: "pointer" }} onClick={() => handleDoneIcon(rowIndex, data.available_timing.length)} />
                                                    </div>
                                                </div>
                                            </div>

                                        }

                                    </div>
                                    {!isCopy ? (<div style={styles.tablist}>

                                        <button type='button' style={styles.tab} onClick={() => handleAddButton(rowIndex)}>
                                            <AddIcon style={{ fontSize: "small" }} />Add
                                        </button>
                                        <button type='button' style={(data.available_timing.length !== 0) ? styles.tab : styles.disabled} onClick={() => { handleCopy(rowIndex, data.available_timing) }} disabled={data.available_timing.length === 0}>
                                            <ContentCopyIcon style={{ fontSize: "small" }} /> Copy
                                        </button>
                                    </div>) : (<>

                                        {activeButton === rowIndex ? <div style={styles.tablist}>
                                            <button type='button' style={styles.done} onClick={() => handleDoneButton()}>
                                                <DoneIcon style={{ fontSize: "small" }} /> Done
                                            </button>
                                            <button type='button' style={styles.clear} onClick={() => handleCancel()}>
                                                <ClearIcon style={{ fontSize: "small" }} /> Cancel
                                            </button> </div> : <div style={styles.tablist}>
                                            <button type='button' style={styles.disabled} disabled>
                                                <AddIcon style={{ fontSize: "small" }} />Add
                                            </button>
                                            <button type='button' style={styles.tab} onClick={() => handlePaste(rowIndex)}>
                                                <ContentPasteIcon style={{ fontSize: "small" }} /> Paste
                                            </button></div>}
                                    </>
                                    )


                                    }
                                </div>

                            </div>


                        ))
                    }


                </div>
            </ShadowBox>
        </div>
    )
}
