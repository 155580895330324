import React, { useEffect, useState } from 'react'
import { Divider } from 'rsuite';
import { ShadowBox } from './style';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SquareIcon from '@mui/icons-material/Square';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import moment from 'moment';
import * as _ from 'lodash';

function SlotSelectionUpdated({ weekData, activeWeek, setWeekData, initWeekData }) {

  const [isChecked, setIsChecked] = useState(false)
  const [rIndex, setRIndex] = useState([])
  const [selectedArray, setSelectedArray] = useState([])


  const styles = {
    divider: {
      height: '2px',
      backgroundColor: '#ADADAD',
    },
    tab: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      fontSize: 12,
      fontWeight: 500,
      color: '#FFFFFF',
      background: '#1B99DF',
      border: '1px solid #1B99DF',
      borderRadius: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 5,
      minHeight: 30,
      cursor: 'pointer',
      minWidth: 150
    },

    tabBooked: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      fontSize: 12,
      fontWeight: 500,
      color: '#D32C2C',
      border: '1px solid #D32C2C',
      borderRadius: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 5,
      minHeight: 30,
      cursor: 'not-allowed',
      minWidth: 150
    },

    tabAvailable: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      fontSize: 12,
      fontWeight: 500,
      background: "#FFFFFF",
      border: "1px solid #ADADAD",
      color: "#000",
      borderRadius: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 5,
      minHeight: 30,
      cursor: 'pointer',
      minWidth: 150

    },
    tabDisabled: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      fontSize: 12,
      fontWeight: 500,
      color: '#FFFFFF',
      background: '#EAEAEA',
      border: '1px solid #EAEAEA',
      borderRadius: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 5,
      minHeight: 30,
      cursor: 'not-allowed',
      minWidth: 150
    },
    select_delete: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'end',
      gap: 20
    },
    checkbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
      fontWeight: 500,
      color: "#000000"
    },
    slots: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 30,
      fontWeight: 500,
      paddingBottom: 20
    },
  }

  useEffect(() => {
    const array = []
    if (weekData[activeWeek] && weekData[activeWeek].data.length > 0) {
      weekData[activeWeek].data.forEach((week) => {
        const getIsActive = week.slots.find((ele)=> ele.is_active)
        if (week.slots.length > 0) {
          array.push({isSelected: false, isDisabled: getIsActive ? true: false})
        }
      })
      setSelectedArray(array)
    }
  }, [initWeekData, activeWeek])

  const clickedSlots = (index, rowIndex) => {
    const tempWeek = [...weekData]
    if (tempWeek[activeWeek].data[rowIndex].slots[index].is_available && selectedArray[rowIndex]) {
      handleSelectedArrayRow(false, rowIndex)
    }

    tempWeek[activeWeek].data[rowIndex].slots[index].is_available = !weekData[activeWeek].data[rowIndex].slots[index].is_available
    // console.log(tempWeek[activeWeek].data[rowIndex].slots[index].is_available)
    setWeekData(tempWeek)
  }


  const handleChange = (e, rowIndex) => {
    setRIndex(rowIndex)

    const tempWeek = [...weekData]
    tempWeek[activeWeek].data[rowIndex].slots.forEach((slot, index) => {
      if (slot.is_active) {

        slot.is_available = e.target.checked
      }
    });
    setWeekData(tempWeek)
    handleSelectedArrayRow(e.target.checked, rowIndex)

  }

  const handleSelectedArrayRow = (value, rowIndex) => {
    const tempArray = [...selectedArray]
    tempArray[rowIndex].isSelected = value
    setSelectedArray(tempArray)
  }
  const handleClearAll = (rowIndex) => {
    setIsChecked(false)
    const tempWeek = [...weekData]
    tempWeek[activeWeek].data[rowIndex].slots.forEach((slot, index) => {
      if (slot.is_active) {
        slot.is_available = false
      }
    });

    setWeekData(tempWeek)

    handleSelectedArrayRow(false, rowIndex)

  }

  const getSlotClassName = (slots) => {
    if (slots.is_active) {
      if (!slots.is_available) {
        return "slot_available"
      }
      return "slot_selected"
    }
    return "slot_disabled"
  }
  return (

    <div style={{ paddingLeft: "20px", paddingBottom: "20px", paddingRight: "20px", paddingTop: "0px" }} >
      <ShadowBox>
        <div style={styles.slots}>
          <div className='slots'>
            <SquareIcon style={{ color: "#1B99DF", fontSize: "20px" }} />
            <span>Slot Selected</span>
          </div>
          <div className='slots'><CropSquareIcon style={{ color: "#D32C2C" }} /><span>Slot Booked</span></div>
          <div className='slots'><CropSquareIcon style={{ color: "#313131" }} /><span>Unselected Slot</span></div>
        </div>
        <Divider style={styles.divider} />
        <div className="selection-row" style={{ padding: "0px" }} >

              {weekData[activeWeek]?.data?.length > 0 ? <> {weekData[activeWeek].data.map((week, rowIndex) => (
                <>
                  {week.slots.length > 0 &&
                    <div className="configuration-row" key={rowIndex} >
                      <div className="configuration">
                        <div style={styles.checkbox}>
                          <div>{moment(week.date).format("ddd, DD MMM")}</div>
                        </div>
                        <div className='select_time_div' style={{ width: "70%" }}>
                          {week.slots.map((slots, index) => {
                            if (!slots.is_booked) {
                              return <div key={index}
                                className={getSlotClassName(slots)}
                                onClick={slots.is_active ? () => clickedSlots(index, rowIndex) : () => { }}> {slots.slot} </div>
                            }
                            return <div key={index} className='slot_booked'> {slots.slot} </div>

                          }
                          )}

                        </div>
                        <div style={styles.select_delete}>
                          <div className='select_delete' >
                            <input id={`${rowIndex}`} type='checkbox' style={{ cursor: "pointer" }} checked={selectedArray[rowIndex]?.isSelected} disabled={!selectedArray[rowIndex]?.isDisabled} onChange={(e) => handleChange(e, rowIndex)} />
                            <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                            <label htmlFor={`${rowIndex}`} style={{ cursor: "pointer" }}>Select all</label>
                          </div>
                          <div className='select_delete' style={{ color: '#D32C2C' }} onClick={() => { handleClearAll(rowIndex) }} >
                            <DeleteOutlineIcon style={{ fontSize: "20px", cursor: "pointer" }} /> <span>Clear all</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </>
                
              ))}</> : <><div className='no_slots'>No Slots Available</div></>}
     
      {/* <div className='no_slots'>No Slots Available</div> */}


        </div>
      </ShadowBox>
    </div>


  )
}

export default SlotSelectionUpdated