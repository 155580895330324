import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AddOrganization from 'views/organization/add';
import ViewOrganization from 'views/organization/view';
import AddNewVideo from 'views/fitness-video/add';
import AssignNewVideo from 'views/fitness-video/assign';
import ViewVideo from 'views/fitness-video/view';
import AllUser from 'views/user-data/all-user';
import SubscribedUser from 'views/user-data/subscribed-user';
import ScheduledTest from 'views/user-data/scheduled-test';
import NewScheduledTest from 'views/user-data/new-scheduled-test';
import axios from 'axios';
import UploadReport from 'views/user-data/upload-report';
import ViewProduct from 'views/product/view';
import AddProduct from 'views/product/add';
import AddCoupon from 'views/coupon/add';
import ViewCoupon from 'views/coupon/view';
import ApprovedVideo from 'views/user-video-assessment/approved-video/index';
import RejectedVideo from 'views/user-video-assessment/rejected-video/index';
import NewAssessment from 'views/user-video-assessment/new-assessment/index';
import ApprovedView from 'views/user-video-assessment/approved-view/index';
import ViewNaf from 'views/user-data/naf/index';
import NotFound from 'views/not-found/index';
import { AddBox } from '@mui/icons-material';
import AddBarcode from 'views/barcode/add';
import ViewBarcode from 'views/barcode/view';
import { chipClasses } from '@mui/material';
import AddRole from 'views/role/add';
import AddView from 'views/role/view';
import { UserData } from 'utils/userData';
import AppAccess from 'views/app-access';
import AddSchedule from '../views/consultation/Add';
import ViewBookings from '../views/consultation/View';
import PrescribeView from '../views/fitness-video/prescribe/prescribe.view';
import InvoicePage from '../views/user-data/invoice/invoice.page';
import Notification from 'views/notification';
import Ingredients from 'views/nutrition/ingredients';
import AddNewIngredient from 'views/nutrition/ingredients/add-new';
import Recipe from 'views/nutrition/recipe';
import AddNewRecipe from 'views/nutrition/recipe/add-new';
import Verification from 'views/nutrition/verification';
import Verify from 'views/nutrition/verification/verify';
import Formula from 'views/nutrition/formula';
import Dashboard from 'views/consultation/Dashboard';
import SlotSetting from 'views/consultation/Slot-setting';
import AddNewConsultant from 'views/consultation/Dashboard/add-new';
import SlotSelection from 'views/consultation/Dashboard/Slot-selection';
import BloodReport from 'views/user-data/blood-report';
import CustomerField from 'views/user-data/Customer-field';
import UpdateCustomerField from 'views/user-data/Customer-field/UpdateCustomerField';
import PdfView from 'ui-component/cards/PdfView';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const role = UserData().role;
const finalPermission = UserData().permissions
const organizationPermissions = finalPermission?.find(permission => permission.name === "Organizations");
const fitnessVideo = finalPermission?.find(permission => permission.name === "Fitness Videos");
const uploadReport = finalPermission?.find(permission => permission.name === "Upload Reports");
const dna = finalPermission?.find(permission => permission.name === "DNA Report");
const blood = finalPermission?.find(permission => permission.name === "Blood Reports");
const usa = finalPermission?.find(permission => permission.name === "User Video Assessment");
const coupon = finalPermission?.find(permission => permission.name === "Coupon");
const notification = finalPermission?.find(permission => permission.name === "Notifications");
const products = finalPermission?.find(permission => permission.name === "Products");
const consultation = finalPermission?.find(permission => permission.name === "Consultation");
const ingredients = finalPermission?.find(permission => permission.name === "Ingredients Panel");
const recipe = finalPermission?.find(permission => permission.name === "Recipe Panel");
const verification = finalPermission?.find(permission => permission.name === "Verification Panel");
const barcode = finalPermission?.find(permission => permission.name === "Add Barcode");
const appAcess = finalPermission?.find(permission => permission.name === "App Access");

function roles() {
        return [
            // {
            //     path: '/',
            //     element: <DashboardDefault />
            // },
            dna?.can_view ? {
                path: '/customer-field',
                element: <CustomerField />
            } : null,
            dna?.can_view ? {
                path: '/update_customer_field/:id',
                element: <UpdateCustomerField />
            } : null,
            {
                path: '/utils/util-typography',
                element: <UtilsTypography />
            },
            {
                path: '/utils/util-color',
                element: <UtilsColor />
            },
            {
                path: '/utils/util-shadow',
                element: <UtilsShadow />
            },
            {
                path: '/icons/tabler-icons',
                element: <UtilsTablerIcons />
            },
            {
                path: '/icons/material-icons',
                element: <UtilsMaterialIcons />
            },
            {
                path: '/sample-page',
                element: <SamplePage />
            },
            organizationPermissions?.can_create ? {
                path: '/organization/add',
                // eslint-disable-next-line react/jsx-no-undef
                element: <AddOrganization />
            } : null,
            organizationPermissions?.can_view ? {
                path: '/organization/view',
                element: <ViewOrganization />
            } : null,
            fitnessVideo?.can_create ? {
                path: '/fitnessvideo/add',
                element: <AddNewVideo />
            } : null,
            fitnessVideo?.can_create ? {
                path: '/fitnessvideo/assign',
                element: <AssignNewVideo />
            } : null,
            fitnessVideo?.can_view ?{
                path: '/fitnessvideo/view',
                element: <ViewVideo />
            } : null,
            {
                path: '/user/all',
                element: <AllUser />
            },
            {
                path: '/user/subscribed',
                element: <SubscribedUser />
            },
            {
                path: '/user/test',
                element: <ScheduledTest />
            },
            uploadReport?.can_view ? {
                path: '/user/newTest',
                element: <NewScheduledTest />
            } : null,
            blood?.can_view ? {
                path: '/user/report',
                element: <BloodReport />
            } : null,

            products?.can_view ? {
                path: '/product/view',
                element: <ViewProduct />
            } : null,
            products?.can_create ? {
                path: '/product/add',
                element: <AddProduct />
            } : null,
            coupon?.can_create ? {
                path: '/coupon/add',
                element: <AddCoupon />
            } : null,
            coupon?.can_view ? {
                path: '/coupon/view',

                element: <ViewCoupon />
            } : null,
            uploadReport?.can_view ? {
                path: '/user/upload-report',
                element: <UploadReport />
            } : null,
            usa?.can_view ? {
                path: '/assessment/new',
                element: <NewAssessment />
            } : null,
            usa?.can_view ? {
                path: '/assessment/approved',
                element: <ApprovedVideo />
            } : null,
            usa?.can_view ? {
                path: '/assessment/rejected',
                element: <RejectedVideo />
            } : null,
            {
                path: '/assessment/view',
                element: <ApprovedView />
            },
            uploadReport?.can_view ? {
                path: '/user/naf',
                element: <ViewNaf />
            } : null,
            barcode?.can_view ? {
                path: 'barcode/view',
                element: <ViewBarcode />
            } : null,
            barcode?.can_create ? {
                path: 'barcode/add',
                element: <AddBarcode />
            } : null,
            // roleAccess?.can_view ? {
            //     path: 'roles/add',
            //     element: <AddRole />
            // } : null,
            // roleAccess?.can_create ? {
            //     path: 'roles/view',
            //     element: <AddView />
            // } : null,
            appAcess?.can_create ? {
                path: 'setting/access',
                element: <AppAccess />
            } : null,
            {
                path: 'user/uploadInvoice/:id',
                element: <InvoicePage />
            },
            consultation?.can_view ? {
                path: 'consultation/view',
                element: <ViewBookings />
            } : null,
            {
                path: 'consultation/schedule',
                element: <AddSchedule />
            },
            {
                path: 'consultation/slotsetting',
                element: <SlotSetting />
            },
            consultation?.can_create ? {
                path: 'consultation/dashboard',
                element: <Dashboard />
            } : null,
            consultation?.can_create ? {
                path: 'consultation/dashboard/add',
                element: <AddNewConsultant />
            } : null,
            consultation?.can_create ? {
                path: 'consultation/dashboard/slotselection',
                element: <SlotSelection />
            } : null,
            uploadReport?.can_create ? {
                path: 'fitnessvideo/prescribe/:id',
                element: <PrescribeView />
            } : null,
            notification?.can_view ? {
                path: 'notification',
                element: <Notification notification={notification} />
            } : null,
            ingredients?.can_view ? {
                path: 'nutrition/ingredients',
                element: <Ingredients />
            }:null,
            recipe?.can_view ? {
                path: 'nutrition/recipe',
                element: <Recipe />
            } : null,
            // verification?.can_view ? {
            //     path: 'nutrition/verification',
            //     element: <Verification />
            // } : null,
            // verification?.can_view ? {
            //     path: 'nutrition/formula/:id',
            //     element: <Formula />
            // } : null,
            // verification?.can_view ? {
            //     path: 'nutrition/verification/:id',
            //     element: <Verify />
            // } : null,
            ingredients?.can_create ? {
                path: 'nutrition/ingredients/add',
                element: <AddNewIngredient />
            } : null,
            recipe?.can_create ? {
                path: 'nutrition/recipe/add',
                element: <AddNewRecipe />
            } : null,
            {
                path: '*',
                element: <NotFound />
            },
            {
                path: '/pdfview',
                element: <PdfView />
            }
        ].filter((item) => item !== null);
  
      // if (role === '10' || role === '6' || role === '7') {
    //     return [
    //         // {
    //         //     path: '/',
    //         //     element: <DashboardDefault />
    //         // },
    //         {
    //             path: '/customer-field',
    //             element: <CustomerField />
    //         },
    //         {
    //             path: '/update_customer_field/:id',
    //             element: <UpdateCustomerField />
    //         },
    //         {
    //             path: '/user/report',
    //             element: <BloodReport />
    //         },
    //         {
    //             path: '/utils/util-typography',
    //             element: <UtilsTypography />
    //         },
    //         {
    //             path: '/utils/util-color',
    //             element: <UtilsColor />
    //         },
    //         {
    //             path: '/utils/util-shadow',
    //             element: <UtilsShadow />
    //         },
    //         {
    //             path: '/icons/tabler-icons',
    //             element: <UtilsTablerIcons />
    //         },
    //         {
    //             path: '/icons/material-icons',
    //             element: <UtilsMaterialIcons />
    //         },
    //         {
    //             path: '/sample-page',
    //             element: <SamplePage />
    //         },
    //         {
    //             path: '/organization/add',
    //             // eslint-disable-next-line react/jsx-no-undef
    //             element: <AddOrganization />
    //         },
    //         {
    //             path: '/organization/view',
    //             element: <ViewOrganization />
    //         },
    //         {
    //             path: '/fitnessvideo/add',
    //             element: <AddNewVideo />
    //         },
    //         {
    //             path: '/fitnessvideo/assign',
    //             element: <AssignNewVideo />
    //         },
    //         {
    //             path: '/fitnessvideo/view',
    //             element: <ViewVideo />
    //         },
    //         {
    //             path: '/user/all',
    //             element: <AllUser />
    //         },
    //         {
    //             path: '/user/subscribed',
    //             element: <SubscribedUser />
    //         },
    //         {
    //             path: '/user/test',
    //             element: <ScheduledTest />
    //         },
    //         // {
    //         //     path: '/user/newTest',
    //         //     element: <NewScheduledTest />
    //         // },
    //         {
    //             path: '/product/view',
    //             element: <ViewProduct />
    //         },
    //         {
    //             path: '/product/add',
    //             element: <AddProduct />
    //         },
    //         coupon?.can_create ? {
    //             path: '/coupon/add',
    //             element: <AddCoupon />
    //         } : null,
    //         coupon?.can_view ? {
    //             path: '/coupon/view',

    //             element: <ViewCoupon />
    //         } : null,
    //         {
    //             path: '/user/upload-report',
    //             element: <UploadReport />
    //         },
    //         {
    //             path: '/assessment/new',
    //             element: <NewAssessment />
    //         },
    //         {
    //             path: '/assessment/approved',
    //             element: <ApprovedVideo />
    //         },
    //         {
    //             path: '/assessment/rejected',
    //             element: <RejectedVideo />
    //         },
    //         {
    //             path: '/assessment/view',
    //             element: <ApprovedView />
    //         },
    //         {
    //             path: '/user/naf',
    //             element: <ViewNaf />
    //         },
    //         {
    //             path: 'barcode/view',
    //             element: <ViewBarcode />
    //         },
    //         {
    //             path: 'barcode/add',
    //             element: <AddBarcode />
    //         },
    //         {
    //             path: 'roles/add',
    //             element: <AddRole />
    //         },
    //         {
    //             path: 'roles/view',
    //             element: <AddView />
    //         },
    //         {
    //             path: 'setting/access',
    //             element: <AppAccess />
    //         },
    //         {
    //             path: 'user/uploadInvoice/:id',
    //             element: <InvoicePage />
    //         },
    //         {
    //             path: 'consultation/view',
    //             element: <ViewBookings />
    //         },
    //         {
    //             path: 'consultation/schedule',
    //             element: <AddSchedule />
    //         },
    //         {
    //             path: 'consultation/slotsetting',
    //             element: <SlotSetting />
    //         },
    //         {
    //             path: 'consultation/dashboard',
    //             element: <Dashboard />
    //         },
    //         {
    //             path: 'consultation/dashboard/add',
    //             element: <AddNewConsultant />
    //         },
    //         {
    //             path: 'consultation/dashboard/slotselection',
    //             element: <SlotSelection />
    //         },
    //         {
    //             path: 'fitnessvideo/prescribe/:id',
    //             element: <PrescribeView />
    //         },
    //         {
    //             path: 'notification',
    //             element: <Notification />
    //         },
    //         {
    //             path: 'nutrition/ingredients',
    //             element: <Ingredients />
    //         },
    //         {
    //             path: 'nutrition/recipe',
    //             element: <Recipe />
    //         },
    //         {
    //             path: 'nutrition/verification',
    //             element: <Verification />
    //         },
    //         {
    //             path: 'nutrition/formula/:id',
    //             element: <Formula />
    //         },
    //         {
    //             path: 'nutrition/verification/:id',
    //             element: <Verify />
    //         },
    //         {
    //             path: 'nutrition/ingredients/add',
    //             element: <AddNewIngredient />
    //         },
    //         {
    //             path: 'nutrition/recipe/add',
    //             element: <AddNewRecipe />
    //         },
    //         {
    //             path: '*',
    //             element: <NotFound />
    //         }
    //     ];
    // }
    // if (role === '2') {
    //     return [
    //         {
    //             path: '/',
    //             element: <AllUser />
    //         },
    //         {
    //             path: '/organization/view',
    //             element: <ViewOrganization />
    //         },
    //         {
    //             path: '/fitnessvideo/view',
    //             element: <ViewVideo />
    //         },
    //         {
    //             path: '/user/all',
    //             element: <AllUser />
    //         },
    //         {
    //             path: '/user/subscribed',
    //             element: <SubscribedUser />
    //         },
    //         {
    //             path: '/user/test',
    //             element: <ScheduledTest />
    //         },
    //         {
    //             path: '/user/newTest',
    //             element: <NewScheduledTest />
    //         },
    //         {
    //             path: '/product/view',
    //             element: <ViewProduct />
    //         },
    //         {
    //             path: '/coupon/view',
    //             element: <ViewCoupon />
    //         },
    //         {
    //             path: '/assessment/new',
    //             element: <NewAssessment />
    //         },
    //         {
    //             path: '/assessment/approved',
    //             element: <ApprovedVideo />
    //         },
    //         {
    //             path: '/assessment/rejected',
    //             element: <RejectedVideo />
    //         },
    //         {
    //             path: '/assessment/view',
    //             element: <ApprovedView />
    //         },
    //         {
    //             path: 'barcode/view',
    //             element: <ViewBarcode />
    //         },
    //         {
    //             path: 'barcode/add',
    //             element: <AddBarcode />
    //         },
    //         {
    //             path: 'notification',
    //             element: <Notification />
    //         },
    //         {
    //             path: '*',
    //             element: <NotFound />
    //         },
    //         {
    //             path: 'nutrition/ingredients',
    //             element: <Ingredients />
    //         },
    //         {
    //             path: 'nutrition/ingredients/add',
    //             element: <AddNewIngredient />
    //         },
    //         {
    //             path: 'nutrition/recipe',
    //             element: <Recipe />
    //         },
    //         {
    //             path: 'nutrition/verification',
    //             element: <Verification />
    //         },
    //         {
    //             path: 'nutrition/formula/:id',
    //             element: <Formula />
    //         },
    //         {
    //             path: '*',
    //             element: <NotFound />
    //         }
    //     ];
    // }


    // if (role === '11') {
    //     return [
    //         // {
    //         //     path: '/',
    //         //     element: <DashboardDefault />
    //         // },
    //         {
    //             path: 'nutrition/verification/:id',
    //             element: <Verify />
    //         },
    //         {
    //             path: 'nutrition/verification',
    //             element: <Verification />
    //         },
    //         {
    //             path: 'nutrition/recipe',
    //             element: <Recipe />
    //         },
    //         {
    //             path: 'nutrition/ingredients',
    //             element: <Ingredients />
    //         },
            
    //         {
    //             path: '/customer-field',
    //             element: <CustomerField />
    //         },
    //                      // add

    //         {
    //             path: '/update_customer_field/:id',
    //             element: <UpdateCustomerField />
    //         },
    //         // {
    //         //     path: '/utils/util-typography',
    //         //     element: <UtilsTypography />
    //         // },
    //         // {
    //         //     path: '/utils/util-color',
    //         //     element: <UtilsColor />
    //         // },
    //         // {
    //         //     path: '/utils/util-shadow',
    //         //     element: <UtilsShadow />
    //         // },
    //         // {
    //         //     path: '/icons/tabler-icons',
    //         //     element: <UtilsTablerIcons />
    //         // },
    //         {
    //             path: '/user/report',
    //             element: <BloodReport />
    //         },
    //         // {
    //         //     path: '/icons/material-icons',
    //         //     element: <UtilsMaterialIcons />
    //         // },
    //         // {
    //         //     path: '/sample-page',
    //         //     element: <SamplePage />
    //         // },
    //         {
    //             path: '/organization/add',
    //             // eslint-disable-next-line react/jsx-no-undef
    //             element: <AddOrganization />
    //         },
    //         // add

    //         {
    //             path: '/organization/view',
    //             element: <ViewOrganization />
    //         }, 
    //         // add
    //         // {
    //         //     path: '/fitnessvideo/add',
    //         //     element: <AddNewVideo />
    //         // },

    //         // {
    //         //     path: '/fitnessvideo/assign',
    //         //     element: <AssignNewVideo />
    //         // },
    //         // {
    //         //     path: '/fitnessvideo/view',
    //         //     element: <ViewVideo />
    //         // },

    //         // {
    //         //     path: '/product/view',
    //         //     element: <ViewProduct />
    //         // },

    //         // {
    //         //     path: '/product/add',
    //         //     element: <AddProduct />
    //         // },

    //         {
    //             path: '/coupon/add',
    //             element: <AddCoupon />
    //         },
    //                      // add

    //         {
    //             path: '/coupon/view',

    //             element: <ViewCoupon />
    //         },
    //                      // add

    //         // {
    //         //     path: '/user/upload-report',
    //         //     element: <UploadReport />
    //         // },
    //         {
    //             path: '/assessment/new',
    //             element: <NewAssessment />
    //         },
    //                      // add

    //         {
    //             path: '/assessment/approved',
    //             element: <ApprovedVideo />
    //         },
    //                      // add

    //         {
    //             path: '/assessment/rejected',
    //             element: <RejectedVideo />
    //         },
    //                      // add

    //         // {
    //         //     path: '/assessment/view',
    //         //     element: <ApprovedView />
    //         // },
    //         // {
    //         //     path: 'roles/add',
    //         //     element: <AddRole />
    //         // },
    //         // {
    //         //     path: 'roles/view',
    //         //     element: <AddView />
    //         // },
    //         // {
    //         //     path: 'setting/access',
    //         //     element: <AppAccess />
    //         // },
    //         // {
    //         //     path: 'user/uploadInvoice/:id',
    //         //     element: <InvoicePage />
    //         // },
    //         {
    //             path: 'consultation/view',
    //             element: <ViewBookings />
    //         },
    //          // add

    //         // {
    //         //     path: 'consultation/schedule',
    //         //     element: <AddSchedule />
    //         // },
    //         // {
    //         //     path: 'consultation/slotsetting',
    //         //     element: <SlotSetting />
    //         // },
    //         {
    //             path: 'consultation/dashboard',
    //             element: <Dashboard />
    //         },
    //                      // add

    //         // {
    //         //     path: 'consultation/dashboard/add',
    //         //     element: <AddNewConsultant />
    //         // },
    //         // {
    //         //     path: 'consultation/dashboard/slotselection',
    //         //     element: <SlotSelection />
    //         // },
    //         // {
    //         //     path: 'fitnessvideo/prescribe/:id',
    //         //     element: <PrescribeView />
    //         // },
    //         {
    //             path: 'notification',
    //             element: <Notification />
    //         },
    //                                  // add

    //     ];
    // }
    // if(role === '12') {
    //  return [ 
    //     // {
    //     //     path: '/',
    //     //     element: <DashboardDefault />
    //     // },  
    //     // {
    //     //     path: '/user/all',
    //     //     element: <AllUser />
    //     // }, 
    //     {
    //         path: '/user/report',
    //         element: <BloodReport />
    //     },
    //     {
    //         path: '/user/subscribed',
    //         element: <SubscribedUser />
    //     },
    //     {
    //         path: '/user/newTest',
    //         element: <NewScheduledTest />
    //     },
    //     {
    //         path: '/assessment/new',
    //         element: <NewAssessment />
    //     },
    //     {
    //         path: '/assessment/approved',
    //         element: <ApprovedVideo />
    //     },
    //     {
    //         path: '/assessment/rejected',
    //         element: <RejectedVideo />
    //     },
    //     {
    //         path: 'notification',
    //         element: <Notification />
    //     },
    //     {
    //         path: 'consultation/view',
    //         element: <ViewBookings />
    //     },
    //     {
    //         path: 'consultation/dashboard',
    //         element: <Dashboard />
    //     },
    //     {
    //         path: 'nutrition/ingredients',
    //         element: <Ingredients />
    //     },
    //     {
    //         path: 'nutrition/recipe',
    //         element: <Recipe />
    //     },
    //     {
    //         path: 'nutrition/verification',
    //         element: <Verification />
    //     },
    //     {
    //         path: 'nutrition/verification/:id',
    //         element: <Verify />
    //     },
    //     {
    //         path: '/customer-field',
    //         element: <CustomerField />
    //     },
    //     {
    //         path: 'consultation/dashboard/slotselection',
    //         element: <SlotSelection />
    //     },
    //     {
    //         path: '/update_customer_field/:id',
    //         element: <UpdateCustomerField />
    //     },
    //     {
    //         path: 'consultation/dashboard/add',
    //         element: <AddNewConsultant />
    //     },
    //     {
    //         path: 'nutrition/ingredients/add',
    //         element: <AddNewIngredient />
    //     },
    //     {
    //         path: 'nutrition/recipe/add',
    //         element: <AddNewRecipe />
    //     },
    //     {
    //         path: 'consultation/schedule',
    //         element: <AddSchedule />
    //     },
    //     {
    //         path: '/assessment/rejected',
    //         element: <RejectedVideo />
    //     },
    //     {
    //         path: '/assessment/view',
    //         element: <ApprovedView />
    //     },
    // ]
    // }
    // if(role === '13' || role === '14' || role === '15') {
    //     return [ 
    //         {
    //             path: 'consultation/view',
    //             element: <ViewBookings />
    //         },
    //         {
    //             path: 'consultation/dashboard',
    //             element: <Dashboard />
    //         },
    //         {
    //             path: 'consultation/dashboard/add',
    //             element: <AddNewConsultant />
    //         },
    //         {
    //             path: 'consultation/dashboard/slotselection',
    //             element: <SlotSelection />
    //         },
    //    ]
    // }

    // if(role === '16' || role === '17') {
    //     return [
    //         {
    //             path: 'nutrition/ingredients',
    //             element: <Ingredients />
    //         },
    //         {
    //             path: 'nutrition/recipe',
    //             element: <Recipe />
    //         },
    //         {
    //             path: 'nutrition/verification',
    //             element: <Verification />
    //         },
    //         {
    //             path: 'nutrition/formula/:id',
    //             element: <Formula />
    //         },
    //         {
    //             path: 'nutrition/verification/:id',
    //             element: <Verify />
    //         },
    //         {
    //             path: 'nutrition/ingredients/add',
    //             element: <AddNewIngredient />
    //         },
    //         {
    //             path: 'nutrition/recipe/add',
    //             element: <AddNewRecipe />
    //         },
    //         {
    //             path: '*',
    //             element: <NotFound />
    //         }
    //     ]
    // }


    // return [
    //     // {
    //     //     path: '/',
    //     //     element: <DashboardDefault />
    //     // },
        
    // ];
}

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: roles()
};

export default MainRoutes;
