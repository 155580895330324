import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableIcons from 'themes/MaterialTableIcons';
import axios from 'axios';
import { Authenticated } from '../../../utils/checkerfunction';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Pagination, Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const Image = styled.img`
    width: 50px;
    border-radius: 50px;

    :hover {
        transform: scale(1.7);
    }
`;

const SubscribedUser = () => {
    const columns = [
        { title: 'S.No', field: 'sno' },
        {
            field: 'profile_pic',
            title: 'Profile',
            render: (rowData) =>
                rowData.profile_pic ? (
                    <Image src={rowData.profile_pic} alt="profile_pic" />
                ) : (
                    <AccountBoxIcon alt="profile_pic" style={{ width: '50px', borderRadius: '50px' }} />
                )
        },


        { title: 'Customer Id', field: 'customer_id', render: (rowData) => {
            if (rowData && rowData.customer_id) {
              return rowData.customer_id;
            }
            return 'null';
          },
        },


        { title: 'Name', field: 'name' },
        { title: 'Organization', field: 'org_name' },
        { title: 'Phone', field: 'phone' },
        { title: 'Email', field: 'email' },
        {title: 'Gender', field: 'gender'},
        {title: 'Height', field: 'height'},
        {title: 'Weight', field: 'weight'},
        {title: 'DOB', field: 'dob'},
        { title: 'Address', field: 'user_address' },
        { title: 'Added On', field: 'created_at' }
    ];

    const [Data, setData] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(100);
    const [searchKey, setSearchKey] = useState('email');
    const [searchValue, setSearchValue] = useState('');
    const [loadingsearch, setLoadingsearch] = useState(false);

    const baseURL = `${process.env.REACT_APP_BASEURL}admin/user/getAllUserList`;
    axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };

    const array = [];
    const getData = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}admin/user/getAllUserListV1?page=${page}&size=${limit}&key=${searchKey}&value=${searchValue}`);
        setTotal(response.data.total_count);
        setLoadingsearch(false);
        response.data.data.forEach((element) => {
            array.push(element);
        });
        setData(array);
        // console.table(array);
    };

    let searchTimeout;

    useEffect(() => {
        clearTimeout(searchTimeout);
        setLoadingsearch(true);
        
        searchTimeout = setTimeout(() => {
            if(searchValue){ // Authenticated(); 
                getData(); }
        }, 2000);
        
        return () => {
            clearTimeout(searchTimeout);
        };
    }, [page, limit, searchValue, searchKey]);

    useEffect(() => {
        setPage(1);
    }, [searchValue, limit, searchKey]);

    useEffect(() => {
        if(!searchValue) getData();
    }, [page, searchValue, limit, searchKey]);

    const arr = [];

    const handlePageChange = (event, value) => {
        setPage(value);
        // getData();
    };

    console.log('hello', Data);
    // eslint-disable-next-line array-callback-return
    Data?.map((item, i) => {
        if (item.is_approved !== 1 && item.is_approved !== 2) {
            arr.push({
                sno: (limit * (page - 1)) + i + 1,
                name: item.name,
                customer_id: item.customer_id,
                email: `${item.email}`,
                org_name: item.org_name,
                phone: item.phone,
                user_address: item.user_address,
                created_at: item.created_at,
                profile_pic: item.profile_pic,
                gender: item.gender,
                height: item.height,
                weight: item.weight,
                dob: item.date_of_birth?.substr(0, 10)
            });
        }
    });

    return (
        <MainCard>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    margin: "0 10px"
                                }}
                            >
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="select-search-key-label">Search by</InputLabel>
                                    <Select
                                        labelId="select-search-key-label"
                                        id="select-search-key"
                                        value={searchKey}
                                        label="Search by"
                                        onChange={(event) => { setSearchKey(event.target.value) }}
                                    >
                                        <MenuItem value='customer_id'>Customer Id</MenuItem>
                                        <MenuItem value='name'>Name</MenuItem>
                                        <MenuItem value='email'>Email</MenuItem>
                                        <MenuItem value='org_name'>Organization</MenuItem>
                                        <MenuItem value='gender'>Gender</MenuItem>
                                        <MenuItem value='height'>Height</MenuItem>
                                        <MenuItem value='weight'>Weight</MenuItem>
                                    </Select>
                                </FormControl>
                                &nbsp;&nbsp;
                                <TextField
                                    autoComplete="off"
                                    key="search"
                                    autoFocus="autoFocus"
                                    id="standard-basic"
                                    placeholder='Search'
                                    variant="standard"
                                    // style={{ marginBottom: '1rem' }}
                                    InputProps={{
                                        startAdornment: (
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft:7 }}>
                                            <InputAdornment position="end">
                                                <SearchRoundedIcon />
                                            </InputAdornment>
                                        </div>),
                                    }}
                                    value={searchValue}
                                    onChange={(e) => {
                                        if (searchKey === '') alert('Please select search key first');
                                        else setSearchValue(e.target.value); setLoadingsearch(true);
                                    }}
                                />
                                {/* &nbsp;&nbsp;&nbsp; */}
                                {/* <Button 
                                    variant="contained" 
                                    size="small"
                                    sx={{color: 'white'}} 
                                    onClick={() => {
                                    if(searchKey === '' || searchValue === '') {
                                        alert('Please select search key and enter search value');
                                    } else {
                                        getData();
                                    }
                                }}>Search</Button> */}
                                {/* <div style={{ width: "13rem" }}>
                                    <MTableToolbar {...props} />
                                </div> */}
                            </div>
                <MaterialTable
                    isLoading={loadingsearch}
                    title={null}
                    style={{width:"100%", padding:"8px", boxShadow:"none"}}
                    icons={tableIcons}
                    columns={columns}
                    data={arr}
                    options={{
                        toolbar: false,
                        // pageSize: 20,
                        search: false,
                        paging: false,
                        maxBodyHeight: '700px',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF',
                            fontWeight: 100
                        },

                        // eslint-disable-next-line consistent-return
                        rowStyle: (x) => {
                            if (x.tableData.id % 2) {
                                return {backgroundColor: '#eef9ff', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'};
                            }
                        }
                    }}
                />
                <div
                    width="100%"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '1rem',
                        width:'100%'
                    }}
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-simple-select-label">Results per Page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={limit}
                            label="Results per Page"
                            onChange={(event) => { setLimit(event.target.value) }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    &nbsp;&nbsp;
                    <Pagination
                        count={Math.ceil(total / limit)}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        size="small"
                    />
                </div>


        </MainCard>
    );
};

export default SubscribedUser;
